import MuiTooltip, {TooltipProps} from '@mui/material/Tooltip';

type Props = Pick<TooltipProps, 'children' | 'title' | 'placement' | 'open' | 'disableHoverListener'>;

export default function Tooltip({children, ...tooltipProps}: Props) {
  return (
    <MuiTooltip {...tooltipProps}>
      <span>{children}</span>
    </MuiTooltip>
  );
}
