import CellRendererEmpty from './CellRendererEmpty';
import {ServerUser} from 'services/types/ServerUser';
import Typography from '@mui/material/Typography';

type CellRendererStringProps = {
  user?: ServerUser;
};

export default function CellRendererUser(props: CellRendererStringProps) {
  if (!props.user) {
    return <CellRendererEmpty />;
  }

  const {first_name, last_name, email, display_name} = props.user;

  const name = first_name && last_name ? `${first_name} ${last_name}` : display_name;

  return (
    <Typography
      sx={{
        hyphens: 'auto',
      }}
      variant="body2"
    >
      {`${name} (${email})`}
    </Typography>
  );
}
