import {useCallback, useMemo, useState} from 'react';
import {NA_SELECT_OPTION, Select, SelectOption} from 'components/base/Select/Select';
import {ServerTrialBase} from 'services/types/ServerTrialBase';
import {getUserFullName} from 'services/types/converters/getUserFullName';
import {usePIsOptions} from 'components/TrialsOverviewContent/filters/pi/usePIsOptions';
import isEmpty from 'lodash/isEmpty';

export function usePIsFilters(list: ServerTrialBase[]) {
  const pIsOptions = usePIsOptions(list);

  const [pIsFilterSelectedOption, setPIsFilterSelectedOption] =
    useState<SelectOption<string | undefined>>(NA_SELECT_OPTION);

  const shouldShowTrial = ({pi}: ServerTrialBase, value: string) => {
    if (!pi || isEmpty(pi)) return false;
    return pi.some((i) => getUserFullName(i) === value);
  };

  const pIsFilter = useCallback(
    (item: ServerTrialBase) =>
      pIsFilterSelectedOption.value === undefined ? true : shouldShowTrial(item, pIsFilterSelectedOption.value),
    [pIsFilterSelectedOption.value]
  );

  const pIsComponent = useMemo(
    () => (
      <Select
        value={pIsFilterSelectedOption}
        onChange={setPIsFilterSelectedOption}
        options={pIsOptions}
        placeholder={'All PIs'}
      />
    ),
    [pIsFilterSelectedOption, pIsOptions]
  );

  return {
    pIsFilter,
    pIsComponent,
  };
}
