import {Communication} from 'models/Communication';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import Loading from 'components/base/WithCommunication/Loading';
import isEmpty from 'lodash/isEmpty';

interface Props<Data> {
  communication: Communication;
  data: Data;
  children: (success: Exclude<Data, null | undefined>) => JSX.Element | null;
}

export default function WithCommunication<Data>({communication, data, children}: Props<Data>) {
  return matchRemoteData(communication, data, {
    notAsked: () => <Loading />,
    // If requesting, but already has data, don't replace it with loading
    requesting: () => <Loading />,
    refetch: () => (!isEmpty(data) ? children(data as Exclude<Data, null | undefined>) : <Loading />),
    error: (error) => (
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant={'h4'}>{error}</Typography>
      </Box>
    ),
    success: (success: Data) => children(success as Exclude<Data, null | undefined>),
  });
}

type Matchers<Data, Result> = {
  notAsked: () => Result;
  requesting: () => Result;
  refetch: (data: Exclude<Data, null | undefined>) => Result;
  error: (message: string) => Result;
  success: (data: Exclude<Data, null | undefined>) => Result;
};

function matchRemoteData<Data, Result>(
  communication: Communication,
  data: Data | undefined | null,
  matchers: Matchers<Data, Result>
): Result {
  if (communication.type === 'notAsked') {
    return matchers.notAsked();
  }
  if (communication.type === 'requesting') {
    return matchers.requesting();
  }
  if (communication.type === 'refetch') {
    return matchers.refetch(data as Exclude<Data, null | undefined>);
  }
  if (communication.type === 'error') {
    return matchers.error(communication.error);
  }
  if (communication.type === 'success') {
    return matchers.success(data as Exclude<Data, null | undefined>);
  }
  return matchers.notAsked();
}
