import ListItem from '@mui/material/ListItem';
import {StyledRouterLink, StyledRouterLinkProps} from './StyledRouterLink';

export const TableOfContentsLink = (props: StyledRouterLinkProps) => {
  return (
    <ListItem>
      <StyledRouterLink {...props} />
    </ListItem>
  );
};

export default TableOfContentsLink;
