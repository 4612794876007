import {Stack, Typography} from '@mui/material';

import {LazyLoadImage} from 'react-lazy-load-image-component';

import styles from '../../PIGuide.module.scss';

// images
import img5 from 'images/guide/section2/A/img-5.jpg';
import img5Placeholder from 'images/guide/section2/A/img-5-placeholder.jpg';
import img6 from 'images/guide/section2/A/img-6.jpg';
import img6Placeholder from 'images/guide/section2/A/img-6-placeholder.jpg';

export const SectionA = () => (
  <section id="guide-crio-login">
    <Stack gap={1} mb={4}>
      <Typography variant="subtitle1">A. Logging In & Overall Orientation</Typography>
      <Typography>
        Once you launch CRIO from the I-Frame (see section 1C above), you’ll be prompted to login with two-factor
        authentication (2FA), as per the screenshot below. Be sure to have your phone handy for this step{' '}
        <Typography component="span" sx={{fontStyle: 'italic'}}>
          (note: in the future, Macro may be moving to single-sign-on capabilities with CRIO, which will eliminate this
          step).
        </Typography>
      </Typography>
      <LazyLoadImage
        src={img5}
        width={800}
        height={534}
        placeholderSrc={img5Placeholder}
        className={styles.img}
        effect="blur"
      />
      <Typography>
        The landing page within the CRIO platform contains a dashboard page, with a number of tabs across the top for
        different users and stakeholders of the tool. For your purposes, the vast majority of your work will be in the
        “Studies” tab, where you can conduct study visit activities and view subjects.
      </Typography>
      <LazyLoadImage
        src={img6}
        width={800}
        height={534}
        placeholderSrc={img6Placeholder}
        className={styles.img}
        effect="blur"
      />
    </Stack>
  </section>
);

export default SectionA;
