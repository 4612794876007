import React, {memo} from 'react';
import {Box, CircularProgress} from '@mui/material';

const defaultStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

interface IProps {
  size?: string | number;
}

const Loading = ({size = 24}: IProps) => {
  return (
    <Box sx={defaultStyles}>
      <CircularProgress size={size} />
    </Box>
  );
};

export default memo(Loading);
