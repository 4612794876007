import {FileDownloadService} from 'services/FileDownloadService';

export default function downloadFromUrl(url: string, onDownloadFinish?: () => void) {
  FileDownloadService.download(url).then((response) => {
    const fileUrl = window.URL.createObjectURL(response.blob);

    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.setAttribute('href', fileUrl);
    link.setAttribute('download', response.name);

    link.click();

    window.URL.revokeObjectURL(link.href);
    onDownloadFinish?.();
    document.body.removeChild(link);
  });
}
