import useAzureRole from 'helpers/hooks/useAzureRole';
import {Navigate, Outlet} from 'react-router-dom';

export const AdminRoute = () => {
  const {isAdmin} = useAzureRole();

  if (isAdmin) {
    return <Outlet />;
  }

  return <Navigate to="/" />;
};

export default AdminRoute;
