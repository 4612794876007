import {ServerConditionOrDisease} from 'services/types/ServerConditionOrDisease';
import {ServerInterventionTreatment} from 'services/types/ServerInterventionTreatment';
import {ServerContacts} from 'services/types/ServerContacts';

export interface ServerDetails {
  id?: number;
  name?: string;
  sponsor?: string;
  cro?: string;
  summary?: string;
  trial_phase?: string;
  condition_of_desease?: ServerConditionOrDisease;
  intervention_treatment?: ServerInterventionTreatment;
  description?: string;
  recruitment_target?: number;
  enrolled_number?: number;
  current_active_patient_number?: number;
  screen_fail_number?: number;
  completed_study_number?: number;
  sq_visit_data?: string;
  siv_date?: string;
  activation_date?: string;
  irb_approval_date?: string;
  protocol_version_date?: string;
  icf_date?: string;
  enrollment_end_date?: string;
  first_screened_date?: string;
  contacts?: ServerContacts;
}

export const INITIAL_SERVER_DETAILS: ServerDetails = {
  id: undefined,
  name: undefined,
  sponsor: undefined,
  cro: undefined,
  summary: undefined,
  condition_of_desease: undefined,
  intervention_treatment: undefined,
  description: undefined,
  recruitment_target: undefined,
  enrolled_number: undefined,
  current_active_patient_number: undefined,
  screen_fail_number: undefined,
  completed_study_number: undefined,
  sq_visit_data: undefined,
  siv_date: undefined,
  activation_date: undefined,
  irb_approval_date: undefined,
  protocol_version_date: undefined,
  icf_date: undefined,
  enrollment_end_date: undefined,
  first_screened_date: undefined,
  contacts: undefined,
};
