import {useMemo} from 'react';
import {ServerVendor} from 'services/types/ServerVendor';
import _ from 'lodash';
import {ServerVendorType} from 'services/types/ServerVendorType';

export const mapVendorToTitle: Record<ServerVendorType | number, string> = {
  [ServerVendorType.main]: '',
  [ServerVendorType.clinical_trial_management]: 'Clinical trial management',
  [ServerVendorType.clinical_portals]: 'Clinical portals',
  [ServerVendorType.patient_recruitment]: 'Patient recruitment',
  [ServerVendorType.travel_vendors]: 'Patient travel',
  [ServerVendorType.unknown]: '',
};

export interface IVendorGroup {
  type: number;
  label: string;
  vendors: ServerVendor[]
}

export function useVendorGroups(vendors?: ServerVendor[]) {
  const _vendorGroups = useMemo(
    () =>
      _(vendors)
        .groupBy((a: ServerVendor) => a.type)
        .toJSON(),
    [vendors]
  );
  const allVendorGroups: IVendorGroup[] = useMemo(
    () => Object.entries(_vendorGroups).map(([key, vendors]) => ({type: +key, label: mapVendorToTitle[+key], vendors})),
    [_vendorGroups]
  );

  const generalVendorGroups: IVendorGroup[] = useMemo(
    () => allVendorGroups.filter((group) => group.type === ServerVendorType.main),
    [allVendorGroups]
  );

  const optionalVendorGroups: IVendorGroup[] = useMemo(
    () => allVendorGroups.filter((group) => group.type !== ServerVendorType.main),
    [allVendorGroups]
  );

  return {
    allVendorGroups,
    generalVendorGroups,
    optionalVendorGroups,
  };
}
