import React from 'react';
import {useNavigate} from 'react-router-dom';
import partner from 'images/partner.svg';
import {Logo} from 'components/complex/Logo/Logo';

import styles from './SignInPage.module.scss';
import Stack from '@mui/material/Stack';
import ms_logo from './images/ms_logo.svg';
import MUIButton from '@mui/material/Button';

export const SignInPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Stack direction={'row'} spacing={0} sx={{height: '100vh', width: '100vw'}} justifyContent={'stretch'}>
      <div className={styles.background} />

      <Stack direction={'column'} alignItems={'stretch'} sx={{width: '100%'}} justifyContent={'space-evenly'}>
        <div className={styles.logo}>
          <Logo />
        </div>

        <div className={styles.signInButton}>
          <MUIButton
            startIcon={<img src={ms_logo} />}
            variant="contained"
            fullWidth={true}
            size="large"
            color="secondary"
            onClick={() => navigate('/trials')}
          >
            Sign in with Microsoft
          </MUIButton>
        </div>

        <div className={styles.partner}>
          official partner
          <img alt="partner" src={partner} />
        </div>
      </Stack>
    </Stack>
  );
};
