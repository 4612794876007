import React, {memo} from 'react';
import {ServerVendor} from 'services/types/ServerVendor';
import {VendorCellsGroup} from 'components/Vendors/VendorCellsGroup';
import {useVendorGroups} from 'components/Vendors/EditVendorsForm/useVendorGroups';

interface Props {
  vendors: ServerVendor[];
  deselectAll: boolean;
  selectedId: number | undefined;
  handleVendorClick(id: number): void;
}

const VendorsCellsList = (props: Props) => {
  const {vendors, deselectAll, handleVendorClick, selectedId} = props;

  const {generalVendorGroups, optionalVendorGroups, allVendorGroups} = useVendorGroups(vendors);

  if (allVendorGroups.length === 0) return null;

  return (
    <>
      {generalVendorGroups.map((group) => (
        <VendorCellsGroup
          key={group.type}
          group={{type: +group.type, vendors: group.vendors}}
          deselectAll={deselectAll}
          selectedId={selectedId}
          handleVendorClick={handleVendorClick}
        />
      ))}
      {optionalVendorGroups.map((group) => (
        <VendorCellsGroup
          key={group.type}
          group={{type: +group.type, vendors: group.vendors}}
          deselectAll={deselectAll}
          selectedId={selectedId}
          handleVendorClick={handleVendorClick}
        />
      ))}
    </>
  );
};

export default memo(VendorsCellsList);
