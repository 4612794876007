import {ServerDetails} from 'services/types/ServerDetails';

function getClientNumber(field?: number): number | undefined {
  return !field || field < 1 ? undefined : field;
}

function getServerNumber(field?: number): number | undefined {
  return !field || field < 1 ? 0 : field;
}

export function convertServerDetailsToClient(details: ServerDetails, trialId?: number): ServerDetails {
  return {
    ...details,
    id: trialId,
    recruitment_target: getClientNumber(details.recruitment_target),
    enrolled_number: getClientNumber(details.enrolled_number),
    current_active_patient_number: getClientNumber(details.current_active_patient_number),
    screen_fail_number: getClientNumber(details.screen_fail_number),
    completed_study_number: getClientNumber(details.completed_study_number),
  };
}

export function convertClientDetailsToServer(details: ServerDetails): ServerDetails {
  return {
    ...details,
    recruitment_target: getServerNumber(details.recruitment_target),
    enrolled_number: getServerNumber(details.enrolled_number),
    current_active_patient_number: getServerNumber(details.current_active_patient_number),
    screen_fail_number: getServerNumber(details.screen_fail_number),
    completed_study_number: getServerNumber(details.completed_study_number),
  };
}