import {Link} from 'react-router-dom';

import {lighten, styled} from '@mui/material';

export const RouterLink = styled(Link)(({theme}) => ({
  '&': {
    lineHeight: '16px',
    transition: 'color 0.3s',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    ':hover': {
      color: lighten(theme.palette.secondary.main, 0.2),
    },
  },
}));
