import React, {memo, useMemo} from 'react';
import Button, {ButtonProps} from 'components/base/Button/Button';
import SubmitButton from 'components/base/Button/SubmitButton';
import SubmitIconButton from 'components/base/Button/FormButton/SubmitIconButton';
import CancelIconButton from 'components/base/Button/FormButton/CancelIconButton';
import {Stack} from '@mui/material';

const isStickyStyles = {
  bottom: -18,
  position: 'sticky',
  background: '#FFF',
  borderTop: '1px solid #EEE',
  paddingTop: '18px',
};

interface IProps {
  submitTitle?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onSubmit?(): void;
  onClose?(): void;
  isSticky?: boolean;
  cancelButtonProps?: Omit<ButtonProps, 'children'>;
  submitButtonProps?: Omit<ButtonProps, 'children'>;
}

type AllProps = IProps;
const FormButtons = (props: AllProps) => {
  const {submitTitle, isLoading, isDisabled, submitButtonProps, cancelButtonProps, isSticky, onSubmit, onClose} = props;

  const renderButtons = useMemo(() => {
    if (submitTitle) {
      return (
        <>
          {onClose && (
            <Button variant="outlined" {...cancelButtonProps} onClick={onClose}>
              Cancel
            </Button>
          )}
          <SubmitButton {...submitButtonProps} isLoading={isLoading} disabled={isDisabled} onClick={onSubmit}>
            {submitTitle}
          </SubmitButton>
        </>
      );
    }

    return (
      <>
        <SubmitIconButton isLoading={isLoading} isDisabled={isDisabled} onClick={onSubmit} />
        {onClose && <CancelIconButton onClick={onClose} />}
      </>
    );
  }, [isLoading, isDisabled, onClose, submitButtonProps, cancelButtonProps, onSubmit, submitTitle]);

  return (
    <Stack justifyContent="end" direction="row" pb="18px" sx={isSticky ? isStickyStyles : undefined}>
      {renderButtons}
    </Stack>
  );
};

export default memo(FormButtons);
export type FormButtonsProps = AllProps;