import React, {memo} from 'react';
import Button, {ButtonProps} from 'components/base/Button/Button';

interface IProps {
  title?: string | JSX.Element;
}

type AllProps = Omit<ButtonProps, 'children'> & IProps;

const AddButton = (props: AllProps) => {
  const {title = '+ Add info', ...rest} = props;

  return (
    <Button {...rest} variant="text" height={28} backgroundColor="rgba(9, 30, 66, 0.04)">
      {title}
    </Button>
  );
};

export default memo(AddButton);
