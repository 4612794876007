import {action, makeAutoObservable} from 'mobx';
import {Communication} from 'models/Communication';
import {TrialsService} from 'services/TrialsService';
import {Permissions} from 'services/types/Permissions';
import {ServerExpenseRequest} from 'services/types/ServerExpenseRequest';
import {ServerExpenses} from 'services/types/ServerExpenses';

const INITIAL = {
  _expenses: [],
  userPermissions: [] as Permissions,
  communication: {type: 'notAsked'} as Communication,
};

class StoreTrialExpenses {
  _expenses: ServerExpenses[] = INITIAL._expenses;
  userPermissions = INITIAL.userPermissions;
  loadExpensesCommunication: Communication = INITIAL.communication;
  markAsCompleteCommunication: Communication = INITIAL.communication;
  addExpenseCommunication: Communication = INITIAL.communication;
  deleteExpenseCommunication: Communication = INITIAL.communication;

  constructor() {
    makeAutoObservable(this);
  }

  onUnmountCommunications() {
    this.markAsCompleteCommunication = INITIAL.communication;
    this.loadExpensesCommunication = INITIAL.communication;
    this.addExpenseCommunication = INITIAL.communication;
    this.deleteExpenseCommunication = INITIAL.communication;
  }

  onUnmountExpenses() {
    this._expenses = INITIAL._expenses;
  }

  loadExpenses = (trialId: number) => {
    this.loadExpensesCommunication = {type: 'requesting'};
    TrialsService.getTrialExpenses(trialId).then(
      action('loadExpensesSuccess', (result) => {
        this.loadExpensesCommunication = {type: 'success'};
        this._expenses = result.data;
        this.userPermissions = result.user_permissions;
      }),
      action('loadExpensesError', (error: string) => {
        this.loadExpensesCommunication = {type: 'error', error};
      })
    );
  };

  addExpense = (trialId: number, expenses: Omit<ServerExpenseRequest, 'trialId'>) => {
    this.addExpenseCommunication = {type: 'requesting'};
    TrialsService.addTrialExpenses({trialId, expenses}).then(
      action('addTrialExpensesSuccess', (result) => {
        this._expenses = [...this._expenses, result.data];
        this.addExpenseCommunication = {type: 'success'};
      }),
      action('addTrialExpenseError', (error: string) => {
        this.addExpenseCommunication = {type: 'error', error};
      })
    );
  };

  deleteExpense = (expenseId: number) => {
    this.deleteExpenseCommunication = {type: 'requesting'};
    TrialsService.deleteTrialExpenses({expenseId}).then(
      action('deleteExpenseSuccess', () => {
        this.deleteExpenseCommunication = {type: 'success'};
        this._expenses = this._expenses.filter((expense) => expense.id !== expenseId);
      }),
      action('deleteExpenseError', (error: string) => {
        this.deleteExpenseCommunication = {type: 'error', error};
      })
    );
  };

  markAsComplete = (expenseId: number, complete: boolean) => {
    this.markAsCompleteCommunication = {type: 'requesting'};
    TrialsService.markTrialExpenseAsComplete({expenseId, complete}).then(
      action('markTrialExpenseAsCompleteSuccess', () => {
        this.markAsCompleteCommunication = {type: 'success'};
        this._expenses = this._expenses.map((expense) => {
          if (expense.id === expenseId) {
            return {...expense, complete};
          }
          return expense;
        });
      }),
      action('markTrialExpenseAsCompleteError', (error: string) => {
        this.markAsCompleteCommunication = {type: 'error', error};
      })
    );
  };
}

export const STORE_TRIAL_EXPENSES = new StoreTrialExpenses();
