import {action, makeAutoObservable} from 'mobx';
import {Communication} from 'models/Communication';
import {TrialsService} from 'services/TrialsService';
import {ServerMonitoringVisit, ServerMonitoringVisitsResponse} from 'services/types/ServerMonitoringVisit';

const INITIAL = {
  monitoringVisits: [] as ServerMonitoringVisit[],
  permission: false,
  communication: {type: 'notAsked'} as Communication,
};

class StoreTrialMonitoringVisits {
  monitoringVisits = INITIAL.monitoringVisits;

  loadMonitoringVisitsCommunication: Communication = INITIAL.communication;
  addMonitoringVisitCommunication: Communication = INITIAL.communication;
  deleteMonitoringVisitCommunication: Communication = INITIAL.communication;

  canDeleteMonitoringVisits = INITIAL.permission;
  canCreateMonitoringVisits = INITIAL.permission;
  canListMonitoringVisits = INITIAL.permission;

  constructor() {
    makeAutoObservable(this);
  }

  onUnmountCommunications() {
    this.loadMonitoringVisitsCommunication = INITIAL.communication;
    this.addMonitoringVisitCommunication = INITIAL.communication;
    this.deleteMonitoringVisitCommunication = INITIAL.communication;
  }

  onUnmountMonitoringVisits() {
    this.monitoringVisits = INITIAL.monitoringVisits;
  }

  addMonitoringVisit = (trialId: number, visit: any) => {
    this.addMonitoringVisitCommunication = {type: 'requesting'};
    TrialsService.addMonitoringVisit({trialId, visit}).then(
      action('addTrialMonitoringVisitSuccess', (result: ServerMonitoringVisit) => {
        this.monitoringVisits = [...this.monitoringVisits, result];
        this.addMonitoringVisitCommunication = {type: 'success'};
      }),
      action('addTrialMonitoringVisitError', (error: string) => {
        this.addMonitoringVisitCommunication = {type: 'error', error};
      })
    );
  };

  loadMonitoringVisits = (trialId: number) => {
    this.loadMonitoringVisitsCommunication = {type: 'requesting'};
    TrialsService.getTrialMonitoringVisits(trialId).then(
      action('loadMonitoringVisitsSuccess', ({data, user_permissions}: ServerMonitoringVisitsResponse) => {
        this.loadMonitoringVisitsCommunication = {type: 'success'};
        this.canListMonitoringVisits = user_permissions?.includes('list') ?? false;
        this.canCreateMonitoringVisits = user_permissions?.includes('create') ?? false;
        this.canDeleteMonitoringVisits = user_permissions?.includes('delete') ?? false;
        this.monitoringVisits = this.canListMonitoringVisits ? data : [];
      }),
      action('loadMonitoringVisitsError', (error: string) => {
        this.loadMonitoringVisitsCommunication = {type: 'error', error};
      })
    );
  };

  deleteMonitoringVisit = (visitId: number) => {
    this.deleteMonitoringVisitCommunication = {type: 'requesting'};
    TrialsService.deleteMonitoringVisit(visitId).then(
      action('deleteMonitoringVisitSuccess', () => {
        this.monitoringVisits = this.monitoringVisits.filter((visit) => visit.id !== visitId);
        this.deleteMonitoringVisitCommunication = {type: 'success'};
      }),
      action('deleteMonitoringVisitError', (error: string) => {
        this.deleteMonitoringVisitCommunication = {type: 'error', error};
      })
    );
  };
}

export const STORE_TRIAL_MONITORING_VISITS = new StoreTrialMonitoringVisits();
