import {ServerVendor} from 'services/types/ServerVendor';
import {useCallback} from 'react';
import Stack from '@mui/material/Stack';
import {ReactComponent as SplitSVG} from 'components/TrialContent/vendor/images/split.svg';
import Box from '@mui/material/Box';
import {ActionsPanel} from 'components/TrialContent/vendor/panel/ActionsPanel';
import {useVendorScreen} from 'components/TrialContent/vendor/useVendorScreen';
import useIsElectronApp from 'helpers/hooks/useIsElectronApp';
import {VendorToCompareSelector} from 'components/TrialContent/vendor/VendorToCompareSelector';
import {ReflexContainer, ReflexSplitter, ReflexElement} from 'react-reflex';
import 'react-reflex/styles.css';
import {ScreenFrame} from './ScreenFrame';

interface VendorScreenProps {
  primaryVendor: ServerVendor;
  vendors: ServerVendor[];
}

export function VendorScreen({primaryVendor, vendors}: VendorScreenProps) {
  const {splitMode, setSplitMode, vendorToCompare, setCompareId} = useVendorScreen(vendors);
  const {isElectronApp} = useIsElectronApp();

  const handleSplitOff = useCallback(() => {
    setSplitMode(false);
  }, [setSplitMode]);

  const handleSplitOn = useCallback(() => {
    setSplitMode(true);
  }, [setSplitMode]);

  return (
    <Stack direction="column" spacing={0} height="100%">
      {!splitMode && isElectronApp ? (
        <Box sx={{position: 'absolute', bottom: 80, right: 0, zIndex: 99}}>
          <SplitSVG onClick={handleSplitOn} />
        </Box>
      ) : null}
      {splitMode ? <ActionsPanel onClose={handleSplitOff} /> : null}

      <ReflexContainer orientation="vertical" style={{height: '95%'}}>
        <ReflexElement
            className="left-pane"
            style={!isElectronApp ? {
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
            } : undefined}
          >
          <ScreenFrame url={primaryVendor.url} vendorName={primaryVendor.name} />
        </ReflexElement>

        {splitMode && <ReflexSplitter style={{width: 10, background: '#769E98'}} />}

        {splitMode ? (
          <ReflexElement
            className="right-pane"
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {vendorToCompare ? (
              <ScreenFrame url={vendorToCompare.url} vendorName={vendorToCompare.name} />
            ) : (
              <VendorToCompareSelector vendors={vendors} onClick={setCompareId} />
            )}
          </ReflexElement>
        ) : null}
      </ReflexContainer>
    </Stack>
  );
}
