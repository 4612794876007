import {Outlet, matchPath, useLocation} from 'react-router-dom';
import {BasicLayout} from './BasicLayout';

const noPaddingRoutes = ['/trials/:trialId/*'];

export const LayoutWrapper = () => {
  const location = useLocation();

  const isNoPaddingRoute = noPaddingRoutes.some((path) => matchPath(path, location.pathname));

  return (
    <BasicLayout noPadding={isNoPaddingRoute}>
      <Outlet />
    </BasicLayout>
  );
};
