import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import {KeyboardEvent, FocusEvent, MouseEvent, useCallback} from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import {TextFieldProps} from '@mui/material/TextField';

import FormHelperText from 'components/base/FormHelperText/FormHelperText';
import {InfoIcon} from 'components/base/InfoIcon/InfoIcon';
import {InputProps} from '@mui/material';

type Props = {
  children: JSX.Element;
  errorMessage?: string;
  hideErrorMessage?: boolean;
};

export function InputBase(props: Props) {
  const {errorMessage, children, hideErrorMessage} = props;

  return (
    <Box width="100%">
      <FormControl fullWidth error={Boolean(errorMessage)}>
        {children}
        {!hideErrorMessage && <FormHelperText errorMessage={errorMessage} />}
      </FormControl>
    </Box>
  );
}

export type InputCommonProps = {
  placeholder: string;
  endAdornmentIcons?: JSX.Element[];
  info?: string;
  name?: string;
  disabled?: boolean;
  isRequired?: boolean;
  dataTest?: string;
  dataTestError?: string;
  onKeyUp?(e: KeyboardEvent<HTMLDivElement>): void;
  onKeyDown?(e: KeyboardEvent<HTMLDivElement>): void;
  onBlur?(e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  onClick?(e: MouseEvent<HTMLDivElement>): void;
  onEnter?(): void;
  meta?: {touched: boolean; error?: string};
  hideErrorMessage?: boolean;
};

export function useInputCommonProps(props: InputCommonProps, inputProps?: InputProps) {
  const errorMessage = props.meta?.touched && props.meta.error ? props.meta.error : undefined;

  const placeholderAppend = props.isRequired ? ' *' : '';
  const label = `${props.placeholder}${placeholderAppend}`;

  const {onEnter, onKeyUp: _onKeyUp} = props;

  const onKeyUp = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        onEnter?.();
      }
      _onKeyUp?.(e);
    },
    [onEnter, _onKeyUp]
  );

  const fieldProps: TextFieldProps & {
    'data-test'?: string;
  } = {
    label,
    onKeyUp,
    InputProps: {
      endAdornment: getEndAdornment({
        info: props.info,
        endAdornmentIcons: props.endAdornmentIcons,
      }),
      maxRows: 5,
      ...inputProps,
    },
    name: props.name,
    'data-test': props.dataTest,
    disabled: props.disabled,
    onBlur: props.onBlur,
    onClick: props.onClick,
    size: 'small' as const,
    variant: 'outlined' as const,
    error: Boolean(errorMessage),
  };

  return {
    fieldProps,
    baseProps: {
      errorMessage,
      dataTestError: props.dataTestError,
      hideErrorMessage: props.hideErrorMessage,
    },
  };
}

function getEndAdornment({
  endAdornmentIcons,
  info,
}: {
  endAdornmentIcons: JSX.Element[] | undefined;
  info: string | undefined;
}) {
  if (endAdornmentIcons?.length === 0 && !info) {
    return undefined;
  }

  return (
    <InputAdornment position="end">
      <Stack direction={'row'}>
        {endAdornmentIcons?.map((icon) => icon)}
        {info ? <InfoIcon info={info} /> : null}
      </Stack>
    </InputAdornment>
  );
}
