import {ServerTrialBase} from 'services/types/ServerTrialBase';
import {useMemo} from 'react';
import {TrialsListCard} from 'components/TrialsList/TrialsListCard';
import Grid from '@mui/material/Grid';

type Props = {
  list: ServerTrialBase[];
};

export const TrialsList = (props: Props) => {
  const list = useMemo(() => props.list, [props.list]);
  return (
    <div>
      <Grid container={true} direction={'row'} spacing={3}>
        {list.map((element) => (
          <Grid item={true} key={element.id}>
            <TrialsListCard element={element} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
