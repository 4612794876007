import CellRendererEmpty from './CellRendererEmpty';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import {observer} from 'mobx-react-lite';
import {useState} from 'react';
import downloadFromUrl from 'services/downloadFromUrl';

interface Props {
  url: string | undefined | null;
}

export const CellRendererDownload = observer(({url}: Props) => {
  const [isLoading, setLoading] = useState(false);

  if (!url) {
    return <CellRendererEmpty />;
  }

  const download = () => {
    setLoading(true);
    downloadFromUrl(url, () => setLoading(false));
  };

  return (
    <IconButton onClick={download}>
      <FileDownloadIcon color={isLoading ? 'disabled' : 'inherit'} />
    </IconButton>
  );
});

export default CellRendererDownload;
