import MUIButton from '@mui/material/Button';

import {ButtonCommonProps, useButtonCommonProps} from 'components/base/Button/shared/useButtonCommonProps';
import {ButtonContent} from 'components/base/Button/shared/ButtonContent';
import Loading from 'components/base/WithCommunication/Loading';

interface IProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit';
  isLoading?: boolean;
}

type AllProps = IProps & ButtonCommonProps;

const Button = (props: AllProps) => {
  const {onClick, type = 'button', isLoading, ...rest} = props;
  const {buttonProps, contentProps} = useButtonCommonProps(rest);

  return (
    <MUIButton {...buttonProps} type={type} onClick={onClick}>
      {isLoading ? <Loading /> : <ButtonContent {...contentProps} />}
    </MUIButton>
  );
};

export default Button;
export type ButtonProps = AllProps;
