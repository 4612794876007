import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TrialSharedLink from './TrialSharedLink';
import styles from './SharedDrive.module.scss';
import {ServerSharedLinks} from 'services/types/ServerSharedLinks';
import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {STORE_TRIAL_LINKS} from 'store/StoreTrialLinks';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';
import Loading from 'components/base/WithCommunication/Loading';
import useAzureRole from 'helpers/hooks/useAzureRole';
import {TrialTitle} from 'components/TrialContent/trial/details/TrialTitle';
import {ReactComponent as FolderDriveLinkSVG} from './icons/folder_drive_link.svg';

const general: {key: keyof ServerSharedLinks; label: string} = {
  key: 'fssd',
  label: 'Full study shared drive',
};

const protocols: {key: keyof ServerSharedLinks; label: string}[] = [
  {key: 'ls', label: 'Lab sheet'},
  {key: 'doa', label: 'DOA'},
  {key: 'irb', label: 'IRB approved ICF'},
  {key: 'pra', label: 'Patient reimbursement agreement'},
  {key: 'pss', label: 'Pre-screening script'},
  {key: 'pp', label: 'Prohibited procedures'},
  {key: 'vs', label: 'Visit schedule'},
];

const documents: {key: keyof ServerSharedLinks; label: string}[] = [
  {key: 'memp', label: 'Medical event management plan'},
  {key: 'el', label: 'Enrollment log'},
  {key: 'sp', label: 'Sponsor protocol'},
  {key: 'csrm', label: 'Clinical study reference manual'},
  {key: 'em', label: 'Equipment manuals'},
  {key: 'lm', label: 'Lab manual'},
  {key: 'sv', label: 'Screening visit'},
];

interface Props {
  trialId: number;
}

export const TrialSharedDrive = observer(({trialId}: Props) => {
  const request = STORE_TRIAL_LINKS.getTrialLinks;
  const data = STORE_TRIAL_LINKS._links;
  const communication = STORE_TRIAL_LINKS.getLinksCommunication;
  const isLoading = communication.type === 'requesting';

  useEffect(() => {
    request(trialId);
  }, [trialId, request]);

  useOnErrorCommunication(communication);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;

  return <SharedDrive links={data} />;
});

type SharedDriveProps = {
  links: ServerSharedLinks;
};

function SharedDrive({links}: SharedDriveProps) {
  const {isAdmin} = useAzureRole();

  return (
    <Box m={0} p={0}>
      <Box>
        <Box mb="30px">
          <TrialSharedLink
            customIcon={FolderDriveLinkSVG}
            name={general.key}
            title={general.label}
            data={links[general.key]}
          />
        </Box>
        <TrialTitle title="Protocol and recruitment information" />
        <Box className={styles.linksTable}>
          <Grid container className={styles.tableHeader}>
            <Grid item xs>
              <span className={styles.tableTitle}>Document</span>
            </Grid>
            <Grid item xs={false}>
              <Box width={200} className={styles.tableTitle}>
                Last updated
              </Box>
            </Grid>
            {isAdmin && (
              <Grid item xs={false}>
                <Box width={200} className={styles.tableTitle} />
              </Grid>
            )}
          </Grid>
          {protocols.map((item) => (
            <TrialSharedLink key={item.key} name={item.key} title={item.label} data={links[item.key]} />
          ))}
        </Box>
      </Box>

      <Box pt="32pt">
        <TrialTitle title="Trial documents" />
        <Box className={styles.linksTable}>
          <Grid container className={styles.tableHeader}>
            <Grid item xs>
              <span className={styles.tableTitle}>Document</span>
            </Grid>
            <Grid item xs={false}>
              <Box width={200} className={styles.tableTitle} />
            </Grid>
            {isAdmin && (
              <Grid item xs={false}>
                <Box width={200} className={styles.tableTitle} />
              </Grid>
            )}
          </Grid>
          {documents.map((item) => (
            <TrialSharedLink key={item.key} name={item.key} title={item.label} data={links[item.key]} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
