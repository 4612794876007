import {Divider, Stack, Typography} from '@mui/material';
import {LogoSmall} from 'components/complex/Logo/LogoSmall';
import {ServerSiteResponse} from 'services/types/ServerSites';
import SitesTable from './SitesTable/SitesTable';
import Button from 'components/base/Button/Button';
import Title from 'components/base/Title/Title';
import {useNavigate} from 'react-router-dom';

interface Props {
  sites: ServerSiteResponse[];
  onDeleteSite: (siteId: string) => void;
}

const SitesContent = ({sites, onDeleteSite}: Props) => {
  const navigate = useNavigate();

  return (
    <Stack direction={'column'} spacing={3}>
      <LogoSmall />
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Title title="Sites" mainTitle style={{marginBottom: 0}} />
        <Button variant="outlined" onClick={() => navigate('/sites/new')}>
          <Stack direction="row" alignItems="center" spacing={0}>
            <Typography>+ Add new site</Typography>
          </Stack>
        </Button>
      </Stack>
      <Divider sx={{mt: '16px !important'}} />
      <SitesTable
        sites={sites}
        isAdminTable
        onDeleteSite={onDeleteSite}
        onEditSite={(id) => navigate(`/sites/${id}/edit`)}
      />
    </Stack>
  );
};

export default SitesContent;
