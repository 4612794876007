import React, {memo} from 'react';
import {Form, Formik} from 'formik';
import {Stack} from '@mui/material';
import TextInputField from 'components/base/Input/TextInputField';
import {equals} from 'ramda';
import {Communication} from 'models/Communication';
import {useOnSuccessCommunication} from 'helpers/hooks/useOnSuccessCommunication';
import {observer} from 'mobx-react-lite';
import Title from 'components/base/Title/Title';
import FormButtons from 'components/base/Button/FormButton/FormButtons';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';

interface IProps<T> {
  name: string;
  communication: Communication;
  data: T;
  onSubmit(values: T): void;
  onSuccess?(): void;
  onClose?(): void;
  placeholder?: string;
  title?: string;
  submitTitle?: string;
  isNullable?: boolean;
  type?: 'create' | 'edit';
  customButton?: React.ReactElement;
  validate?(value: string): string;
}

const ChangeSimpleFieldForm = <T,>(props: IProps<T>) => {
  const {onSuccess, onSubmit, onClose, validate} = props;
  const {
    name,
    title,
    placeholder = 'Enter',
    type = 'edit',
    submitTitle,
    customButton,
    data,
    communication,
  } = props;

  const isLoading = communication.type === 'requesting';

  useOnErrorCommunication(communication);
  useOnSuccessCommunication(communication, () => onSuccess && onSuccess());

  return (
    <Formik initialValues={data} onSubmit={onSubmit}>
      {({handleSubmit, values, initialValues, isValid}) => (
        <Form onSubmit={handleSubmit}>
          {title && <Title title={title} />}
          <Stack direction="column" justifyContent="end" alignItems="end">
            <TextInputField
              name={name}
              placeholder={placeholder}
              multiline={true}
              validate={validate}
            />
            <Stack
              width="100%"
              direction="row"
              justifyContent={customButton ? 'space-between' : 'end'}
              alignItems="baseline"
            >
              {customButton}
              <FormButtons
                submitTitle={submitTitle}
                isLoading={isLoading}
                isDisabled={!isValid || (type !== 'create' ? equals(values, initialValues) : false)}
                onClose={onClose}
              />
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

const memoizedChangeSimpleFieldForm = memo(observer(ChangeSimpleFieldForm)) as <T>(props: IProps<T>) => JSX.Element;
export {memoizedChangeSimpleFieldForm as ChangeSimpleFieldForm};
