import {useSearchParams} from 'react-router-dom';
import {useCallback, useMemo} from 'react';
import {ServerVendor} from 'services/types/ServerVendor';

const COMPARE_WITH_KEY = 'compare-with';
const COMPARE_MODE = 'compare-mode';

export function useVendorScreen(vendors: ServerVendor[]) {
  const [searchParams, setSearchParams] = useSearchParams();

  const splitMode = useMemo(() => searchParams.get(COMPARE_MODE) === 'true', [searchParams]);

  const vendorToCompareId = useMemo(() => {
    const compareId = Number(searchParams.get(COMPARE_WITH_KEY));
    if (isNaN(compareId)) {
      return undefined;
    }
    return compareId;
  }, [searchParams]);

  const vendorToCompare = useMemo(
    () => vendors.find((vendor) => vendor.id === vendorToCompareId),
    [vendorToCompareId, vendors]
  );

  const setSplitMode = useCallback(
    (value: boolean) => {
      setSearchParams(getSearchParams(value, vendorToCompareId));
    },
    [setSearchParams, vendorToCompareId]
  );

  const setCompareId = useCallback(
    (vendorId: number) => {
      setSearchParams(getSearchParams(splitMode, vendorId));
    },
    [setSearchParams, splitMode]
  );

  return {
    splitMode,
    setSplitMode,
    vendorToCompare,
    setCompareId,
  };
}

function getSearchParams(compareMode: boolean, vendorToCompareId: number | undefined) {
  const result: Record<string, string> = {};

  if (compareMode) {
    result[COMPARE_MODE] = `${compareMode}`;
    if (vendorToCompareId !== undefined) {
      result[COMPARE_WITH_KEY] = `${vendorToCompareId}`;
    }
  }

  return result;
}
