import {ServerTrialBase} from 'services/types/ServerTrialBase';
import styles from './TrialsListCard.module.scss';
import Stack from '@mui/material/Stack';
import {TrialStatus} from 'components/TrialsList/TrialStatus/TrialStatus';
import {getUserFullName} from 'services/types/converters/getUserFullName';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Avatar from 'components/base/Avatar/Avatar';
import Box from '@mui/material/Box';
import isEmpty from 'lodash/isEmpty';
import {Grid, useTheme} from '@mui/material';

type Props = {
  element: ServerTrialBase;
};
export const TrialsListCard = ({element}: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClick = useCallback(() => {
    navigate(`${element.id}/details`);
  }, [element.id, navigate]);

  return (
    <div className={styles.root} onClick={handleClick}>
      <Stack direction="column" spacing={1} sx={{p: 4}}>
        <div className={styles.header}>{element.name}</div>
        <Stack direction="column" spacing={4}>
          <Grid container spacing={1}>
            <Grid item>
              <TrialStatus status={element.status} isDisplayMode={true} />
            </Grid>
            {element.is_archived && (
              <Grid item>
                <Box
                  borderColor={theme.palette.text.disabled}
                  borderRadius={43}
                  padding="4px 10px"
                  border={1}
                  fontSize={12}
                  color={theme.palette.text.disabled}
                  width="fit-content"
                  textAlign="center"
                >
                  Archived
                </Box>
              </Grid>
            )}
          </Grid>
          <Stack direction="column" spacing={0.5}>
            <div className={styles.title}>Sponsor:</div>
            <div className={styles.value}>{element.sponsor}</div>
          </Stack>
          {!isEmpty(element.pi) ? (
            <Stack direction="row">
              <Box mt={0.5}>
                <div className={styles.title}>PIs:</div>
              </Box>
              <Stack direction="column" spacing={1}>
                {element.pi?.map((user) => (
                  <Stack direction="row" alignItems="center" key={user.id}>
                    <Avatar user={user} size={24} />
                    <div className={styles.value}>{getUserFullName(user)}</div>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      </Stack>
    </div>
  );
};
