import React, {HTMLAttributes, memo, useCallback} from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  FormControl,
  InputProps,
  Paper,
  TextField,
  TextFieldProps,
} from '@mui/material';
import {SelectOption} from 'components/base/Select/Select';
import FormHelperText from 'components/base/FormHelperText/FormHelperText';
import {Nullable} from 'services/types/common';
import {makeStyles} from '@material-ui/styles';

interface IProps<T> {
  options: SelectOption<T>[];
  inputPlaceholder?: string;
  inputLabel?: string;
  onChange?(selectedOption: Nullable<SelectOption<T>>): void;
  errorMessage?: string;
  inputProps?: InputProps;
  textFieldProps?: TextFieldProps;
  hideErrorMessage?: boolean;
}

const useStyles = makeStyles(() => ({
  anotherBorder: {
    border: '2px solid #DFE1E6',
  },
}));

const CustomPaper = (props: HTMLAttributes<HTMLElement>) => <Paper style={{fontWeight: 400}} {...props} />;

type AllProps<T> = Omit<AutocompleteProps<SelectOption<T>, false, false, false>, 'renderInput' | 'onChange'> &
  IProps<T>;
function Combobox<T>(props: AllProps<T>) {
  const classes = useStyles();

  const {
    errorMessage,
    inputLabel = undefined,
    inputPlaceholder = 'Start typing to search...',
    onChange,
    inputProps,
    textFieldProps,
    hideErrorMessage,
    ...rest
  } = props;

  const onChangeHandler = useCallback(
    (event: React.SyntheticEvent, option: Nullable<SelectOption<T>>) => onChange && onChange(option),
    [onChange]
  );

  return (
    <FormControl
      sx={{
        m: 1,
        minWidth: 160,
        background: 'rgba(9, 30, 66, 0.04)',
        borderRadius: 3,
        margin: 0,
      }}
      size={'small'}
      error={Boolean(errorMessage)}
      fullWidth
    >
      <Autocomplete
        {...rest}
        onChange={onChangeHandler}
        PaperComponent={CustomPaper}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldProps}
            placeholder={inputPlaceholder}
            label={inputLabel}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              classes: {notchedOutline: classes.anotherBorder},
              style: {background: '#FAFBFC'},
              ...inputProps,
            }}
          />
        )}
      />
      {!hideErrorMessage && <FormHelperText errorMessage={errorMessage} />}
    </FormControl>
  );
}

export default memo(Combobox);
export type ComboboxProps<T> = IProps<T>;
