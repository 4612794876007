import {useCallback, useMemo, useState} from 'react';
import {NA_SELECT_OPTION, Select, SelectOption} from 'components/base/Select/Select';
import {ServerTrialBase} from 'services/types/ServerTrialBase';
import {useSponsorsOptions} from 'components/TrialsOverviewContent/filters/sponsors/useSponsorsOptions';

export function useSponsorsFilters(list: ServerTrialBase[]) {
  const sponsorsOptions = useSponsorsOptions(list);

  const [sponsorsFilterSelectedOption, setSponsorsFilterSelectedOption] =
    useState<SelectOption<string | undefined>>(NA_SELECT_OPTION);

  const sponsorsFilter = useCallback(
    (item: ServerTrialBase) =>
      sponsorsFilterSelectedOption.value === undefined ? true : sponsorsFilterSelectedOption.value === item.sponsor,
    [sponsorsFilterSelectedOption.value]
  );

  const sponsorsComponent = useMemo(
    () => (
      <Select
        value={sponsorsFilterSelectedOption}
        onChange={setSponsorsFilterSelectedOption}
        options={sponsorsOptions}
        placeholder={'All sponsors'}
      />
    ),
    [sponsorsFilterSelectedOption, sponsorsOptions]
  );

  return {
    sponsorsFilter,
    sponsorsComponent,
  };
}
