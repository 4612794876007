import {Stack, Typography} from '@mui/material';

import {LazyLoadImage} from 'react-lazy-load-image-component';

import styles from '../../PIGuide.module.scss';

// images
import img14 from 'images/guide/section2/C/img-14.jpg';
import img14Placeholder from 'images/guide/section2/C/img-14-placeholder.jpg';
import img15 from 'images/guide/section2/C/img-15.jpg';
import img15Placeholder from 'images/guide/section2/C/img-15-placeholder.jpg';
import img16 from 'images/guide/section2/C/img-16.jpg';
import img16Placeholder from 'images/guide/section2/C/img-16-placeholder.jpg';
import img17 from 'images/guide/section2/C/img-17.jpg';
import img17Placeholder from 'images/guide/section2/C/img-17-placeholder.jpg';

export const SectionC = () => (
  <section id="guide-screening-visits">
    <Stack gap={1} mb={4}>
      <Typography variant="subtitle1">C. Screening Visits - I/E Criteria, Medical History & Eligibility</Typography>
      <Typography>
        For screening visits, the above study visit activities are all the same, plus you will be responsible for
        evaluating the inclusion/exclusion (I/E) criteria, subject medical history, and making a decision regarding the
        eligibility of the subject to enroll in the study.
      </Typography>
      <Typography>
        For inclusion criteria, you’ll have to respond to each line item in the pop-up window, demonstrating a subject’s
        compliance (or lack thereof) with each criterion.
      </Typography>
      <LazyLoadImage
        src={img14}
        width={800}
        height={485}
        placeholderSrc={img14Placeholder}
        className={styles.img}
        effect="blur"
      />
      <Typography>The same is true for exclusion criteria.</Typography>
      <LazyLoadImage
        src={img15}
        width={800}
        height={485}
        placeholderSrc={img15Placeholder}
        className={styles.img}
        effect="blur"
      />
      <Typography>
        Followed by the “Inclusion/Exclusion Criteria Review,” which only requires responses to the first two items, if
        a subject is eligible to enroll. If the subject screen fails, you’ll need to complete the line items below, with
        support from your study team.
      </Typography>
      <LazyLoadImage
        src={img16}
        width={800}
        height={485}
        placeholderSrc={img16Placeholder}
        className={styles.img}
        effect="blur"
      />
      <Typography>
        And finally, the “Eligibility (PI Review),” which must be completed regardless of eligibility. You may be
        prompted to provide additional information or rationale, which can be supported by your study team
      </Typography>
      <LazyLoadImage
        src={img17}
        width={800}
        height={485}
        placeholderSrc={img17Placeholder}
        className={styles.img}
        effect="blur"
      />
    </Stack>
  </section>
);

export default SectionC;
