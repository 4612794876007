import {Checkbox} from 'components/base/Checkbox/Checkbox';

type Props = {
  value?: boolean;
  onChange: (value: boolean) => void;
  readonly?: boolean;
};

export default function CellRendererCheckbox({value, onChange, readonly}: Props) {
  return <Checkbox value={!!value} onChange={onChange} label="" readonly={readonly} />;
}
