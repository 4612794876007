import Typography from '@mui/material/Typography';
import {DataGridWithTypes} from 'components/base/Table/DataGridWithTypes';
import {MatrixEquipmentResponse, MatrixSiteEquipment} from 'services/types/ServerEquipment';
import CheckIcon from '@mui/icons-material/Check';
import {DataGridColumn} from 'components/base/Table/DataGridColumn';
import {RenderCellParams} from 'components/base/Table/renderCell/RenderCellParams';
import {useMemo} from 'react';
import {isEmpty} from 'lodash';
import {List, ListItem, Stack} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import TypographyWrapper from 'components/base/Typography/TypographyWrapper';
import {DeleteIconButton} from 'components/base/Button/DeleteIconButton';
import {EditIconButton} from 'components/base/Button/EditIconButton';

type Props = {
  data: MatrixEquipmentResponse;
  onDelete: (equipmentId: number) => void;
  disabledDelete?: boolean;
  onEdit: (
    event: React.MouseEvent<HTMLButtonElement>,
    {equipmentId, name}: {equipmentId: number; name: string}
  ) => void;
};

type Row = {
  id: number;
  name: string;
} & {[key: string]: MatrixSiteEquipment | number | string};

export const EquipmentAdminTable = ({data, onDelete, disabledDelete, onEdit}: Props) => {
  const {equipment, sites} = data;

  const columns: Array<DataGridColumn<Row>> = useMemo(
    () => [
      {
        field: 'Delete equipment',
        width: 50,
        renderHeader: () => null,
        renderCell: ({row}) => {
          return (
            <DeleteIconButton
              onClick={() => {
                onDelete(row.id);
              }}
              disabled={!!disabledDelete}
            />
          );
        },
      },
      {
        field: 'Edit equipment',
        width: 50,
        renderHeader: () => null,
        renderCell: ({row}) => {
          return (
            <EditIconButton
              onClick={(e) => {
                onEdit(e, {
                  equipmentId: row.id,
                  name: row.name,
                });
              }}
              sx={{
                width: 30,
                height: 30,
              }}
            />
          );
        },
      },
      {
        field: 'Equipment type',
        width: 200,
        renderHeader: () => null,
        renderCell: ({row}) => <Typography variant="subtitle2">{row.name}</Typography>,
        cellClassName: 'sticky-col',
      },
      ...sites.map((site) => ({
        field: site.name,
        width: 200,
        renderHeader: () => <TypographyWrapper withTooltip value={site.name} variant="subtitle2" />,
        renderCell: ({row}: RenderCellParams<Row>) => {
          const equipment = row[site.id] as MatrixSiteEquipment;

          if (!isEmpty(equipment)) {
            const equipmentLength = equipment.length;

            return (
              <Stack>
                <CheckIcon sx={{color: 'success.main'}} />
                <List disablePadding>
                  {equipment.map((item, index) => {
                    return (
                      <ListItem alignItems="flex-start" disablePadding key={`${row.id}-${row.name}-note-${index}`}>
                        {equipmentLength > 1 && item.note && <CircleIcon sx={{width: 4, mr: 1}} fontSize="small" />}
                        <Typography component="span" variant="body2">
                          {item.note}
                        </Typography>
                      </ListItem>
                    );
                  })}
                </List>
              </Stack>
            );
          } else return null;
        },
      })),
    ],
    [disabledDelete, onDelete, onEdit, sites]
  );

  const rows: Row[] = useMemo(() => {
    return equipment.map((equipment) => ({
      id: equipment.id,
      name: equipment.name,
      ...sites.reduce((acc, site) => {
        return {
          ...acc,
          [site.id]: site.equipment.filter((siteEquipment) => siteEquipment.id === equipment.id),
        };
      }, {}),
    }));
  }, [equipment, sites]);

  return (
    <DataGridWithTypes
      columns={columns}
      rows={rows}
      isHeaderSticky
      backgroundColor="#f6f8ff"
      styles={{width: 'calc(100vw - 160px)'}}
    />
  );
};
