import API from 'services/api';
import {ServerSiteResponse, ServerSitesRequest} from './types/ServerSites';

const DEFAULT_PREFIX = 'site';

export class SitesService {
  public static async getSites(): Promise<ServerSiteResponse[]> {
    const result = await API.get(`${DEFAULT_PREFIX}/`);
    return result.data;
  }

  public static async getSite(siteId: string): Promise<ServerSiteResponse> {
    const result = await API.get(`${DEFAULT_PREFIX}/${siteId}`);
    return result.data;
  }

  public static async createSite(site: ServerSitesRequest): Promise<ServerSiteResponse> {
    const result = await API.post(`${DEFAULT_PREFIX}/`, JSON.stringify(site));
    return result.data;
  }

  public static async editSite(siteId: string, site: ServerSitesRequest): Promise<ServerSiteResponse> {
    const result = await API.put(`${DEFAULT_PREFIX}/${siteId}`, JSON.stringify(site));
    return result.data;
  }

  public static async deleteSite(id: string): Promise<void> {
    return API.delete(`${DEFAULT_PREFIX}/${id}`);
  }
}
