import {ServerTrialBase} from 'services/types/ServerTrialBase';
import useClearableSelectOptions, {SelectOption} from 'components/base/Select/Select';
import {useMemo} from 'react';
import filterByLabel from 'components/TrialsOverviewContent/filters/utils/filterByLabel';

export function useSponsorsOptions(list: ServerTrialBase[]): SelectOption<string | undefined>[] {
  return useClearableSelectOptions(
    useMemo(() => {
      return list
        .map((item) => ({
          label: item.sponsor,
          value: item.sponsor,
        }))
        .filter(filterByLabel);
    }, [list])
  );
}
