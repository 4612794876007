import Box from '@mui/material/Box';
import {ReactComponent as BackEnSVG} from 'images/back_enabled.svg';
import styles from './BackButton.module.scss';

import TypographyWrapper from '../Typography/TypographyWrapper';
import {RouterLink} from '../RouterLink/RouterLink';

type BackButtonProps = {
  href: string;
  text?: string;
};

export const BackButton = ({href, text = 'Go back'}: BackButtonProps) => {
  return (
    <RouterLink to={href}>
      <Box display="flex" alignItems="center">
        <BackEnSVG fill="currentColor" className={styles.arrow} />
        <TypographyWrapper value={text} variant="body1" style={{marginLeft: 8}} />
      </Box>
    </RouterLink>
  );
};
