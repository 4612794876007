import {useCallback} from 'react';
import TextField from '@mui/material/TextField';
import {InputBase, InputCommonProps, useInputCommonProps} from 'components/base/Input/shared/InputBase';
import isNotNil from 'components/base/utils/isNotNil';
import {makeStyles} from '@material-ui/styles';
import * as React from 'react';
import MentionTextInputField from './MentionTextInputField';
import {InputProps} from '@mui/material';

export type TextInputProps = InputCommonProps & {
  value?: string;
  onChange(value: string): void;
  type?: 'input' | 'password' | 'number';
  multiline?: boolean;
  isMentionAllowed?: boolean;
  allUsers?: {id: string; display: string}[];
  helperText?: string;
  inputProps?: InputProps;
};

const useStyles = makeStyles(() => ({
  anotherBorder: {
    border: '2px solid #DFE1E6',
  },
}));

// todo add adornment icon with onEnter behavior like in design?
export default function TextInput(props: TextInputProps) {
  const classes = useStyles();
  const value = props.value ?? '';
  const allUsers = props.allUsers ?? [];
  const isMentionAllowed = props.isMentionAllowed;

  // const {type, passwordAdornmentIcon} = useHidden(props.type ? props.type : 'input');

  const {baseProps, fieldProps} = useInputCommonProps(
    {
      ...props,
      endAdornmentIcons: [...(props.endAdornmentIcons ?? []) /*passwordAdornmentIcon*/].filter(isNotNil),
    },
    props.inputProps
  );

  const {onChange, onKeyDown: _onKeyDown} = props;
  const onChangeInput = useCallback(
    (e: {target: {value: string}}) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 && props.onEnter) {
      props.onEnter();
    }
  };

  return isMentionAllowed ? (
    <InputBase {...baseProps}>
      <MentionTextInputField
        value={value}
        onChange={onChangeInput}
        onKeyDown={onKeyDown}
        placeholder={props.placeholder}
        data={allUsers}
      />
    </InputBase>
  ) : (
    <InputBase {...baseProps}>
      <TextField
        {...fieldProps}
        onKeyDown={_onKeyDown}
        onChange={onChangeInput}
        value={value}
        type={props.type || 'input'}
        multiline={props.multiline}
        helperText={props.helperText}
        InputProps={{
          classes: {notchedOutline: classes.anotherBorder},
          style: {background: '#FAFBFC'},
          ...fieldProps.InputProps,
        }}
      />
    </InputBase>
  );
}

// function useHidden(type: 'input' | 'password') {
//   const [isValueHidden, setIsValueHidden] = useState(type === 'password');
//
//   const toggleValueHidden = useCallback(() => {
//     setIsValueHidden(!isValueHidden);
//   }, [isValueHidden]);
//
//   const passwordAdornmentIcon =
//     type === 'password' ? (
//       <IconButton key={'password'} onClick={toggleValueHidden}>
//         {isValueHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
//       </IconButton>
//     ) : null;
//
//   return {
//     type: isValueHidden ? 'password' : 'input',
//     passwordAdornmentIcon,
//   };
// }
