import {Box} from '@mui/material';
import styles from 'components/TrialContent/vendor/ScreenFrame.module.scss';
import {ReactComponent as BackEnSVG} from 'images/back_enabled.svg';
import {ReactComponent as BackDisSVG} from 'images/back_disabled.svg';
import {ReactComponent as FwdEnSVG} from 'images/forward_enabled.svg';
import {ReactComponent as FwdDisSVG} from 'images/forward_disabled.svg';
import {ReactComponent as DividerSVG} from 'components/TrialContent/vendor/images/divider.svg';
import {useRef, useEffect, useState} from 'react';
import useIsElectronApp from 'helpers/hooks/useIsElectronApp';
import Title from 'components/base/Title/Title';
import {BackButton} from 'components/base/BackButton/BackButton';
import {useParams} from 'react-router-dom';

export function ScreenFrame({url, vendorName}: {url: string; vendorName: string}) {
  const {isElectronApp} = useIsElectronApp();
  const {trialId} = useParams();

  const viewRef = useRef<any>(null);
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoForward, setCanGoForward] = useState(false);

  useEffect(() => {
    viewRef?.current?.addEventListener('did-navigate', () => {
      setCanGoBack(viewRef?.current?.canGoBack());
      setCanGoForward(viewRef?.current?.canGoForward());
    });
  });

  function clickBackButton() {
    if (!canGoBack) {
      return;
    }
    const webview = viewRef.current;
    if (webview != null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      webview.goBack();
    }
  }

  function clickForwardButton() {
    if (!canGoForward) {
      return;
    }
    const webview = viewRef.current;
    if (webview != null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      webview.goForward();
    }
  }

  if (!isElectronApp) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '24px',
          textAlign: 'center',
        }}
      >
        <Title title={`Opened ${vendorName} in new browser tab.`} />
        <BackButton text="Back to trial" href={`/trials/${trialId}/details`} />
      </Box>
    );
  }

  return (
    <Box className={styles.screenFrameContainer} height={'100%'}>
      <webview ref={viewRef} className={styles.screenFrame} src={url} />
      <div className={styles.buttonsContainer}>
        <div className={styles.button} onClick={clickBackButton}>
          {canGoBack ? <BackEnSVG /> : <BackDisSVG />}
        </div>
        <div className={styles.divider}>
          <DividerSVG />
        </div>
        <div className={styles.button} onClick={clickForwardButton}>
          {canGoForward ? <FwdEnSVG /> : <FwdDisSVG />}
        </div>
      </div>
    </Box>
  );
}
