import styles from 'components/complex/BasicLayout/BasicLayout.module.scss';
import SettingsIcon from 'components/complex/BasicLayout/SettingsIcon';
import {MsalAuthenticationTemplate, useMsal} from '@azure/msal-react';
import {InteractionStatus, InteractionType} from '@azure/msal-browser';
import {ReactComponent as HomeSVG} from 'components/complex/BasicLayout/icons/home.svg';
import {ReactComponent as LocationSVG} from 'images/location.svg';
import {ReactComponent as EquipmentSVG} from 'images/equipment.svg';
import {loginRequest} from 'authConfig';
import {UserData} from 'services/types/ServerUser';
import {AzureService} from 'services/AzureService';
import {useEffect, useState} from 'react';
import AvatarImage from 'components/base/Avatar/Avatar';
import {Box, Grid} from '@mui/material';
import Icon from 'components/base/Icon/Icon';
import {useNavigate} from 'react-router-dom';
import useAzureRole from 'helpers/hooks/useAzureRole';

interface Props {
  children: JSX.Element;
  noPadding?: boolean;
}

export const BasicLayout = (props: Props) => {
  const navigate = useNavigate();
  const {isAdmin} = useAzureRole();

  const authRequest = {
    ...loginRequest,
  };

  const {instance, inProgress} = useMsal();
  const [userData, setUserData] = useState<null | UserData>(null);

  useEffect(() => {
    if (!userData && inProgress === InteractionStatus.None) {
      AzureService.GetMe().then((response) => setUserData(response));
    }
  }, [inProgress, userData, instance]);

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
      <div className={styles.root}>
        <div className={styles.sidePanel}>
          <Grid container flexDirection="column" alignItems="center" justifyContent="flex-start" gap={2}>
            <Icon onClick={() => navigate('/trials')} Icon={HomeSVG} />
            {isAdmin && (
              <>
                <Icon onClick={() => navigate('/sites')} Icon={LocationSVG} />
                <Icon onClick={() => navigate('/equipment')} Icon={EquipmentSVG} />
              </>
            )}
          </Grid>
          <div style={{display: 'flex', flexDirection: 'column', gap: 16}}>
            {userData && (
              <AvatarImage
                size={28}
                user={{
                  id: userData?.id,
                  first_name: userData?.givenName,
                  last_name: userData?.surname,
                  display_name: userData?.displayName,
                }}
              />
            )}
            <SettingsIcon />
          </div>
        </div>
        <Box sx={{width: '100%', padding: props.noPadding ? 0 : '54px', ml: '48px'}}>{props.children}</Box>
      </div>
    </MsalAuthenticationTemplate>
  );
};
