import {TypographyOptions} from '@mui/material/styles/createTypography';

const commonProps: React.CSSProperties = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
};

export const muiThemeTypography: TypographyOptions = {
  h1: {...commonProps, fontWeight: 300, fontSize: 96},
  h2: {...commonProps, fontWeight: 300, fontSize: 60},
  h3: {...commonProps, fontWeight: 400, fontSize: 48},
  h4: {...commonProps, fontWeight: 400, fontSize: 34},
  h5: {...commonProps, fontWeight: 400, fontSize: 24},
  h6: {...commonProps, fontWeight: 500, fontSize: 20},
  subtitle1: {...commonProps, fontWeight: 600, fontSize: 16},
  subtitle2: {...commonProps, fontWeight: 600, fontSize: 14},
  body1: {...commonProps, fontWeight: 500, fontSize: 14},
  body2: {...commonProps, fontWeight: 400, fontSize: 14},
};
