import API from 'services/api';
import {BrowserUtils} from '@azure/msal-browser';
// MSAL imports
import {PublicClientApplication, EventType, EventMessage, AuthenticationResult} from '@azure/msal-browser';
import {msalConfig} from 'authConfig';
import {ServerUser, UserData} from 'services/types/ServerUser';
import {graphEndpoints} from 'authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
    API.post('/logger/user-logged-in');
  }
});

export class AzureService {
  public static async GetMe(): Promise<UserData> {
    const result = await API.get(graphEndpoints.Me);
    return result.data;
  }

  public static async getUsers(): Promise<ServerUser[]> {
    const result = await API.get('user/');
    return result.data;
  }

  public static async Logout() {
    msalInstance.logoutRedirect({
      account: msalInstance.getActiveAccount(),
      onRedirectNavigate: () => !BrowserUtils.isInIframe(),
    });
  }
}
