export default function validateNotEmptyDate(value: Date | undefined): string {
  if (!value) {
    return 'Required';
  } else {
    try {
      if (value.toISOString()) {
        return '';
      }
      return '';
    } catch (_) {
      return 'Incorrect date format';
    }
  }
}
