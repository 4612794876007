import {Box} from '@mui/material';
import TrialContentLayout from 'components/TrialContent/TrialContentLayout';
import WithCommunication from 'components/base/WithCommunication/WithCommunication';
import {SiteForm} from 'components/complex/SiteForm/SiteForm';
import {memo, useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {SitesService} from 'services/SitesService';
import {ServerTrial} from 'services/types/ServerTrial';
import {ServerVendor} from 'services/types/ServerVendor';
import {useRequestData} from 'services/useRequestData';

interface Props {
  trial: ServerTrial;
  vendors: ServerVendor[];
}

type LocationState =
  | {
      from: {
        pathname: string;
        title: string;
      };
    }
  | undefined;

const EditSiteInTrial = ({trial, vendors}: Props) => {
  const params = useParams();
  const location = useLocation();
  const from = (location?.state as LocationState)?.from;

  const siteId = params.siteId;

  const {communication, request: getSite, data} = useRequestData(SitesService.getSite);

  useEffect(() => {
    if (siteId) {
      getSite(siteId);
    }
  }, [siteId, getSite]);

  return (
    <TrialContentLayout vendors={vendors} trial={trial}>
      <Box px={6} py={5}>
        <WithCommunication communication={communication} data={data}>
          {(loadedSite) => (
            <SiteForm
              backRoute={from ?? {pathname: `/trials/${trial.id}/sites`, title: 'Back to trial'}}
              site={loadedSite}
            />
          )}
        </WithCommunication>
      </Box>
    </TrialContentLayout>
  );
};

export default memo(EditSiteInTrial);
