const SIZE = 28;

type Props = {
  children: JSX.Element;
};

export const SVGIcon = (props: Props) => {
  return (
    <div
      style={{
        width: SIZE,
        height: SIZE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
    >
      {props.children}
    </div>
  );
};
