import CellRendererEmpty from './CellRendererEmpty';
import {ServerUser} from 'services/types/ServerUser';
import CellRendererUser from './CellRendererUser';
import {Stack} from '@mui/material';

type CellRendererStringProps = {
  users?: ServerUser[];
};

export default function CellRendererUsers(props: CellRendererStringProps) {
  if (!props.users) {
    return <CellRendererEmpty />;
  }

  const {users} = props;

  return (
    <Stack direction={'column'}>
      {users.map((user) => {
        return <CellRendererUser key={user.id} user={user} />;
      })}
    </Stack>
  );
}
