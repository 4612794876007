import React, {useCallback, useMemo, useState} from 'react';
import {ServerDetails} from 'services/types/ServerDetails';
import {TrialDetailsAccordion} from 'components/TrialContent/trial/details/TrialDetailsAccordion';
import {forEachObjIndexed} from 'ramda';
import {JobTitle, ServerContacts} from 'services/types/ServerContacts';
import {ServerContact} from 'services/types/ServerContact';
import Stack from '@mui/material/Stack';
import AddInfoButton from 'components/base/Button/AddInfoButton';
import styles from 'components/TrialContent/trial/details/blocks/right/KeyStudyContacts.module.scss';
import {AvatarUnknown} from 'components/base/Avatar/AvatarUnknown';
import {getUserFullName} from 'services/types/converters/getUserFullName';
import Divider from '@mui/material/Divider';
import useAzureRole from 'helpers/hooks/useAzureRole';
import {usePopover} from 'components/base/Popover/usePopover';
import Popover from 'components/base/Popover/Popover';
import {EditIconButton} from 'components/base/Button/EditIconButton';
import EditKeyStudyContactForm from '../EditKeyStudyContactForm';

interface Props {
  details: ServerDetails;
  canEdit: boolean;
}
export const KeyStudyContacts = (props: Props) => {
  const {
    details: {contacts},
    canEdit,
  } = props;

  const [title, setTitle] = useState<JobTitle>(JobTitle.MedicalMonitor);

  const {isAdmin} = useAzureRole();

  const contactsList = useMemo(() => {
    return getItemsFromContacts(contacts);
  }, [contacts]);

  const {popoverProps, onOpen, onClose} = usePopover();

  const onOpenHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, title: JobTitle) => {
      onOpen(event);
      setTitle(title);
    },
    [onOpen, setTitle]
  );

  return (
    <>
      <Popover {...popoverProps} horizontal="left">
        <EditKeyStudyContactForm onClose={onClose} name={title} onSuccess={onClose} />
      </Popover>

      <TrialDetailsAccordion title="Key study contacts">
        <Stack direction="column">
          {Object.entries(JobTitle).map(([, value]) => {
            const contact = contactsList.find((i) => i.title === value);
            if (contact) {
              const {title, item} = contact;
              return (
                <React.Fragment key={title}>
                  <Stack direction="column" key={title}>
                    <Stack direction="row" justifyContent="space-between">
                      <div className={styles.title}>{title}</div>
                      {canEdit && <EditIconButton onClick={(event) => onOpenHandler(event, title)} />}
                    </Stack>

                    <Stack direction="row" justifyContent="stretch">
                      <AvatarUnknown user={item} size={24} />
                      <Stack direction="column" sx={{width: '100%'}}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <div className={styles.fullName}>
                            {getUserFullName({
                              first_name: item.first_name,
                              last_name: item.last_name,
                            })}
                          </div>
                          <div className={styles.phone}>{item.phone}</div>
                        </Stack>
                        <div className={styles.email}>{item.email}</div>
                      </Stack>
                    </Stack>
                    <Divider sx={{height: 1}} />
                  </Stack>
                </React.Fragment>
              );
            }

            if (!isAdmin) return null;

            return (
              <Stack direction="column" key={value}>
                <Stack direction="row" justifyContent="space-between">
                  <div className={styles.title}>{value}</div>
                  {canEdit ? <AddInfoButton onClick={(event) => onOpenHandler(event, value)} /> : 'N/A'}
                </Stack>
                <Divider sx={{height: 1}} />
              </Stack>
            );
          })}
        </Stack>
      </TrialDetailsAccordion>
    </>
  );
};

interface ItemData {
  title: JobTitle;
  item: ServerContact;
}
function getItemsFromContacts(contacts: ServerContacts | undefined): ItemData[] {
  if (!contacts) {
    return [];
  }

  const result: ItemData[] = [];
  forEachObjIndexed((item, title) => result.push({item, title}), contacts);
  return result;
}
