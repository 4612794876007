import {useCallback, useMemo} from 'react';
import {TrialsList} from 'components/TrialsList/TrialsList';
import Stack from '@mui/material/Stack';
import {ServerTrialBase, ServerTrialBaseRequest} from 'services/types/ServerTrialBase';
import {LogoSmall} from 'components/complex/Logo/LogoSmall';
import getArrayWithAppliedFilters from 'components/base/utils/getArrayFromFilters';
import {useStatusesFilters} from 'components/TrialsOverviewContent/filters/statuses/useStatusesFilters';
import {useSponsorsFilters} from 'components/TrialsOverviewContent/filters/sponsors/useSponsorsFilters';
import {usePIsFilters} from 'components/TrialsOverviewContent/filters/pi/usePIsFilters';
import useNameFilter from 'components/TrialsOverviewContent/filters/name/useNameFilter';
import Button from 'components/base/Button/Button';
import {useDialog} from 'components/base/Dialog/useDialog';
import Dialog from 'components/base/Dialog/Dialog';
import useAzureRole from 'helpers/hooks/useAzureRole';
import CreateNewTrialForm from 'components/TrialsOverviewContent/createNewTrial/CreateNewTrialForm';
import styles from './TrialsOverviewContent.module.scss';
import {useSortBy} from './sorting/useSortBy';
import {Select} from 'components/base/Select/Select';
import isEmpty from 'lodash/isEmpty';
import {Divider} from '@mui/material';
import partition from 'lodash/partition';

type Props = {
  list: ServerTrialBase[];
  reloadTrials(...p: ServerTrialBaseRequest[]): void;
};

export const TrialsOverviewContent = ({list, reloadTrials}: Props) => {
  const {nameFilter, nameComponent} = useNameFilter();
  const {pIsFilter, pIsComponent} = usePIsFilters(list);
  const {sponsorsFilter, sponsorsComponent} = useSponsorsFilters(list);
  const {statusesFilter, statusesComponent} = useStatusesFilters(list);
  const {orderBy, orderType, sortBySelectedOption, setSortBySelectedOption, sortOptions} = useSortBy(reloadTrials);

  const {isAdmin} = useAzureRole();

  const filteredList = useMemo(
    () =>
      getArrayWithAppliedFilters({
        arr: list,
        filters: [nameFilter, pIsFilter, sponsorsFilter, statusesFilter],
      }),
    [list, pIsFilter, nameFilter, sponsorsFilter, statusesFilter]
  );

  const listPartition = useMemo(
    () => partition(filteredList, (trial: ServerTrialBase) => trial.is_archived),
    [filteredList]
  );
  const archivedList = listPartition[0];
  const unArchivedList = listPartition[1];

  const {isOpen, onOpen, onClose} = useDialog();
  const onSuccessAddTrialForm = useCallback(() => {
    onClose();
    reloadTrials({orderBy, orderType});
  }, [onClose, reloadTrials, orderBy, orderType]);

  return (
    <>
      <Dialog isOpen={isOpen}>
        <CreateNewTrialForm onSuccess={onSuccessAddTrialForm} onClose={onClose} />
      </Dialog>

      <Stack direction={'column'} spacing={3}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'baseline'}>
          <LogoSmall />
          <Stack direction={'row'} spacing={1} alignContent={'center'}>
            {nameComponent}
            {pIsComponent}
            {sponsorsComponent}
            {statusesComponent}
            <Select
              value={sortBySelectedOption}
              onChange={setSortBySelectedOption}
              options={sortOptions}
              placeholder={'Sort by'}
            />
            {isAdmin && <Button onClick={onOpen}>+ New Trial</Button>}
          </Stack>
        </Stack>
        {!isEmpty(unArchivedList) && (
          <>
            <div className={styles.title}>Trial overview</div>
            <TrialsList list={unArchivedList} />
          </>
        )}
        {!isEmpty(archivedList) && (
          <>
            {!isEmpty(unArchivedList) && <Divider sx={{mt: '78px !important'}} />}
            <div className={styles.subtitle}>Archived trials</div>
            <TrialsList list={archivedList} />
          </>
        )}
      </Stack>
    </>
  );
};
