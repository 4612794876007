import styles from './TrialTitle.module.scss';

interface Props {
  title: string;
  size?: string | number;
}
export function TrialTitle(props: Props) {
  const {title, size} = props;
  return (
    <span className={styles.title} style={{fontSize: size}}>
      {title}
    </span>
  );
}
