import React, {memo} from 'react';
import {Stack} from '@mui/material';
import Button from 'components/base/Button/Button';
import FormButtons, {FormButtonsProps} from 'components/base/Button/FormButton/FormButtons';

const isStickyStyles = {
  bottom: -18,
  position: 'sticky',
  background: '#FFF',
  borderTop: '1px solid #EEE',
  paddingTop: '18px',
};

interface IProps {
  isEditingMode: boolean;
  disableEditingMode(): void;
}

type AllProps = IProps & FormButtonsProps;
const FooterSection = (props: AllProps) => {
  const {isEditingMode, disableEditingMode, ...formButtonsProps} = props;

  if(isEditingMode) {
    return (
      <Stack justifyContent="end" direction="row" pb="18px" sx={isStickyStyles}>
        <Button onClick={disableEditingMode}>
          Done
        </Button>
      </Stack>
    );
  }

  return (
    <FormButtons isSticky={true} {...formButtonsProps} />
  );
};

export default memo(FooterSection);