import {FieldConfig, Field, FieldProps} from 'formik';
import DatePicker, {DatePickerProps} from 'components/base/DatePicker/DatePicker';

type Props = FieldConfig & Omit<DatePickerProps, 'value' | 'onChange'>;

export default function DatePickerField(props: Props) {
  return (
    <Field {...props}>
      {({field, form, meta}: FieldProps<Date>) => (
        <DatePicker
          {...props}
          value={field.value}
          onChange={(v) => form.setFieldValue(field.name, v)}
          errorMessage={meta.error}
        />
      )}
    </Field>
  );
}
