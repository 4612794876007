import {memo} from 'react';
import {Field, FieldConfig, FieldProps} from 'formik';
import {SelectOption} from 'components/base/Select/Select';
import Creatable, {CreatableProps} from './Creatable';

type AllProps = FieldConfig & CreatableProps;
function ComboboxField(props: AllProps) {
  return (
    <Field {...props}>
      {({form}: FieldProps<SelectOption<string>>) => (
        <Creatable onChange={(item) => form.setFieldValue(props.name, item)} {...props} />
      )}
    </Field>
  );
}

export default memo(ComboboxField);
