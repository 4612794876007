import {CSSProperties, memo} from 'react';
import Typography, {TypographyProps} from '@mui/material/Typography';
import OverflowTooltip from '../Tooltip/OverflowTooltip';

type Props = {
  value: string | JSX.Element;
  style?: CSSProperties;
  variant?: TypographyProps['variant'];
  breakAll?: boolean;
  withTooltip?: boolean;
};

const TypographyWrapper = ({value, style, variant, breakAll, withTooltip}: Props) => {
  if (withTooltip) {
    return (
      <OverflowTooltip title={value} typographyProps={{style, variant}}>
        <>{value}</>
      </OverflowTooltip>
    );
  }

  return (
    <div style={{width: '100%', overflowX: 'auto'}}>
      <Typography
        style={style}
        component="div"
        sx={
          breakAll
            ? {whiteSpace: 'pre-wrap', wordBreak: 'break-all'}
            : {
                whiteSpace: 'pre-line',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }
        }
        variant={variant ?? 'body2'}
      >
        {value}
      </Typography>
    </div>
  );
};

export default memo(TypographyWrapper);
