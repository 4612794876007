import {Communication} from 'models/Communication';
import {Nullable} from 'services/types/common';
import {makeAutoObservable} from 'mobx';
import {TrialsService} from 'services/TrialsService';
import {ServerSharedLinks} from 'services/types/ServerSharedLinks';

const INITIAL = {
  _links: null,
  communication: {type: 'notAsked'} as Communication,
};

class StoreTrialLinks {
  _links: Nullable<ServerSharedLinks> = INITIAL._links;
  getLinksCommunication: Communication = INITIAL.communication;
  changeLinksCommunication: Communication = INITIAL.communication;

  constructor() {
    makeAutoObservable(this);
  }

  onUnmountCommunications() {
    this.getLinksCommunication = INITIAL.communication;
    this.changeLinksCommunication = INITIAL.communication;
  }

  getTrialLinks = (id: number) => {
    this.getLinksCommunication = {type: 'requesting'};
    TrialsService.getTrialSharedLinks(id)
      .then((result) => {
        this.getLinksCommunication = {type: 'success'};
        this._links = result;
      })
      .catch((error: string) => {
        this.getLinksCommunication = {type: 'error', error};
      });
  };

  changeTrialLinks = (trialId: number, data: ServerSharedLinks) => {
    this.changeLinksCommunication = {type: 'requesting'};
    TrialsService.changeTrialSharedLinks(trialId, data)
      .then(() => {
        this.changeLinksCommunication = {type: 'success'};
        this._links = data;
      })
      .catch((error: string) => {
        this.changeLinksCommunication = {type: 'error', error};
      });
  };
}

export const STORE_TRIAL_LINKS = new StoreTrialLinks();
