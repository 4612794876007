import {ServerDetails} from 'services/types/ServerDetails';
import {TrialDetailsAccordion} from 'components/TrialContent/trial/details/TrialDetailsAccordion';
import Stack from '@mui/material/Stack';
import {TrialDetailsKeyValue} from 'components/TrialContent/trial/details/TrialDetailsKeyValue';
import {memo, useCallback, useState} from 'react';
import {EditIconButton} from 'components/base/Button/EditIconButton';
import AddInfoButton from 'components/base/Button/AddInfoButton';
import {STORE_TRIAL} from 'store/StoreTrial';
import {ChangeSimpleFieldForm} from 'components/base/Form/ChangeSimpleFieldForm';
import {observer} from 'mobx-react-lite';
import {usePopover} from 'components/base/Popover/usePopover';
import Popover from 'components/base/Popover/Popover';
import {Box} from '@mui/material';
import validateOnlyPositiveNumbers from 'validators/validateOnlyPositiveNumbers';

const recruitmentProgressFields: {label: string; fieldName: keyof ServerDetails}[] = [
  {label: 'Recruitment target', fieldName: 'recruitment_target'},
  {label: 'Enrolled number', fieldName: 'enrolled_number'},
  {label: 'Current active patient number', fieldName: 'current_active_patient_number'},
  {label: 'Screen fail number', fieldName: 'screen_fail_number'},
  {label: 'Completed study number', fieldName: 'completed_study_number'},
];

interface Props {
  details: ServerDetails;
  canEdit: boolean;
}
const RecruitmentProgress = (props: Props) => {
  const {details, canEdit} = props;

  const {onOpen, onClose, popoverProps} = usePopover();

  const [name, setName] = useState<keyof ServerDetails>();
  const onOpenHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, name: keyof ServerDetails) => {
      setName(name);
      onOpen(event);
    },
    [setName, onOpen]
  );

  const changeTrialDetails = useCallback(
    (values: ServerDetails) => {
      STORE_TRIAL.changeDetails({
        ...details,
        ...values,
      });
    },
    [details]
  );

  const renderEdit = useCallback(
    (fieldName: keyof ServerDetails) => (
      <Stack direction="row" gap={1}>
        {`${details[fieldName]}`} patients
        {canEdit && <EditIconButton />}
      </Stack>
    ),
    [details, canEdit]
  );

  const renderAddButton = useCallback(() => {
    if (canEdit) {
      return <AddInfoButton />;
    } else {
      return 'N/A';
    }
  }, [canEdit]);

  return (
    <>
      <Popover {...popoverProps} width={250} horizontal="left">
        {name && (
          <ChangeSimpleFieldForm
            title=" "
            onClose={onClose}
            onSubmit={changeTrialDetails}
            onSuccess={onClose}
            data={details}
            name={name}
            validate={validateOnlyPositiveNumbers}
            communication={STORE_TRIAL.changeDetailsCommunication}
          />
        )}
      </Popover>

      <TrialDetailsAccordion title="Recruitment progress">
        <Stack direction="column" spacing={1}>
          {recruitmentProgressFields.map(({label, fieldName}) => (
            <TrialDetailsKeyValue key={fieldName} k={label}>
              <Box onClick={canEdit ? (event) => onOpenHandler(event, fieldName) : undefined}>
                {details[fieldName] ? renderEdit(fieldName) : renderAddButton()}
              </Box>
            </TrialDetailsKeyValue>
          ))}
        </Stack>
      </TrialDetailsAccordion>
    </>
  );
};

export default memo(observer(RecruitmentProgress));
