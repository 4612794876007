/* eslint-disable react-hooks/exhaustive-deps */
import SitesTable from 'components/SitesContent/SitesTable/SitesTable';
import {useCallback, useEffect} from 'react';
import WithCommunication from 'components/base/WithCommunication/WithCommunication';
import {Stack} from '@mui/material';
import {TrialTitle} from '../details/TrialTitle';
import DropdownWithAction from 'components/base/DropdownWithAction/DropdownWithAction';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';
import {STORE_TRIAL_SITES} from 'store/StoreTrialSites';

interface Props {
  trialId: number;
}

export const TrialSites = observer(({trialId}: Props) => {
  const sites = STORE_TRIAL_SITES._sites;
  const availableTrialSites = STORE_TRIAL_SITES._availableSites;

  const communication = STORE_TRIAL_SITES.sitesCommunication;
  const changeSitesCommunication = STORE_TRIAL_SITES.changeSitesCommunication;
  const availableSitesCommunication = STORE_TRIAL_SITES.availableSitesCommunication;

  const canCreateSite = STORE_TRIAL_SITES.canCreateSite;
  const canUpdateSite = STORE_TRIAL_SITES.canUpdateSite;
  const canDeleteSite = STORE_TRIAL_SITES.canDeleteSite;

  const navigate = useNavigate();

  useOnErrorCommunication(changeSitesCommunication);

  const getSites = useCallback(
    (refetch?: boolean) => {
      STORE_TRIAL_SITES.getTrialSites(trialId, refetch);
    },
    [trialId]
  );

  const assignSiteToTrial = useCallback(
    (siteId: string) => {
      STORE_TRIAL_SITES.assignSiteToTrial(trialId, siteId);
    },
    [trialId]
  );

  const removeSiteFromTrial = useCallback(
    (siteId: string) => {
      STORE_TRIAL_SITES.removeSiteFromTrial(trialId, siteId);
    },
    [trialId]
  );

  const getAvailableTrialSites = useCallback(() => {
    STORE_TRIAL_SITES.getAvailableSites(trialId);
  }, [trialId]);

  useEffect(() => {
    getSites();
    return () => {
      STORE_TRIAL_SITES.onUnmountSites();
      STORE_TRIAL_SITES.onUnmountCommunications();
    };
  }, []);

  useEffect(() => {
    if (canCreateSite) {
      getAvailableTrialSites();
    }
  }, [canCreateSite]);

  return (
    <WithCommunication communication={communication} data={sites}>
      {(loadedSites) => (
        <Stack>
          <TrialTitle title="Trial's sites" />
          <SitesTable
            sites={loadedSites}
            onDeleteSite={canDeleteSite ? (id: string) => removeSiteFromTrial(id) : undefined}
            onEditSite={canUpdateSite ? (id) => navigate(`${id}/edit`) : undefined}
            hideOwners={!canUpdateSite}
          />
          {canCreateSite && (
            <DropdownWithAction
              items={availableTrialSites}
              onItemSelect={(id) => {
                assignSiteToTrial(id as string);
              }}
              isLoading={availableSitesCommunication.type === 'requesting'}
              buttonTitle="+ Add site"
              actionTitle="+ Add new site"
              onAction={() => navigate('new')}
            />
          )}
        </Stack>
      )}
    </WithCommunication>
  );
});
