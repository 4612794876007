import React, {memo} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Box, Stack} from '@mui/material';
import styles from 'components/Vendors/EditVendorsForm/EditVendorsForm.module.scss';
import AccordionDetails from '@mui/material/AccordionDetails';
import {ServerVendor} from 'services/types/ServerVendor';
import Button from 'components/base/Button/Button';
import {IVendorGroup} from 'components/Vendors/EditVendorsForm/useVendorGroups';
import Popover from 'components/base/Popover/Popover';
import EditVendorForm from 'components/Vendors/EditVendorForm/EditVendorForm';
import {usePopover} from 'components/base/Popover/usePopover';
import VendorSection from 'components/Vendors/EditVendorsForm/parts/VendorGroupSection/VendorSection';
import {observer} from 'mobx-react-lite';

interface IProps {
  data: IVendorGroup;
  isEditingMode: boolean;
}

const VendorGroupSection = (props: IProps) => {
  const {data, isEditingMode} = props;

  const toggledObjectStyles = isEditingMode ? styles.visible : styles.hidden;

  const centerSectionStyles = isEditingMode ? styles.leftMoved : styles.leftInPlace;

  const {popoverProps, onOpen, onClose: onClosePopover} = usePopover();

  return (
    <>
      <Popover {...popoverProps} horizontal="right">
        <EditVendorForm typeId={data.type} onClose={onClosePopover} onSuccess={onClosePopover}/>
      </Popover>

      <Accordion style={{border: 'none'}} defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box className={styles.subtitle}>{data.label}</Box>
        </AccordionSummary>
        <AccordionDetails>
          <Stack key={data.type} mb="16px" className={`${styles.animatedObject} ${centerSectionStyles}`}>
            {data.vendors.map((vendor: ServerVendor) => (
              <VendorSection key={vendor.id} data={vendor} isEditingMode={isEditingMode} />
            ))}
          </Stack>
          <Box
            style={{marginBottom: !isEditingMode ? -60 : 0}}
            className={`${styles.animatedObject} ${toggledObjectStyles}`}
          >
            <Button onClick={onOpen}>Add new vendor to category</Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(observer(VendorGroupSection));