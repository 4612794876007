import {Configuration, PopupRequest} from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: '36d955d5-e0c0-4ac4-833a-9a91107d000b',
    authority: 'https://login.microsoftonline.com/5949dd6d-4bb9-4069-b5f3-8cda16f6a6a5',
    redirectUri: '/token',
    postLogoutRedirectUri: '/',
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  // TODO there was 'Directory.Read.All' permission
  scopes: ['User.Read.All', 'email'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphEndpoints = {
  Me: 'https://graph.microsoft.com/v1.0/me',
  GetAvatarUrl: (userId: string) => `https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`
};
