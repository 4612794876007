import {ServerDetails} from 'services/types/ServerDetails';
import Stack from '@mui/material/Stack';
import {TrialTitle} from 'components/TrialContent/trial/details/TrialTitle';
import Box from '@mui/material/Box';
import {TrialKey} from 'components/TrialContent/trial/details/TrialKey';
import {StudyDescriptionTable} from 'components/TrialContent/trial/details/blocks/left/StudyDescriptionTable';
import styles from './StudyDescription.module.scss';
import AddInfoButton from 'components/base/Button/AddInfoButton';
import React, {useCallback} from 'react';
import {EditIconButton} from 'components/base/Button/EditIconButton';
import {observer} from 'mobx-react-lite';
import {STORE_TRIAL} from 'store/StoreTrial';
import {ChangeSimpleFieldForm} from 'components/base/Form/ChangeSimpleFieldForm';
import {usePopover} from 'components/base/Popover/usePopover';
import Popover from 'components/base/Popover/Popover';

interface Props {
  details: ServerDetails;
}

export const StudyDescription = observer((props: Props) => {
  const {details} = props;

  return (
    <Box>
      <Stack direction="column" spacing={1}>
        <TrialTitle title="Study description" />
        <Stack style={{wordBreak: 'break-word'}} direction="column" spacing={2}>
          <Block k="Sponsor:" name="sponsor" text={details.sponsor} />
          <Block k="CRO:" name="cro" text={details.cro} />
          <Block k="Brief summary:" name="summary" text={details.summary} />
          <Block k="Trial phase:" name="trial_phase" text={details.trial_phase} />
          <StudyDescriptionTable
            left={details.condition_of_desease ?? []}
            right={details.intervention_treatment ?? []}
          />
          <Block k="Detailed description:" name="description" text={details.description} />
        </Stack>
      </Stack>
    </Box>
  );
});

const Block = observer(({name, k, text}: {name: keyof ServerDetails; k: string; text: string | undefined}) => {
  const {popoverProps, onOpen, onClose} = usePopover();

  const details = STORE_TRIAL._details;
  const communication = STORE_TRIAL.changeDetailsCommunication;
  const canEdit = STORE_TRIAL.canEditTrial;

  const changeTrialDetails = useCallback(
    (values: ServerDetails) => {
      STORE_TRIAL.changeDetails({
        ...details,
        ...values,
      });
    },
    [details]
  );

  if (!canEdit && !text) return null;

  return (
    <>
      <Popover {...popoverProps} width={720} horizontal="center">
        <ChangeSimpleFieldForm
          title=" "
          onClose={onClose}
          onSubmit={changeTrialDetails}
          onSuccess={onClose}
          data={details}
          name={name}
          communication={communication}
        />
      </Popover>
      <Stack direction={text ? 'column' : 'row'} alignItems={text ? 'start' : 'center'} spacing={1}>
        <TrialKey k={k} />
        <Box onClick={canEdit ? onOpen : undefined}>
          {text ? (
            <Stack direction="row">
              <span className={styles.textBlock}>{text}</span>
              {canEdit && <EditIconButton />}
            </Stack>
          ) : (
            <AddInfoButton />
          )}
        </Box>
      </Stack>
    </>
  );
});
