import API from 'services/api';
interface BlobWithName {
  blob: Blob;
  name: string;
}

export class FileDownloadService {
  public static download(url: string) {
    return API.get(url, {responseType: 'blob'})
      .then((response): BlobWithName => {
        const blob = response.data as Blob;
        const contentDisposition = response.headers['content-disposition'];
        // todo not safe search, need to rewrite with regexp etc.
        const fileNameIndex = contentDisposition.indexOf('"') + 1;
        const fileNameIndexTo = contentDisposition.lastIndexOf('"');
        const name = contentDisposition.substring(fileNameIndex, fileNameIndexTo);
        return {blob, name};
      })
      .catch((e) => {
        throw Error(e);
      });
  }
}
