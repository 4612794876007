import {ServerTrialStatus} from 'services/types/ServerTrialStatus';
import {MainMuiTheme} from 'theme/MainMuiTheme';

export const mapServerTrialStatusToLabel: Record<ServerTrialStatus, string> = {
  [ServerTrialStatus.UNKNOWN]: 'Unknown',
  [ServerTrialStatus.EARLY_STAGE_PIPELINE]: 'Early Stage Pipeline',
  [ServerTrialStatus.SITE_QUALIFICATION]: 'Site Qualification',
  [ServerTrialStatus.SITE_ACTIVATION]: 'Site Activation',
  [ServerTrialStatus.OPEN_TO_ENROLLMENT]: 'Open To Enrollment',
  [ServerTrialStatus.CLOSED_TO_ENROLLMENT]: 'Closed To Enrollment',
  [ServerTrialStatus.CLOSED]: 'Closed',
  [ServerTrialStatus.NOT_MOVING_FORWARD]: 'Not Moving Forward',
};

export const mapServerTrialStatusToColor: Record<ServerTrialStatus, string> = {
  [ServerTrialStatus.UNKNOWN]: '#0288d1',
  [ServerTrialStatus.EARLY_STAGE_PIPELINE]: MainMuiTheme.palette.warning.main,
  [ServerTrialStatus.SITE_QUALIFICATION]: MainMuiTheme.palette.primary.main,
  [ServerTrialStatus.SITE_ACTIVATION]: MainMuiTheme.palette.primary.main,
  [ServerTrialStatus.OPEN_TO_ENROLLMENT]: MainMuiTheme.palette.primary.main,
  [ServerTrialStatus.CLOSED_TO_ENROLLMENT]: '#979797',
  [ServerTrialStatus.CLOSED]: '#979797',
  [ServerTrialStatus.NOT_MOVING_FORWARD]: MainMuiTheme.palette.error.main,
};
