import React, {memo} from 'react';
import FormButton, {FormButtonProps} from 'components/base/Button/FormButton/FormButton';
import CheckIcon from '@mui/icons-material/Check';

const SubmitIconButton = (props: FormButtonProps) => (
  <FormButton {...props} type="submit">
    <CheckIcon style={{width: 16, height: 16}} />
  </FormButton>
);

export default memo(SubmitIconButton);
