import Popover from '@mui/material/Popover';
import {ReactComponent as SettingsSVG} from 'components/complex/BasicLayout/icons/settings.svg';
import {SVGIcon} from 'components/base/SVGIcon/SVGIcon';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';

import {useState, MouseEvent, useCallback} from 'react';

export default function SettingsIcon() {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    navigate('/logout');
  }, [navigate]);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div aria-describedby={id} onClick={handleClick}>
        <SVGIcon>
          <SettingsSVG />
        </SVGIcon>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Button onClick={handleLogout}>Logout</Button>
      </Popover>
    </div>
  );
}
