import {useCallback, useMemo, useState} from 'react';
import {NA_SELECT_OPTION, Select, SelectOption} from 'components/base/Select/Select';
import {ServerTrialBase} from 'services/types/ServerTrialBase';
import {ServerTrialStatus} from 'services/types/ServerTrialStatus';
import useStatusesOptions from 'components/TrialsOverviewContent/filters/statuses/useStatusesOptions';

export function useStatusesFilters(list: ServerTrialBase[]) {
  const statusesOptions = useStatusesOptions(list);

  const [statusesFilterSelectedOption, setStatusesFilterSelectedOption] =
    useState<SelectOption<ServerTrialStatus | undefined>>(NA_SELECT_OPTION);

  const statusesFilter = useCallback(
    (item: ServerTrialBase) =>
      statusesFilterSelectedOption.value === undefined ? true : statusesFilterSelectedOption.value === item.status,
    [statusesFilterSelectedOption.value]
  );

  const statusesComponent = useMemo(
    () => (
      <Select
        value={statusesFilterSelectedOption}
        onChange={setStatusesFilterSelectedOption}
        options={statusesOptions}
        placeholder={'All statuses'}
      />
    ),
    [statusesFilterSelectedOption, statusesOptions]
  );

  return {
    statusesFilter,
    statusesComponent,
  };
}
