/* eslint-disable react-hooks/exhaustive-deps */
import {Stack} from '@mui/material';
import FormButtons from 'components/base/Button/FormButton/FormButtons';
import Combobox from 'components/base/Combobox/Combobox';
import {SelectOption} from 'components/base/Select/Select';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';
import {renderLabelName} from 'helpers/renderLabelName';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {AzureService} from 'services/AzureService';
import {TrialsService} from 'services/TrialsService';
import {ServerTrialUser} from 'services/types/ServerTrialUser';
import {ServerUser} from 'services/types/ServerUser';
import {useRequestData} from 'services/useRequestData';

type OwnersSelectProps = {
  onClose: () => void;
  onSubmit: (user: ServerUser) => void;
  trialId?: number;
  currentOwners?: ServerUser[];
};

export const OwnersSelect = ({onClose, onSubmit, trialId, currentOwners}: OwnersSelectProps) => {
  const [value, setValue] = useState<SelectOption<ServerUser>>();
  const {data: allUsers, communication: getCommunication, request: requestGet} = useRequestData(AzureService.getUsers);
  const {
    data: trialUsers,
    communication: getTrialUsersCommunication,
    request: requestGetTrialUsers,
  } = useRequestData(() => TrialsService.getTrialUsers(trialId ?? 0));

  useOnErrorCommunication(getCommunication);
  useOnErrorCommunication(getTrialUsersCommunication);

  const getUserOptions = useCallback((users: ServerUser[]) => {
    return users
      .filter((user) => !currentOwners?.find((owner) => owner.id === user.id))
      .map((user) => ({
        label: renderLabelName(user),
        value: user,
      }));
  }, []);

  const getTrialUserOptions = useCallback(
    (users: ServerTrialUser[]) => {
      const trialUsers = users.map((user) => user.user);
      return getUserOptions(trialUsers);
    },
    [getUserOptions]
  );

  const userOptions = useMemo(
    () => (trialId ? getTrialUserOptions(trialUsers?.data ?? []) : getUserOptions(allUsers ?? [])),
    [allUsers, getTrialUserOptions, getUserOptions, trialId, trialUsers]
  );

  const handleSubmit = () => {
    if (value) {
      onSubmit(value.value);
      onClose();
    }
  };

  useEffect(() => {
    if (trialId) {
      requestGetTrialUsers();
    } else {
      requestGet();
    }
  }, [trialId]);

  return (
    <Stack direction="column" spacing={1}>
      <Combobox
        options={userOptions}
        onChange={(selectValue) => setValue(selectValue as SelectOption<ServerUser>)}
        value={value || null}
        inputPlaceholder="Select user"
      />
      <FormButtons onClose={onClose} onSubmit={handleSubmit} isDisabled={!value} />
    </Stack>
  );
};
