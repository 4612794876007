import {usePrevious} from 'helpers/hooks/usePrevious';
import {Communication} from 'models/Communication';
import {useSnackbar} from 'notistack';
import {useEffect} from 'react';

export function useOnErrorCommunication(communication: Communication) {
  const prevCommunication = usePrevious<Communication>(communication);

  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (
      prevCommunication?.type === 'requesting' &&
      communication.type !== 'requesting' &&
      communication.type === 'error'
    ) {
      setTimeout(() => enqueueSnackbar(communication.error, {variant: 'error'}), 0);
    }
  }, [prevCommunication?.type, communication, enqueueSnackbar]);
}
