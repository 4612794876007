import React, {memo} from 'react';
import MuiPopover from '@mui/material/Popover';
import {PopoverProps} from '@mui/material/Popover/Popover';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import {SxProps} from '@mui/system';
import {Box, Theme} from '@mui/material';
import Loading from 'components/base/WithCommunication/Loading';

const disabledStyle: SxProps<Theme> = {
  px: 20,
  opacity: 0.5,
  pointerEvents: 'none',
  userSelect: 'none',
};

type VerticalSide = 'top' | 'bottom';
type HorizontalSide = 'left' | 'right' | 'center';

const mapTransformToAnchor: Record<VerticalSide, VerticalSide> = {
  bottom: 'top',
  top: 'bottom',
};

const mapTransformToSide: Record<HorizontalSide, HorizontalSide> = {
  left: 'right',
  right: 'left',
  center: 'center',
};

interface IProps {
  width?: string | number;
  vertical?: 'top' | 'bottom';
  horizontal?: 'right' | 'left' | 'center';
  anchorOriginHorizontal?: 'right' | 'left' | 'center';
  isLoading?: boolean;
}

type AllProps = React.PropsWithChildren<IProps & PopoverProps>;

const Popover = (props: AllProps) => {
  const {
    children,
    isLoading,
    width,
    vertical = 'bottom',
    horizontal = 'center',
    anchorOriginHorizontal = 'center',
    ...rest
  } = props;

  return (
    <MuiPopover
      {...rest}
      style={!rest.anchorEl ? {display: 'none'} : {}}
      elevation={0}
      anchorOrigin={{vertical, horizontal: anchorOriginHorizontal}}
      transformOrigin={{vertical: mapTransformToAnchor[vertical], horizontal: mapTransformToSide[horizontal]}}
    >
      <DialogContent
        sx={isLoading ? disabledStyle : null}
        style={{
          width,
          overflow: 'hidden',
          padding: '0 !important',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {isLoading && (
          <Box position="absolute" zIndex={999}>
            <Loading />
          </Box>
        )}
        <Box sx={{width: '100%'}}>{children}</Box>
      </DialogContent>
    </MuiPopover>
  );
};

export default memo(Popover);
