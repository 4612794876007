import React, {useCallback, useMemo, useState} from 'react';
import {Modal} from '@mui/material';
import {useRequestMutation} from 'services/useRequestMutation';
import {TrialsService} from 'services/TrialsService';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';
import {useOnSuccessCommunication} from 'helpers/hooks/useOnSuccessCommunication';
import DeleteLayout from 'components/base/DeleteLayout/DeleteLayout';

interface IProps {
  trialId: number;
  onSuccess(): void;
}

export const useDeleteTrialModal = ({trialId, onSuccess}: IProps) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const onOpenDeleteModal = useCallback(() => setIsOpenDeleteModal(true), [setIsOpenDeleteModal]);
  const onCloseDeleteModal = useCallback(() => setIsOpenDeleteModal(false), [setIsOpenDeleteModal]);

  const onSuccessDelete = useCallback(() => {
    onCloseDeleteModal();
    onSuccess();
  }, [onCloseDeleteModal, onSuccess]);

  const {communication, request} = useRequestMutation(TrialsService.deleteTrial);
  useOnErrorCommunication(communication);
  useOnSuccessCommunication(communication, onSuccessDelete);
  const isLoading = communication.type === 'requesting';

  const onDelete = useCallback(() => request(trialId), [request, trialId]);

  const deleteModal = useMemo(
    () => (
      <Modal
        open={isOpenDeleteModal}
        onClose={onCloseDeleteModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <DeleteLayout
          title="Do you want to delete trial?"
          onDelete={onDelete}
          onCancel={onCloseDeleteModal}
          isLoading={isLoading}
        />
      </Modal>
    ),
    [isOpenDeleteModal, onCloseDeleteModal, isLoading, onDelete]
  );

  return {
    onOpenDeleteModal,
    deleteModal,
    onDelete,
  };
};
