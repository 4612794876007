import {Stack, Typography} from '@mui/material';

import {LazyLoadImage} from 'react-lazy-load-image-component';

import styles from '../../PIGuide.module.scss';

// images
import img18 from 'images/guide/section2/D/img-18.jpg';
import img18Placeholder from 'images/guide/section2/D/img-18-placeholder.jpg';
import img19 from 'images/guide/section2/D/img-19.jpg';
import img19Placeholder from 'images/guide/section2/D/img-19-placeholder.jpg';

export const SectionD = () => (
  <section id="guide-delegation-log">
    <Stack gap={1} mb={4}>
      <Typography variant="subtitle1">D. Delegation Log - Sign Off on Study Team</Typography>
      <Typography>
        In addition to conducting study visits, you will also be responsible for approving and signing off on your study
        team, at the beginning of the study, and if any changes occur throughout. Your study team will make sure you are
        aware of any changes prior, and will support you through sign off.
      </Typography>
      <Typography>
        From the CRIO homepage, click “eReg” on the top banner to navigate to the delegation log signature page.
      </Typography>
      <LazyLoadImage
        src={img18}
        width={800}
        height={518}
        placeholderSrc={img18Placeholder}
        className={styles.img}
        effect="blur"
      />
      <Typography>Scroll to the bottom of this page to view any required signatures or actions.</Typography>
      <LazyLoadImage
        src={img19}
        width={800}
        height={447}
        placeholderSrc={img19Placeholder}
        className={styles.img}
        effect="blur"
      />
    </Stack>
  </section>
);

export default SectionD;
