import {ServerUser} from 'services/types/ServerUser';

export function getUserFullName({
  first_name,
  last_name,
  display_name,
}: Pick<ServerUser, 'first_name' | 'last_name' | 'display_name'>): string {
  if (!first_name && !last_name && !display_name) {
    return 'Unknown';
  }

  if (!first_name && !last_name && display_name) {
    return display_name;
  }

  return [first_name ?? '', last_name ?? ''].join(' ');
}
