import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {TrialKey} from 'components/TrialContent/trial/details/TrialKey';
import {PropsWithChildren} from 'react';

interface Props {
  k: string;
}

export function TrialDetailsKeyValue({k, children}: PropsWithChildren<Props>) {
  return (
    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
      <Box sx={{alignContent: 'center'}}>
        <TrialKey k={k} />
      </Box>
      <Box sx={{alignContent: 'center'}}>{children}</Box>
    </Stack>
  );
}
