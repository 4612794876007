import React, {useEffect} from 'react';
import {TrialsService} from 'services/TrialsService';
import {TrialsOverviewContent} from 'components/TrialsOverviewContent/TrialsOverviewContent';
import {useRequestData} from 'services/useRequestData';
import WithCommunication from 'components/base/WithCommunication/WithCommunication';
import {ServerTrialBase, ServerTrialBaseRequest} from 'services/types/ServerTrialBase';

const TrialsOverview = () => {
  const {data, communication, request, refetch} = useRequestData<ServerTrialBaseRequest, ServerTrialBase[]>(
    TrialsService.getTrials
  );

  useEffect(() => {
    request();
  }, [request]);

  return (
    <WithCommunication communication={communication} data={data}>
      {(loadedTrials) => <TrialsOverviewContent list={loadedTrials} reloadTrials={refetch} />}
    </WithCommunication>
  );
};

export default TrialsOverview;
