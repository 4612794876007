import {Grid, Typography} from '@mui/material';
import {DataGridColumn} from 'components/base/Table/DataGridColumn';
import {DataGridWithTypes} from 'components/base/Table/DataGridWithTypes';
import CellRendererString from 'components/base/Table/columns/CellRendererString';
import {useMemo} from 'react';
import {ServerSiteResponse} from 'services/types/ServerSites';
import styles from './SitesTable.module.scss';
import CellRendererAddress from 'components/base/Table/columns/CellRendererAddress';
import EditButton from 'components/base/Button/EditButton';
import DeleteButton from 'components/base/Button/DeleteButton';
import CellRendererUsers from 'components/base/Table/columns/CellRendererUsers';
import {RenderCellParams} from 'components/base/Table/renderCell/RenderCellParams';

interface Props {
  sites: ServerSiteResponse[];
  isAdminTable?: boolean;
  onDeleteSite?: (siteId: string) => void;
  onEditSite?: (siteId: string) => void;
  hideOwners?: boolean;
}

const SitesTable = ({sites, isAdminTable, onDeleteSite, onEditSite, hideOwners = false}: Props) => {
  const columns: DataGridColumn<ServerSiteResponse>[] = useMemo(() => {
    return [
      {
        renderHeader: () => <Typography className={styles.title}>Site name</Typography>,
        renderCell: ({row}) => <CellRendererString value={row.name} />,
        field: 'name',
        flex: 1,
      },
      {
        renderHeader: () => <Typography className={styles.title}>Address</Typography>,
        renderCell: ({row}) => (
          <CellRendererAddress
            value={{
              street1: row.street1,
              street2: row.street2,
              zip_code: row.zip_code,
              city: row.city,
              state: row.state,
            }}
          />
        ),
        field: 'address',
        flex: 1,
      },
      ...(!hideOwners
        ? [
            {
              renderHeader: () => <Typography className={styles.title}>Owners</Typography>,
              renderCell: ({row}: RenderCellParams<ServerSiteResponse>) => <CellRendererUsers users={row.owners} />,
              field: 'owners',
              flex: 1,
            },
          ]
        : []),
      ...(onEditSite
        ? [
            {
              renderHeader: () => null,
              renderCell: ({row}: RenderCellParams<ServerSiteResponse>) => (
                <Grid alignItems="center" justifyContent="center">
                  <EditButton
                    onClick={() => {
                      onEditSite(row.id);
                    }}
                  >
                    Edit
                  </EditButton>
                </Grid>
              ),
              field: 'edit',
              width: 90,
            },
          ]
        : []),
      ...(onDeleteSite
        ? [
            {
              renderHeader: () => null,
              renderCell: ({row}: RenderCellParams<ServerSiteResponse>) => (
                <DeleteButton
                  onClick={() => {
                    onDeleteSite(row.id);
                  }}
                  disabled={false}
                  icon={isAdminTable ? 'bin' : 'minus'}
                >
                  {isAdminTable ? 'Delete' : 'Remove'}
                </DeleteButton>
              ),
              field: 'delete',
              width: 120,
            },
          ]
        : []),
    ];
  }, [isAdminTable, onDeleteSite, onEditSite, hideOwners]);

  return <DataGridWithTypes rows={sites} columns={columns} />;
};

export default SitesTable;
