import Stack from '@mui/material/Stack';
import {VendorCard} from 'components/Vendors/VendorCard';
import {ServerVendor} from 'services/types/ServerVendor';

import styles from './VendorToCompareSelector.module.scss';

interface Props {
  vendors: ServerVendor[];
  onClick(vendorId: number): void;
}
export function VendorToCompareSelector({vendors, onClick}: Props) {
  return (
    <Stack direction={'column'} spacing={1} alignItems={'center'} style={{maxHeight: '100%', padding: '20px 0'}}>
      <span className={styles.header}>Select service for split mode</span>
      {vendors.length > 0
        ? vendors.map((value) => (
            <VendorCard
              selected={false}
              key={value.id}
              vendor={value}
              onClick={() => {
                onClick(value.id);
              }}
            />
          ))
        : null}
    </Stack>
  );
}
