import React, {memo} from 'react';

interface IProps {
  url: string;
  width?: string | number;
  height?: string | number;
  backgroundColor?: string;
}

const Image = (props: IProps) => {
  const {url, width = '100%', height = '100%', backgroundColor = '#FFF'} = props;

  return (
    <div style={{width, height}}>
      <div
        style={{
          width: '100%',
          height: '100%',
          maxWidth: '100%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundImage: `url(${url})`,
          backgroundColor,
        }}
      />
    </div>
  );
};

export default memo(Image);
