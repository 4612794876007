import orderBy from 'lodash/orderBy';
import {action, makeAutoObservable} from 'mobx';
import {Communication} from 'models/Communication';
import {TrialsService} from 'services/TrialsService';
import {ServerEquipment, TrialEquipmentMatrix} from 'services/types/ServerEquipment';

const INITIAL = {
  _equipment: {
    trial_equipment: [],
    sites: [],
    user_permissions: [],
  },
  availableEquipment: [] as Omit<ServerEquipment, 'in_use'>[],
  communication: {type: 'notAsked'} as Communication,
  permission: false,
};

class StoreTrialEquipment {
  _equipment: TrialEquipmentMatrix = INITIAL._equipment;
  availableEquipment: Omit<ServerEquipment, 'in_use'>[] = INITIAL.availableEquipment;

  loadEquipmentCommunication: Communication = INITIAL.communication;
  assignEquipmentCommunication: Communication = INITIAL.communication;
  loadAvailableCommunication: Communication = INITIAL.communication;
  removeEquipmentCommunication: Communication = INITIAL.communication;

  canEditEquipment = INITIAL.permission;
  canDeleteEquipment = INITIAL.permission;
  canCreateEquipment = INITIAL.permission;
  canListEquipment = INITIAL.permission;

  constructor() {
    makeAutoObservable(this);
  }

  onUnmountEquipment() {
    this._equipment = INITIAL._equipment;
  }

  onUnmountCommunications() {
    this.loadEquipmentCommunication = INITIAL.communication;
    this.assignEquipmentCommunication = INITIAL.communication;
    this.loadAvailableCommunication = INITIAL.communication;
    this.removeEquipmentCommunication = INITIAL.communication;
  }

  loadEquipment = (trialId: number) => {
    this.loadEquipmentCommunication = {type: 'requesting'};
    TrialsService.getTrialEquipment(trialId).then(
      action('loadEquipmentSuccess', (result) => {
        this.loadEquipmentCommunication = {type: 'success'};

        this.canEditEquipment = result?.user_permissions?.includes('update') ?? false;
        this.canCreateEquipment = result?.user_permissions?.includes('create') ?? false;
        this.canDeleteEquipment = result?.user_permissions?.includes('delete') ?? false;
        this.canListEquipment = result?.user_permissions?.includes('list') ?? false;

        this._equipment = result;
      }),
      action('loadEquipmentError', (error: string) => {
        this.loadEquipmentCommunication = {type: 'error', error};
      })
    );
  };

  loadAvailableEquipment = (trialId: number) => {
    this.loadAvailableCommunication = {type: 'requesting'};
    TrialsService.getAvailableEquipmentTypes(trialId).then(
      action('loadAvailableSuccess', (result) => {
        this.loadAvailableCommunication = {type: 'success'};
        this.availableEquipment = result;
      }),
      action('loadAvailableError', (error: string) => {
        this.loadAvailableCommunication = {type: 'error', error};
      })
    );
  };

  assignEquipment = (trialId: number, equipmentId: number) => {
    this.assignEquipmentCommunication = {type: 'requesting'};
    TrialsService.assignEquipmentToTrial(trialId, equipmentId).then(
      action('assignEquipmentSuccess', () => {
        this.assignEquipmentCommunication = {type: 'success'};
        this.availableEquipment = this.availableEquipment.filter((equipment) => equipment.id !== equipmentId);
        this.loadEquipmentCommunication = {type: 'refetch'};
        TrialsService.getTrialEquipment(trialId).then(
          action('loadEquipmentSuccess', (result) => {
            this.loadEquipmentCommunication = {type: 'success'};
            this._equipment = result;
          }),
          action('loadEquipmentError', (error: string) => {
            this.loadEquipmentCommunication = {type: 'error', error};
          })
        );
      }),
      action('assignEquipmentError', (error: string) => {
        this.assignEquipmentCommunication = {type: 'error', error};
      })
    );
  };

  appendNewEquipment = (equipment: ServerEquipment) => {
    this.availableEquipment = this.availableEquipment.concat(equipment);
  };

  removeEquipment = (trialEquipmentId: number, trialId: number) => {
    this.removeEquipmentCommunication = {type: 'requesting'};
    TrialsService.removeEquipmentFromTrial(trialEquipmentId).then(
      action('removeEquipmentSuccess', () => {
        this.removeEquipmentCommunication = {type: 'refetch'};

        const equipmentTypeId = this._equipment.trial_equipment.find((i) => i.id === trialEquipmentId)?.equipment_id;
        const equipmentTypeName = this._equipment.trial_equipment.find((i) => i.id === trialEquipmentId)?.name;

        if (equipmentTypeId && equipmentTypeName) {
          this.availableEquipment = orderBy(
            this.availableEquipment.concat({
              id: equipmentTypeId,
              name: equipmentTypeName,
            }),
            [(data: Omit<ServerEquipment, 'in_use'>) => data.name.toLowerCase()],
            ['asc']
          );
        }

        TrialsService.getTrialEquipment(trialId).then(
          action('loadEquipmentSuccess', (result) => {
            this.loadEquipmentCommunication = {type: 'success'};
            this.removeEquipmentCommunication = {type: 'success'};
            this._equipment = result;
          }),
          action('loadEquipmentError', (error: string) => {
            this.loadEquipmentCommunication = {type: 'error', error};
          })
        );
      }),
      action('removeEquipmentError', (error: string) => {
        this.removeEquipmentCommunication = {type: 'error', error};
      })
    );
  };

  addNoteToEquipmentType = (trialEquipmentId: number, note: string) => {
    TrialsService.addNoteToEquipmentType(trialEquipmentId, note);
  };

  addNoteToRequiredEquipment = (
    trialId: number,
    trialEquipmentId: number,
    note: string,
    siteId: number,
    siteEquipmentId?: number
  ) => {
    TrialsService.addNoteToRequiredEquipment(trialId, trialEquipmentId, note, siteId, siteEquipmentId);
  };
}

export const STORE_TRIAL_EQUIPMENT = new StoreTrialEquipment();
