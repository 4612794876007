import API from 'services/api';
import {ServerTrialBase, ServerTrialBaseRequest} from 'services/types/ServerTrialBase';
import {ServerTrial} from 'services/types/ServerTrial';
import {ServerVendor} from 'services/types/ServerVendor';
import {ServerDetails} from 'services/types/ServerDetails';
import {ServerExpenses, ServerExpensesResponse} from 'services/types/ServerExpenses';
import {ServerSharedLinks} from 'services/types/ServerSharedLinks';
import {ServerExpenseRequest} from 'services/types/ServerExpenseRequest';
import {convertServerVendorToClient} from 'services/types/converters/convertServerVendorToClient';
import {ServerAssignUserToTrial, ServerTrialUserResponse} from 'services/types/ServerTrialUser';
import {graphEndpoints} from 'authConfig';
import {
  convertClientDetailsToServer,
  convertServerDetailsToClient,
} from 'services/types/converters/convertTrialDetails';
import {ServerSiteResponse, ServerTrialSitesResponse} from './types/ServerSites';
import {AxiosResponse} from 'axios';
import {ServerEquipment, ServerTrialEquipment, TrialEquipmentMatrix} from './types/ServerEquipment';
import {
  ServerMonitoringVisit,
  ServerMonitoringVisitRequest,
  ServerMonitoringVisitsResponse,
} from './types/ServerMonitoringVisit';
import {ServerTask} from './types/ServerCheckList';

const DEFAULT_PREFIX = 'trial';

export class TrialsService {
  public static async getTrials(data?: ServerTrialBaseRequest): Promise<ServerTrialBase[]> {
    const {orderBy, orderType} = data || {};
    const result = await API.get(`${DEFAULT_PREFIX}/`, {
      ...(orderBy && {
        params: {
          order_by: orderBy,
          order_type: orderType,
        },
      }),
    });
    return result.data;
  }

  public static async createTrial(name: string): Promise<ServerTrial> {
    const result = await API.post(`${DEFAULT_PREFIX}/?trial_name=${name}`);
    return result.data;
  }

  public static async getTrial(id: number): Promise<ServerTrial> {
    const result = await API.get(`${DEFAULT_PREFIX}/${id}`);
    return result.data;
  }

  public static async getAvatar(userId: string): Promise<string | null> {
    const response = await API.get(graphEndpoints.GetAvatarUrl(userId), {responseType: 'blob'});
    return window.URL.createObjectURL(response.data);
  }

  public static putTrial(data: ServerTrial): Promise<void> {
    return API.put(`${DEFAULT_PREFIX}/${data.id}`, JSON.stringify(data));
  }

  public static async getTrialVendors(id: number): Promise<ServerVendor[]> {
    const result = await API.get(`${DEFAULT_PREFIX}/vendors/${id}`);
    return result.data.map(convertServerVendorToClient);
  }

  public static putTrialVendors(data: {trialId: number; vendors: number[]}): Promise<void> {
    return API.put(`${DEFAULT_PREFIX}/vendors/${data.trialId}`, JSON.stringify(data.vendors));
  }

  public static async getTrialDetails(id: number): Promise<ServerDetails> {
    const result = await API.get(`${DEFAULT_PREFIX}/details/${id}`);
    return convertServerDetailsToClient(result.data, id);
  }

  public static putTrialDetails(details: ServerDetails): Promise<void> {
    const convertedData = convertClientDetailsToServer(details);
    return API.put(`${DEFAULT_PREFIX}/details/${details.id}`, JSON.stringify(convertedData));
  }

  public static deleteTrial(trialId: number): Promise<void> {
    return API.delete(`${DEFAULT_PREFIX}/${trialId}`);
  }

  public static async getTrialSharedLinks(id: number): Promise<ServerSharedLinks> {
    const result = await API.get(`${DEFAULT_PREFIX}/links/${id}`);
    return result.data;
  }

  public static changeTrialSharedLinks(id: number, data: ServerSharedLinks): Promise<ServerSharedLinks> {
    return API.post(`${DEFAULT_PREFIX}/links/${id}`, JSON.stringify(data));
  }

  public static async getTrialExpenses(id: number): Promise<ServerExpensesResponse> {
    const result = await API.get(`${DEFAULT_PREFIX}/expenses/${id}`);
    return result.data;
  }

  public static async getTrialSites(id: number): Promise<ServerTrialSitesResponse> {
    const result = await API.get(`${DEFAULT_PREFIX}/sites/${id}`);
    return result.data;
  }

  public static async getAvailableTrialSites(id: number): Promise<ServerSiteResponse[]> {
    const result = await API.get(`${DEFAULT_PREFIX}/sites/${id}/available`);
    return result.data;
  }

  public static async assignSiteToTrial(id: number, siteId: string): Promise<void> {
    return API.post(`${DEFAULT_PREFIX}/sites/${id}`, {site_id: siteId});
  }

  public static async removeSiteFromTrial(id: number, siteId: string): Promise<void> {
    return API.delete(`${DEFAULT_PREFIX}/sites/${id}?site_id=${siteId}`);
  }

  public static addTrialExpenses({
    expenses,
    trialId,
  }: {
    trialId: number;
    expenses: Omit<ServerExpenseRequest, 'trialId'>;
  }): Promise<AxiosResponse<ServerExpenses>> {
    const formData = new FormData();
    const receiptKey: keyof Omit<ServerExpenseRequest, 'trialId'> = 'receipt';
    formData.append(receiptKey, expenses.receipt);

    return API.post(`${DEFAULT_PREFIX}/expenses/${trialId}`, formData, {
      headers: {'Content-Type': 'multipart/form-data'},
      params: {
        subject: expenses.subject,
        type: expenses.type,
        date: expenses.date,
      },
    });
  }

  public static deleteTrialExpenses({expenseId}: {expenseId: number}): Promise<void> {
    return API.delete(`${DEFAULT_PREFIX}/expenses/${expenseId}`);
  }

  public static markTrialExpenseAsComplete({
    expenseId,
    complete,
  }: {
    expenseId: number;
    complete: boolean;
  }): Promise<void> {
    return API.patch(`${DEFAULT_PREFIX}/expenses/${expenseId}`, {complete});
  }

  public static async getTrialUsers(trialId: number): Promise<ServerTrialUserResponse> {
    const result = await API.get<ServerTrialUserResponse>(`${DEFAULT_PREFIX}/users/${trialId}`);
    // Filtering is a leftover from the previous iteration of the project.
    // TODO: Check if filtering is really necessary (if it's possible to have nil users right now).
    return {
      data: result?.data?.data?.filter((trialUser) => !!trialUser?.user),
      user_permissions: result?.data?.user_permissions,
    };
  }

  public static assignUserToTrial(trialId: number, data: ServerAssignUserToTrial): Promise<void> {
    return API.post(`${DEFAULT_PREFIX}/users/${trialId}`, JSON.stringify([data]));
  }

  public static deleteUserFromTrial(trialId: number, userId: string): Promise<void> {
    return API.delete(`${DEFAULT_PREFIX}/users/${trialId}`, {params: {user_id: userId}});
  }

  public static async getTrialEquipment(trialId: number): Promise<TrialEquipmentMatrix> {
    const result = await API.get(`${DEFAULT_PREFIX}/equipment/${trialId}/matrix`);
    return result.data;
  }

  public static async assignEquipmentToTrial(trialId: number, equipmentId: number): Promise<ServerTrialEquipment[]> {
    const result = await API.post(
      `${DEFAULT_PREFIX}/equipment/${trialId}`,
      JSON.stringify([{equipment_id: equipmentId, note: ''}])
    );
    return result.data;
  }

  public static async removeEquipmentFromTrial(trialEquipmentId: number): Promise<void> {
    return API.delete(`${DEFAULT_PREFIX}/equipment/${trialEquipmentId}`);
  }

  public static async addNoteToEquipmentType(trialEquipmentId: number, note: string): Promise<void> {
    return API.put(`${DEFAULT_PREFIX}/equipment/${trialEquipmentId}`, {note});
  }

  public static async addNoteToRequiredEquipment(
    trialId: number,
    trialEquipmentId: number,
    note: string,
    siteId?: number,
    siteEquipmentId?: number
  ): Promise<void> {
    return API.post(`${DEFAULT_PREFIX}/equipment/${trialId}/notes`, {
      trial_equipment_id: trialEquipmentId,
      note,
      ...(siteId && {site_id: siteId}),
      ...(siteEquipmentId && {site_equipment_id: siteEquipmentId}),
    });
  }

  public static async getAvailableEquipmentTypes(id: number): Promise<ServerEquipment[]> {
    const result = await API.get(`${DEFAULT_PREFIX}/equipment/${id}/available`);
    return result.data;
  }

  public static async changeArchivedStatus(trialId: number, isArchived: boolean): Promise<void> {
    return API.patch(`${DEFAULT_PREFIX}/${trialId}`, {is_archived: isArchived});
  }

  public static async getTrialMonitoringVisits(trialId: number): Promise<ServerMonitoringVisitsResponse> {
    const result = await API.get(`${DEFAULT_PREFIX}/monitoring_visits/${trialId}`);
    return result.data;
  }

  public static async addMonitoringVisit({
    visit,
    trialId,
  }: {
    trialId: number;
    visit: ServerMonitoringVisitRequest;
  }): Promise<ServerMonitoringVisit> {
    const {name, date, length, monitors_name, confirmation_letter, follow_up_letter} = visit;

    const formData = new FormData();

    formData.append('confirmation_letter', confirmation_letter);
    formData.append('follow_up_letter', follow_up_letter);

    const result = await API.post(`${DEFAULT_PREFIX}/monitoring_visits/${trialId}`, formData, {
      headers: {'Content-Type': 'multipart/form-data'},
      params: {
        name,
        date,
        length,
        monitors_name,
      },
    });

    return result.data;
  }

  public static async deleteMonitoringVisit(visitId: number): Promise<void> {
    return API.delete(`${DEFAULT_PREFIX}/monitoring_visits/${visitId}`);
  }

  public static async getTrialTasks(trialId: number): Promise<ServerTask[]> {
    const result = await API.get(`${DEFAULT_PREFIX}/${trialId}/tasks`);
    return result.data;
  }

  public static async addTask(trialId: number, text: string): Promise<ServerTask> {
    const result = await API.post(`${DEFAULT_PREFIX}/${trialId}/tasks`, {text});
    return result.data;
  }

  public static async deleteTask(trialId: number, taskId: number): Promise<void> {
    return API.delete(`${DEFAULT_PREFIX}/${trialId}/tasks/${taskId}`);
  }

  public static async changeTaskStatus(trialId: number, taskId: number, checked: boolean): Promise<ServerTask> {
    const result = await API.patch(`${DEFAULT_PREFIX}/${trialId}/tasks/${taskId}`, {checked});
    return result.data;
  }
}
