import {memo} from 'react';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

import Button, {ButtonProps} from 'components/base/Button/Button';

type Props = ButtonProps & {isLoading?: boolean; icon?: 'bin' | 'minus'};

export const DeleteButton = ({icon = 'bin', ...props}: Props) => {
  return (
    <Button
      {...props}
      variant="outlined"
      color="error"
      startIcon={icon === 'minus' ? <RemoveOutlinedIcon /> : <DeleteIcon />}
    />
  );
};

export default memo(DeleteButton);
