import WithCommunication from 'components/base/WithCommunication/WithCommunication';
import {memo, useCallback, useEffect} from 'react';
import {ServerDetails} from 'services/types/ServerDetails';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import DailyChecklist from 'components/TrialContent/trial/details/blocks/left/DailyChecklist';
import {StudyDescription} from 'components/TrialContent/trial/details/blocks/left/StudyDescription';
import RecruitmentProgress from 'components/TrialContent/trial/details/blocks/right/RecruitmentProgress';
import {TrialTimeline} from 'components/TrialContent/trial/details/blocks/right/TrialTimeline';
import {KeyStudyContacts} from 'components/TrialContent/trial/details/blocks/right/KeyStudyContacts';
import {STORE_TRIAL} from 'store/StoreTrial';
import {observer} from 'mobx-react-lite';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';

interface Props {
  trialId: number;
}

const TrialDetails = ({trialId}: Props) => {
  const request = STORE_TRIAL.getDetails;
  const details = STORE_TRIAL._details;
  const communication = STORE_TRIAL.detailsCommunication;
  const canEditTrial = STORE_TRIAL.canEditTrial;

  useOnErrorCommunication(communication);

  const reloadTrialDetails = useCallback(() => {
    request(trialId);
    STORE_TRIAL.getChecklist(trialId);
  }, [trialId, request]);

  useEffect(() => {
    reloadTrialDetails();
  }, [reloadTrialDetails]);

  useEffect(() => {
    return () => STORE_TRIAL.onUnmountDetails();
  }, []);

  return (
    <div>
      <WithCommunication communication={communication} data={details}>
        {(data) => <Details details={data} canEdit={canEditTrial} />}
      </WithCommunication>
    </div>
  );
};

type DetailsProps = {
  details: ServerDetails;
  canEdit: boolean;
};

const V_SPACING = 2;
const Details = observer(({details, canEdit}: DetailsProps) => {
  return (
    <Stack direction="row" justifyContent="space-between" spacing={2}>
      <Box width="70%">
        <Stack direction={'column'} spacing={V_SPACING}>
          <DailyChecklist />
          <StudyDescription details={details} />
        </Stack>
      </Box>
      <Box>
        <Stack direction="column" spacing={V_SPACING}>
          <RecruitmentProgress details={details} canEdit={canEdit} />
          <TrialTimeline details={details} canEdit={canEdit} />
          <KeyStudyContacts details={details} canEdit={canEdit} />
        </Stack>
      </Box>
    </Stack>
  );
});

export default memo(observer(TrialDetails));
