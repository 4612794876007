import {ServerTrialBase} from 'services/types/ServerTrialBase';
import useClearableSelectOptions, {SelectOption} from 'components/base/Select/Select';
import {ServerTrialStatus} from 'services/types/ServerTrialStatus';
import {useMemo} from 'react';
import {mapServerTrialStatusToLabel} from 'services/types/converters/getServerTrialStatus';
import filterByLabel from 'components/TrialsOverviewContent/filters/utils/filterByLabel';

export default function useStatusesOptions(list: ServerTrialBase[]): SelectOption<ServerTrialStatus | undefined>[] {
  return useClearableSelectOptions(
    useMemo(
      () =>
        list
          .map((item) => ({
            label: mapServerTrialStatusToLabel[item.status],
            value: item.status,
          }))
          .filter(filterByLabel),
      [list]
    )
  );
}
