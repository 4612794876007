import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {ReactComponent as SplitSVG} from 'components/TrialContent/vendor/panel/images/split.svg';
import styles from './ActionsPanel.module.scss';

interface ActionsPanelProps {
  onClose(): void;
}
export function ActionsPanel({onClose}: ActionsPanelProps) {
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{
        width: '100%',
        height: '5%',
        background: '#FFFFFF',
        borderBottom: '1px solid #769E98',
        boxShadow: '0px 2px 4px rgba(118, 158, 152, 0.5)',
        borderRadius: 0,
        p: 1,
        pl: 2,
      }}
    >
      <Stack direction={'row'} alignItems={'center'} spacing={3}>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <SplitSVG />
          <div className={styles.title}>Split mode</div>
        </Stack>
      </Stack>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Stack>
  );
}
