import {action, makeAutoObservable} from 'mobx';
import {ServerTask} from 'services/types/ServerCheckList';
import {INITIAL_SERVER_DETAILS, ServerDetails} from 'services/types/ServerDetails';
import {Communication} from 'models/Communication';
import {TrialsService} from 'services/TrialsService';
import {ServerTrial} from 'services/types/ServerTrial';
import {Nullable} from 'services/types/common';
import {ServerVendor} from 'services/types/ServerVendor';
import {convertServerDetailsToClient} from 'services/types/converters/convertTrialDetails';

const INITIAL = {
  _trial: null,
  _vendors: [],
  _checklistItems: [],
  _details: INITIAL_SERVER_DETAILS,
  communication: {type: 'notAsked'} as Communication,
  canEditTrial: false,
  canDeleteTrial: false,
  canCreateTrial: false,
};

class StoreTrial {
  _trial: Nullable<ServerTrial> = INITIAL._trial;
  getTrialCommunication: Communication = INITIAL.communication;
  changeTrialCommunication: Communication = INITIAL.communication;

  _vendors: ServerVendor[] = INITIAL._vendors;
  getTrialVendorsCommunication: Communication = INITIAL.communication;
  changeTrialVendorsCommunication: Communication = INITIAL.communication;

  _checklistItems: ServerTask[] = INITIAL._checklistItems;
  checkListCommunication: Communication = INITIAL.communication;
  changeCheckListCommunication: Communication = INITIAL.communication;

  _details: ServerDetails = INITIAL._details;
  detailsCommunication: Communication = INITIAL.communication;
  changeDetailsCommunication: Communication = INITIAL.communication;

  canEditTrial = INITIAL.canEditTrial;
  canDeleteTrial = INITIAL.canDeleteTrial;
  canCreateTrial = INITIAL.canCreateTrial;

  constructor() {
    makeAutoObservable(this);
  }

  onUnmountTrialCommunications() {
    this.getTrialCommunication = INITIAL.communication;
    this.changeTrialCommunication = INITIAL.communication;
  }

  onUnmountDetails() {
    this._checklistItems = INITIAL._checklistItems;
    this._details = INITIAL._details;
    this.checkListCommunication = INITIAL.communication;
    this.detailsCommunication = INITIAL.communication;
    this.changeDetailsCommunication = INITIAL.communication;
  }

  onUnmountTrial() {
    this._trial = INITIAL._trial;
  }

  getTrial = (id: number) => {
    this.getTrialCommunication = {type: 'requesting'};
    TrialsService.getTrial(id)
      .then((result) => {
        this.getTrialCommunication = {type: 'success'};
        this._trial = result;
        this.canEditTrial = result?.user_permissions?.includes('update') || false;
        this.canCreateTrial = result?.user_permissions?.includes('create') || false;
        this.canDeleteTrial = result?.user_permissions?.includes('delete') || false;
      })
      .catch((error: string) => {
        this.getTrialCommunication = {type: 'error', error};
      });
  };

  changeTrial = (data: ServerTrial) => {
    this.changeTrialCommunication = {type: 'requesting'};
    TrialsService.putTrial(data)
      .then(() => {
        this.changeTrialCommunication = {type: 'success'};
        this._trial = data;
      })
      .catch((error: string) => {
        this.changeTrialCommunication = {type: 'error', error};
      });
  };

  getTrialVendors = (trialId: number) => {
    this.getTrialCommunication = {type: 'requesting'};
    TrialsService.getTrialVendors(trialId)
      .then((data) => {
        this.getTrialCommunication = {type: 'success'};
        this._vendors = data;
      })
      .catch((error: string) => {
        this.getTrialCommunication = {type: 'error', error};
      });
  };

  putTrialVendors = (trialId: number, vendors: ServerVendor[]) => {
    this.changeTrialVendorsCommunication = {type: 'requesting'};
    TrialsService.putTrialVendors({trialId, vendors: vendors.map((i) => i.id)})
      .then(() => {
        this.changeTrialVendorsCommunication = {type: 'success'};
        this._vendors = vendors;
      })
      .catch((error: string) => {
        this.changeTrialVendorsCommunication = {type: 'error', error};
      });
  };

  getDetails = (id: number) => {
    this.detailsCommunication = {type: 'requesting'};
    TrialsService.getTrialDetails(id)
      .then((result) => {
        this._details = result;
        this.detailsCommunication = {type: 'success'};
      })
      .catch((error: string) => {
        this.detailsCommunication = {type: 'error', error};
      });
  };

  getChecklist = (trialId: number) => {
    this.detailsCommunication = {type: 'requesting'};
    TrialsService.getTrialTasks(trialId)
      .then((result) => {
        this._checklistItems = result;
        this.detailsCommunication = {type: 'success'};
      })
      .catch((error: string) => {
        this.detailsCommunication = {type: 'error', error};
      });
  };

  checkChecklistItem(trialId: number, taskId: number, checked: boolean) {
    this.changeCheckListCommunication = {type: 'requesting'};
    TrialsService.changeTaskStatus(trialId, taskId, checked)
      .then(() => {
        this.changeCheckListCommunication = {type: 'success'};
        this._checklistItems = this._checklistItems.map((item) => (item.id === taskId ? {...item, checked} : item));
      })
      .catch((error: string) => {
        this.changeCheckListCommunication = {type: 'error', error};
      });
  }

  addChecklistItem(trialId: number, text: string, onAdd?: () => void) {
    this.changeCheckListCommunication = {type: 'requesting'};
    TrialsService.addTask(trialId, text)
      .then((task) => {
        this.changeCheckListCommunication = {type: 'success'};
        this._checklistItems = [...this._checklistItems, task];
        onAdd?.();
      })
      .catch((error: string) => {
        this.changeCheckListCommunication = {type: 'error', error};
      });
  }

  deleteChecklistItem(trialId: number, taskId: number) {
    this.changeCheckListCommunication = {type: 'requesting'};
    TrialsService.deleteTask(trialId, taskId)
      .then(() => {
        this.changeCheckListCommunication = {type: 'success'};
        this._checklistItems = this._checklistItems.filter((item) => item.id !== taskId);
      })
      .catch((error: string) => {
        this.changeCheckListCommunication = {type: 'error', error};
      });
  }

  changeDetails(details: ServerDetails) {
    this.changeDetailsCommunication = {type: 'requesting'};
    TrialsService.putTrialDetails(details)
      .then(() => {
        this.changeDetailsCommunication = {type: 'success'};
        this._details = convertServerDetailsToClient(details, this._trial?.id);
        this._trial = this._trial ? {...this._trial, sponsor: details.sponsor} : null;
      })
      .catch((error: string) => (this.changeDetailsCommunication = {type: 'error', error}));
  }

  changeArchiveStatus(trialId: number, isArchived: boolean) {
    this.changeDetailsCommunication = {type: 'requesting'};
    TrialsService.changeArchivedStatus(trialId, isArchived).then(
      action('changeArchivedStatusSuccess', () => {
        this.changeDetailsCommunication = {type: 'success'};
        this._trial = this._trial ? {...this._trial, is_archived: isArchived} : null;
      }),
      action('changeArchivedStatusError', (error: string) => {
        this.changeDetailsCommunication = {type: 'error', error};
      })
    );
  }
}

export const STORE_TRIAL = new StoreTrial();
