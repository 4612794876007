import CellRendererEmpty from './CellRendererEmpty';
import CellRendererString from 'components/base/Table/columns/CellRendererString';
import {formatDate} from 'components/base/utils/formatDate';

type Props = {
  value: string | undefined | null;
};

export default function CellRendererDate(props: Props) {
  if (!props.value) {
    return <CellRendererEmpty />;
  }

  return <CellRendererString value={formatDate(new Date(props.value), false)} />;
}
