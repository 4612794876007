import MenuItem from '@mui/material/MenuItem';
import {useState} from 'react';
import {Divider, Menu, MenuProps, Stack, Typography, styled} from '@mui/material';
import AddInfoButton from '../Button/AddInfoButton';
import {isEmpty} from 'lodash';

type DropdownWithActionProps = {
  buttonTitle: string;
  actionTitle?: string;
  onAction?: () => void;
  isLoading?: boolean;
  items?: {
    name: string;
    id: string;
  }[];
  onItemSelect?: (id: string) => void;
};

const StyledMenu = styled((props: MenuProps) => <Menu elevation={8} {...props} />)(({theme}) => ({
  '& .MuiPaper-root': {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    padding: '4px 0',
    minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
  },
}));

export const DropdownWithAction = ({
  buttonTitle,
  actionTitle,
  onAction,
  isLoading,
  items,
  onItemSelect,
}: DropdownWithActionProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AddInfoButton title={buttonTitle} onClick={handleClick} />
      <StyledMenu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {isLoading && <MenuItem disabled>Loading...</MenuItem>}
        {!isLoading && (
          <Stack>
            {items &&
              !isEmpty(items) &&
              items.map((item) => (
                <MenuItem
                  onClick={
                    onItemSelect
                      ? () => {
                          handleClose();
                          onItemSelect(item.id);
                        }
                      : undefined
                  }
                  key={item.id}
                >
                  <Typography variant="inherit" style={{whiteSpace: 'normal'}}>
                    {item.name}
                  </Typography>
                </MenuItem>
              ))}
            {actionTitle && onAction && (
              <Stack>
                {!isEmpty(items) && <Divider />}
                <MenuItem onClick={onAction}>{actionTitle}</MenuItem>
              </Stack>
            )}
          </Stack>
        )}
      </StyledMenu>
    </>
  );
};

export default DropdownWithAction;
