import WithCommunication from 'components/base/WithCommunication/WithCommunication';
import {SiteForm} from 'components/complex/SiteForm/SiteForm';
import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {SitesService} from 'services/SitesService';
import {useRequestData} from 'services/useRequestData';

const EditSite = () => {
  const params = useParams();
  const siteId = params.siteId;

  const {communication, request: getSite, data} = useRequestData(SitesService.getSite);

  useEffect(() => {
    if (siteId) {
      getSite(siteId);
    }
  }, [siteId, getSite]);

  return (
    <WithCommunication communication={communication} data={data}>
      {(loadedSite) => <SiteForm backRoute={{pathname: '/sites', title: 'Back to Sites'}} site={loadedSite} />}
    </WithCommunication>
  );
};

export default EditSite;
