import {Box} from '@mui/material';
import TrialContentLayout from 'components/TrialContent/TrialContentLayout';
import {SiteForm} from 'components/complex/SiteForm/SiteForm';
import {memo} from 'react';
import {ServerTrial} from 'services/types/ServerTrial';
import {ServerVendor} from 'services/types/ServerVendor';

interface Props {
  trial: ServerTrial;
  vendors: ServerVendor[];
}

const AddSiteInTrial = ({trial, vendors}: Props) => {
  return (
    <TrialContentLayout vendors={vendors} trial={trial}>
      <Box px={6} py={5}>
        <SiteForm backRoute={{pathname: `/trials/${trial.id}/sites`, title: 'Back to trial'}} />
      </Box>
    </TrialContentLayout>
  );
};

export default memo(AddSiteInTrial);
