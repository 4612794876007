import React, {memo} from 'react';
import {Box} from '@mui/material';
import Image from 'components/base/Image';
import {Nullable} from 'services/types/common';

interface IProps {
  url: Nullable<string>;
}

const LogoWrapper = ({url}: IProps) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="182px"
    height="60px"
    bgcolor="#FFFFFF"
    border="1px solid #E0E6F2"
    borderRadius="6px"
  >
    <Image width="50%" height="50%" url={url || ''} />
  </Box>
);

export default memo(LogoWrapper);