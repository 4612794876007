import React, {useCallback, useMemo, useState} from 'react';
import {Modal} from '@mui/material';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';
import {useOnSuccessCommunication} from 'helpers/hooks/useOnSuccessCommunication';
import DeleteLayout from 'components/base/DeleteLayout/DeleteLayout';
import {STORE_VENDOR} from 'store/StoreVendor';

interface IProps {
  onSuccess(): void;
}

export const useDeleteVendorModal = ({onSuccess}: IProps) => {
  const [currentId, setCurrentId] = useState<number>();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const onOpenDeleteModal = useCallback((vendorId: number) => {
    setCurrentId(vendorId);
    setIsOpenDeleteModal(true);
  }, [setIsOpenDeleteModal]);
  const onCloseDeleteModal = useCallback(() => setIsOpenDeleteModal(false), [setIsOpenDeleteModal]);

  const onSuccessDelete = useCallback(() => {
    onCloseDeleteModal();
    onSuccess();
  }, [onCloseDeleteModal, onSuccess]);

  const {deleteVendor: request, deleteVendorCommunication: communication} = STORE_VENDOR;
  useOnErrorCommunication(communication);
  useOnSuccessCommunication(communication, onSuccessDelete);
  const isLoading = communication.type === 'requesting';

  const onDelete = useCallback(() => currentId && request(currentId), [request, currentId]);

  const deleteModal = useMemo(
    () => (
      <Modal
        open={isOpenDeleteModal}
        onClose={onCloseDeleteModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <DeleteLayout
          title="Do you want to delete vendor?"
          onDelete={onDelete}
          onCancel={onCloseDeleteModal}
          isLoading={isLoading}
        />
      </Modal>
    ),
    [isOpenDeleteModal, onCloseDeleteModal, isLoading, onDelete]
  );

  return {
    onOpenDeleteModal,
    deleteModal,
    onDelete,
  };
};
