import Stack from '@mui/material/Stack';
import {ServerExpensesType} from 'services/types/ServerExpensesType';
import {Formik, FormikHelpers} from 'formik';
import TextInputField from 'components/base/Input/TextInputField';
import SubmitButton from 'components/base/Button/SubmitButton';
import SelectField from 'components/base/Select/SelectField';
import {SelectOption} from 'components/base/Select/Select';
import validateNotEmpty from 'validators/validateNotEmpty';
import {equals} from 'ramda';
import DatePickerField from 'components/base/DatePicker/DatePickerField';
import validateNotEmptyDate from 'validators/validateNotEmptyDate';
import {ServerExpenseRequest} from 'services/types/ServerExpenseRequest';
import DropZoneField from 'components/base/DropZone/DropZoneField';
import validateNotEmptyFile from 'validators/validateNotEmptyFile';
import {mapExpenseTypeToLabel} from 'services/types/converters/getServerExpensesTypeLabel';

type Expense = Omit<ServerExpenseRequest, 'trialId'>;

interface Props {
  onSend(value: Expense): void;
  isLoading: boolean;
}

const TYPE_OPTIONS: SelectOption<ServerExpensesType>[] = [
  {
    value: ServerExpensesType.hotel,
    label: mapExpenseTypeToLabel[ServerExpensesType.hotel],
  },
  {
    value: ServerExpensesType.travel,
    label: mapExpenseTypeToLabel[ServerExpensesType.travel],
  },
  {
    value: ServerExpensesType.gas,
    label: mapExpenseTypeToLabel[ServerExpensesType.gas],
  },
  {
    value: ServerExpensesType.mileage,
    label: mapExpenseTypeToLabel[ServerExpensesType.mileage],
  },
  {
    value: ServerExpensesType.food,
    label: mapExpenseTypeToLabel[ServerExpensesType.food],
  },
  {
    value: ServerExpensesType.medical_bill,
    label: mapExpenseTypeToLabel[ServerExpensesType.medical_bill],
  },
  {
    value: ServerExpensesType.other,
    label: mapExpenseTypeToLabel[ServerExpensesType.other],
  },
  {
    value: ServerExpensesType.patient_stipend,
    label: mapExpenseTypeToLabel[ServerExpensesType.patient_stipend],
  }
];

type FormValues = Omit<ServerExpenseRequest, 'trialId' | 'date' | 'receipt'> & {
  date: Date;
  receipt: File | undefined;
};
const initialValues: FormValues = {
  type: ServerExpensesType.hotel,
  receipt: undefined,
  subject: '',
  date: new Date(),
};
export function TrialExpensesAddForm({onSend, isLoading}: Props) {
  const handleSend = ({type, subject, date, receipt}: FormValues, {resetForm}: FormikHelpers<FormValues>) => {
    if (receipt) {
      onSend({
        type,
        subject,
        receipt,
        date: date.toISOString(),
      });
      resetForm();
    }
  };
  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSend}>
        {({handleSubmit, isValid, values}) => (
          <form onSubmit={handleSubmit}>
            <Stack direction={'column'}>
              <TextInputField name={'subject'} placeholder={'Enter subject ID'} validate={validateNotEmpty} />
              <DatePickerField name={'date'} placeholder={'Date of Service'} validate={validateNotEmptyDate} />
              <SelectField name={'type'} placeholder={'Select expense type'} options={TYPE_OPTIONS} />
              <DropZoneField
                name={'receipt'}
                title="Upload receipt here"
                hint="REMEMBER TO REDACT PHI BEFORE RECEIPT UPLOAD"
                validate={validateNotEmptyFile}
              />
              <Stack direction="row" justifyContent="end">
                <SubmitButton
                  isLoading={isLoading}
                  disabled={!isValid || equals(values, initialValues)}
                  fullWidth={false}
                >
                  Submit
                </SubmitButton>
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>
    </div>
  );
}
