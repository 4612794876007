import {ServerTrialStatus} from 'services/types/ServerTrialStatus';
import {mapServerTrialStatusToColor, mapServerTrialStatusToLabel} from 'services/types/converters/getServerTrialStatus';
import {Box} from '@mui/material';
import {hexToRgba} from 'helpers/hexToRgba';
import React, {useMemo} from 'react';

const defaultStyle = {
  padding: '4px 10px',
  borderRadius: 43,
  width: 'fit-content',
  textAlign: 'center',
  fontSize: '12px',
};

interface Props {
  status: ServerTrialStatus | undefined;
  isDisplayMode: boolean;
  onClick?(event: React.MouseEvent<HTMLDivElement>): void;
}

export const TrialStatus = (props: Props) => {
  const {status, isDisplayMode, onClick} = props;

  const chipStatus = status || ServerTrialStatus.UNKNOWN;
  const color = mapServerTrialStatusToColor[chipStatus];

  const optionalStyle = useMemo(() => {
    if (!isDisplayMode) {
      return {
        '&:hover': {backgroundColor: hexToRgba(color, 0.1), color},
        '&:active': {backgroundColor: color, color: '#FFF'},
        cursor: 'pointer',
      };
    }

    return null;
  }, [isDisplayMode, color]);

  return (
    <Box sx={{...defaultStyle, border: `1px solid ${color}`, color, ...optionalStyle}} onClick={onClick}>
      {mapServerTrialStatusToLabel[chipStatus]}
    </Box>
  );
};
