import {Stack, Typography} from '@mui/material';

import {LazyLoadImage} from 'react-lazy-load-image-component';

import styles from '../../PIGuide.module.scss';

// images
import img7 from 'images/guide/section2/B/img-7.jpg';
import img7Placeholder from 'images/guide/section2/B/img-7-placeholder.jpg';
import img8 from 'images/guide/section2/B/img-8.jpg';
import img8Placeholder from 'images/guide/section2/B/img-8-placeholder.jpg';
import img9 from 'images/guide/section2/B/img-9.jpg';
import img9Placeholder from 'images/guide/section2/B/img-9-placeholder.jpg';
import img13 from 'images/guide/section2/B/img-13.jpg';
import img13Placeholder from 'images/guide/section2/B/img-13-placeholder.jpg';
import img10 from 'images/guide/section2/B/img-10.jpg';
import img10Placeholder from 'images/guide/section2/B/img-10-placeholder.jpg';
import img11 from 'images/guide/section2/B/img-11.jpg';
import img11Placeholder from 'images/guide/section2/B/img-11-placeholder.jpg';
import img12 from 'images/guide/section2/B/img-12.jpg';
import img12Placeholder from 'images/guide/section2/B/img-12-placeholder.jpg';

export const SectionB = () => (
  <section id="guide-study-visits">
    <Stack gap={1} mb={4}>
      <Typography variant="subtitle1">
        B. Conducting Study Visits - Investigator Attestation, Progress Notes, Adverse Events, Sign Off
      </Typography>
      <Typography>
        During and/or after a study visit has been completed, you will be required to review and sign off on study visit
        events. Follow the steps below to navigate through CRIO to complete these required activities. For specific
        details on conducting screening visits, see section 2C.
      </Typography>
      <Typography>
        Navigate to the “Studies” tab (highlighted in screenshot above), where you’ll see a set of tiles that represent
        Macro’s currently active clinical trials, indicated with titles, indications, and a logo for the sponsor. Click
        the study that pertains to your visit.
      </Typography>
      <LazyLoadImage
        src={img7}
        width={800}
        height={517}
        placeholderSrc={img7Placeholder}
        className={styles.img}
        effect="blur"
      />
      <Typography>
        Once you click into the study, you’ll see another set of tabs below the study name. Click “subjects” to see a
        process map of study recruitment/enrollment steps, with cards for the subjects in each step underneath (note:
        the screenshot below is representative, and only shows subjects in the “Completed” column. In your actual study,
        you’ll see patients within each column).
      </Typography>
      <Typography>
        For initial, screening visits - find your subject’s name & info on a card in the “In Screening” column.
      </Typography>
      <Typography>
        For ongoing & follow-up study visits - find your subject’s name & info on a card in the “Enrolled” column.
      </Typography>
      <LazyLoadImage
        src={img9}
        width={800}
        height={517}
        placeholderSrc={img9Placeholder}
        className={styles.img}
        effect="blur"
      />
      <Typography>
        From the subject page, scroll down to the “Visit Schedule” section, and identify the visit in question. Often
        this will be noted with “Visit in Progress” in the second column. Click the “Visit In Progress” button to enter
        the visit instance{' '}
        <Typography component="span" sx={{fontStyle: 'italic'}}>
          (note: this will open in a new tab)
        </Typography>
        .
      </Typography>
      <LazyLoadImage
        src={img8}
        width={800}
        height={358}
        placeholderSrc={img8Placeholder}
        className={styles.img}
        effect="blur"
      />
      <Typography>
        If the visit has happened already, the header of CRIO will show a purple banner that says “Review and Sign Off
        on Visit.”
      </Typography>
      <LazyLoadImage
        src={img13}
        width={800}
        height={94}
        placeholderSrc={img13Placeholder}
        className={styles.img}
        effect="blur"
      />
      <Typography>
        If the visit is still in progress, you won’t see this banner, but you can still edit information in the visit
        itself.
      </Typography>
      <Typography>
        From the visit page, move your cursor to the left-hand, blue-shaded panel and scroll to the very bottom of the
        page. Click “Investigator Attestation” to pop-up this panel, and then click into each line item to check the box
        and populate your attestation/signature.
      </Typography>
      <LazyLoadImage
        src={img10}
        width={800}
        height={453}
        placeholderSrc={img10Placeholder}
        className={styles.img}
        effect="blur"
      />
      <Typography>
        From the bottom of the page, you can also access: Progress Notes & Investigator Sign Off, which are the other
        activities for which you’ll be responsible.
      </Typography>
      <Typography>
        In Progress Notes - you can add your own notes, and review notes from other members of the study team.
      </Typography>
      <Typography>
        In Investigator Sign Off - you’ll be providing your overall sign off on the study visit, thus marking it as
        complete.
      </Typography>
      <LazyLoadImage
        src={img11}
        width={800}
        height={453}
        placeholderSrc={img11Placeholder}
        className={styles.img}
        effect="blur"
      />
      <Typography>
        If an adverse event (AE) occurred during the visit (which you will observe and be notified about when your study
        team logs it into CRIO), you will be required to review & grade the event. From the visit page, scroll to the
        middle of the left-hand, blue-shaded panel and find the “Adverse Events” and “Serious Adverse Event” items.
        Clicking into these will pop-up the panels shown below, which enable you to view & grade the event.
      </Typography>
      <LazyLoadImage
        src={img12}
        width={800}
        height={453}
        placeholderSrc={img12Placeholder}
        className={styles.img}
        effect="blur"
      />
    </Stack>
  </section>
);

export default SectionB;
