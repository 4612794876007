import {Communication} from 'models/Communication';
import {makeAutoObservable} from 'mobx';
import {TrialsService} from 'services/TrialsService';
import {ServerSiteResponse} from 'services/types/ServerSites';

const INITIAL = {
  _sites: [],
  _availableSites: [],
  communication: {type: 'notAsked'} as Communication,
  permission: false,
};

class StoreTrialSites {
  _sites: ServerSiteResponse[] = INITIAL._sites;
  _availableSites: ServerSiteResponse[] = INITIAL._availableSites;

  sitesCommunication: Communication = INITIAL.communication;
  changeSitesCommunication: Communication = INITIAL.communication;
  availableSitesCommunication: Communication = INITIAL.communication;

  canListSites = INITIAL.permission;
  canCreateSite = INITIAL.permission;
  canUpdateSite = INITIAL.permission;
  canDeleteSite = INITIAL.permission;

  constructor() {
    makeAutoObservable(this);
  }

  onUnmountCommunications() {
    this.sitesCommunication = INITIAL.communication;
    this.changeSitesCommunication = INITIAL.communication;
    this.availableSitesCommunication = INITIAL.communication;
  }

  onUnmountSites() {
    this._sites = INITIAL._sites;
    this._availableSites = INITIAL._availableSites;
    this.canListSites = INITIAL.permission;
    this.canCreateSite = INITIAL.permission;
    this.canUpdateSite = INITIAL.permission;
    this.canDeleteSite = INITIAL.permission;
  }

  getTrialSites(id: number, refetch?: boolean) {
    this.sitesCommunication = {type: refetch ? 'refetch' : 'requesting'};
    TrialsService.getTrialSites(id)
      .then((result) => {
        this.sitesCommunication = {type: 'success'};

        this.canCreateSite = result.user_permissions?.includes('create') || false;
        this.canListSites = result.user_permissions?.includes('list') || false;
        this.canUpdateSite = result.user_permissions?.includes('update') || false;
        this.canDeleteSite = result.user_permissions?.includes('delete') || false;

        this._sites = this.canListSites ? result.data : [];
      })
      .catch((error: string) => {
        this.sitesCommunication = {type: 'error', error};
      });
  }

  assignSiteToTrial(trialId: number, siteId: string) {
    this.changeSitesCommunication = {type: 'requesting'};
    TrialsService.assignSiteToTrial(trialId, siteId)
      .then(() => {
        this._availableSites = this._availableSites.filter((i) => i.id !== siteId);
        TrialsService.getTrialSites(trialId)
          .then((result) => {
            this._sites = result.data;
          })
          .catch((error: string) => {
            this.sitesCommunication = {type: 'error', error};
          });
        this.changeSitesCommunication = {type: 'success'};
      })
      .catch((error: string) => {
        this.changeSitesCommunication = {type: 'error', error};
      });
  }

  removeSiteFromTrial(trialId: number, siteId: string) {
    this.changeSitesCommunication = {type: 'requesting'};
    TrialsService.removeSiteFromTrial(trialId, siteId)
      .then(() => {
        const site = this._sites.find((i) => i.id === siteId);
        this._sites = this._sites.filter((i) => i.id !== siteId);
        if (site) {
          this._availableSites.push(site);
        }
        this.changeSitesCommunication = {type: 'success'};
      })
      .catch((error: string) => {
        this.changeSitesCommunication = {type: 'error', error};
      });
  }

  getAvailableSites(trialId: number) {
    this.availableSitesCommunication = {type: 'requesting'};
    TrialsService.getAvailableTrialSites(trialId)
      .then((result) => {
        this.availableSitesCommunication = {type: 'success'};
        this._availableSites = result;
      })
      .catch((error: string) => {
        this.availableSitesCommunication = {type: 'error', error};
      });
  }
}

export const STORE_TRIAL_SITES = new StoreTrialSites();
