import {loginRequest} from 'authConfig';
import axios, {AxiosError} from 'axios';
import isArray from 'lodash/isArray';

import {msalInstance} from './AzureService';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'cache-control': 'no-cache',
    'Content-Type': 'application/json',
  },
});

API.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<{detail: string}>) => {
    if (error.response?.data?.detail) {
      if (typeof error.response?.data?.detail === 'string') throw error.response?.data?.detail;
      else if (
        isArray(error.response?.data?.detail) &&
        error.response?.data?.detail[0] &&
        'msg' in error.response.data.detail[0]
      ) {
        throw error.response?.data?.detail[0]['msg'];
      }
    }
    if (typeof error.response?.data === 'string') throw error.response?.data;
    throw error.message;
  }
);

API.interceptors.request.use(async (request) => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });

  const bearer = `Bearer ${response.accessToken}`;

  const token = response.accessToken;
  if (token) {
    request.headers = {
      ...request.headers,
      ['Authorization']: bearer,
      ['azure-id-token']: response.idToken,
    };
  }

  return request;
});

export default API;
