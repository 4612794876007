import {useParams} from 'react-router-dom';
import React, {useEffect, useMemo} from 'react';
import TrialPageContent from 'components/TrialContent/TrialPageContent';
import {STORE_TRIAL} from 'store/StoreTrial';
import {observer} from 'mobx-react-lite';
import Loading from 'components/base/WithCommunication/Loading';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';

const TrialPage = observer(() => {
  const {trialId} = useParams();

  const {_trial, _vendors, getTrial, getTrialVendors, getTrialCommunication, getTrialVendorsCommunication} =
    STORE_TRIAL;

  useEffect(() => {
    getTrial(Number(trialId));
    getTrialVendors(Number(trialId));
  }, [trialId, getTrial, getTrialVendors]);

  useOnErrorCommunication(getTrialCommunication);
  useOnErrorCommunication(getTrialVendorsCommunication);

  useEffect(() => {
    return () => {
      STORE_TRIAL.onUnmountTrialCommunications();
      STORE_TRIAL.onUnmountDetails();
      STORE_TRIAL.onUnmountTrial();
    };
  }, []);

  const isLoading = [getTrialCommunication, getTrialVendorsCommunication].some((i) => i.type === 'requesting');

  const data = useMemo(() => {
    return _trial && _vendors
      ? {
          vendors: _vendors,
          trial: _trial,
        }
      : undefined;
  }, [_trial, _vendors]);

  if (!data) return null;

  if (isLoading) {
    return <Loading />;
  }

  return <TrialPageContent vendors={data.vendors} trial={data.trial} />;
});

export default TrialPage;
