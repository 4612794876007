import {ServerVendor} from 'services/types/ServerVendor';
import _ from 'lodash';

export function convertServerVendorToClient(vendor: ServerVendor): ServerVendor {
  //remove last slashes if they exist
  const baseUrl = process.env.REACT_APP_API_URL?.replace(/\/+$/, '');

  if(!vendor.logo || vendor.logo.length < 1) {
    return {
      ...vendor,
      logo: null
    };
  }

  //remove first slash if it exists
  const logoUrl = vendor.logo?.replace(/(^)\//, '');
  return {
    ...vendor,
    // - why do we need a uniqueId?
    // - the server can change the image, but the link may not change
    // each time the image is loaded, this way the image cache is updated
    logo: `${baseUrl}/${logoUrl}#${_.uniqueId()}`,
  };
}
