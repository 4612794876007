import React, {memo, useMemo} from 'react';
import styles from 'components/base/Button/FormButton/FormButton.module.scss';
import Loading from 'components/base/WithCommunication/Loading';
import MUIButton from '@mui/material/Button';

interface IProps {
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?(): void;
  type?: 'submit' | 'button';
}

const FormButton = (props: React.PropsWithChildren<IProps>) => {
  const {isDisabled, isLoading, onClick, type = 'button', children} = props;

  const style = useMemo(() => (isDisabled ? styles.disabled : styles.root), [isDisabled]);
  const child = useMemo(() => (isLoading ? <Loading size={16} /> : children), [isLoading, children]);

  return (
    <MUIButton className={style} type={type} disableRipple={true} onClick={onClick}>
      {child}
    </MUIButton>
  );
};

export default memo(FormButton);
export type FormButtonProps = IProps;
