import {useState, useEffect} from 'react';
import MuiTooltip, {TooltipProps} from '@mui/material/Tooltip';
import {Typography, TypographyProps} from '@mui/material';

type Props = {
  typographyProps?: {
    variant?: TypographyProps['variant'];
    style?: TypographyProps['style'];
  };
} & TooltipProps;

const OverflowTooltip = ({typographyProps, ...props}: Props) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const [textElementRef, setTextElementRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (textElementRef) {
      setIsOverflow(textElementRef.scrollWidth > textElementRef.clientWidth);
    }
  }, [textElementRef]);

  return (
    <MuiTooltip {...props} disableHoverListener={!isOverflowed}>
      <Typography
        ref={(ref: HTMLDivElement) => setTextElementRef(ref)}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...typographyProps?.style,
        }}
        component="div"
        variant={typographyProps?.variant ?? 'body2'}
      >
        {props.children}
      </Typography>
    </MuiTooltip>
  );
};

export default OverflowTooltip;
