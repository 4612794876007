import {useCallback, useState} from 'react';

export function useDialog<T>() {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<T>();

  const onClose = useCallback(() => setIsOpen(false), []);
  const onOpen = useCallback((data?: T) => {
    setData(data);
    setIsOpen(true);
  }, []);

  return {
    isOpen,
    onOpen,
    onClose,
    data,
  };
}
