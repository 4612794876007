import {useEffect, useMemo} from 'react';
import {FieldArray} from 'formik';
import Popover from 'components/base/Popover/Popover';
import EquipmentAddRequiredForm from '../EquipmentAddRequiredForm/EquipmentAddRequiredForm';
import {usePopover} from 'components/base/Popover/usePopover';
import {EquipmentSiteTable, TableSiteEquipment} from './EquipmentSiteTable';
import {ServerSiteEquipment} from 'services/types/ServerEquipment';
import AddInfoButton from 'components/base/Button/AddInfoButton';
import {useRequestData} from 'services/useRequestData';
import {EquipmentService} from 'services/EquipmentService';

type Props = {
  equipment: ServerSiteEquipment[];
};

export const EquipmentSiteForm = ({equipment}: Props) => {
  const {popoverProps, onOpen, onClose} = usePopover();

  const {request: getAvailableEquipment, data: availableEquipment} = useRequestData(EquipmentService.getEquipment);

  useEffect(() => {
    getAvailableEquipment();
  }, [getAvailableEquipment]);

  const mappedEquipment: TableSiteEquipment[] = useMemo(
    () => equipment.map((item, index) => ({equipmentId: item.id, name: item.name, note: item.note, id: index})),
    [equipment]
  );

  return (
    <>
      <FieldArray
        name="equipment"
        render={(arrayHelpers) => (
          <>
            <Popover {...popoverProps} horizontal="right">
              <EquipmentAddRequiredForm
                onAddNewEquipment={(equipmentName) => arrayHelpers.push({name: equipmentName, note: ''})}
                availableEquipment={availableEquipment || []}
                onAssignEquipment={(_equipmentId, equipmentName) => {
                  if (equipmentName) {
                    arrayHelpers.push({name: equipmentName, note: ''});
                  }
                }}
                onClose={onClose}
              />
            </Popover>
            <EquipmentSiteTable
              equipment={mappedEquipment}
              onDeleteSite={(index) => arrayHelpers.remove(index)}
              availableEquipment={availableEquipment || []}
              onEquipmentTypeSelect={(equipment, index) => {
                arrayHelpers.replace(index, {
                  name: equipment.name,
                  note: equipment.note,
                  ...('id' in equipment && {id: equipment.id}),
                });
              }}
            />
          </>
        )}
      />
      <AddInfoButton title="+ Add site equipment" onClick={onOpen} />
    </>
  );
};

export default EquipmentSiteForm;
