import React, {memo} from 'react';
import TypographyWrapper from 'components/base/Typography/TypographyWrapper';
import Stack from '@mui/material/Stack';
import styles from './Title.module.scss';

interface IProps {
  title: string;
  style?: React.CSSProperties;
  mainTitle?: boolean;
}

const Title = ({title, style, mainTitle}: IProps) => {
  return (
    <Stack style={{marginBottom: 26, ...style}} direction="row" justifyContent="end" className={mainTitle ? styles['main-title'] : ''}>
      <TypographyWrapper value={title} variant={'h6'} />
    </Stack>
  );
};

export default memo(Title);
