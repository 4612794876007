import React, {useCallback, useMemo, useState} from 'react';
import {Modal} from '@mui/material';
import {useRequestMutation} from 'services/useRequestMutation';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';
import {useOnSuccessCommunication} from 'helpers/hooks/useOnSuccessCommunication';
import DeleteLayout from 'components/base/DeleteLayout/DeleteLayout';
import {EquipmentService} from 'services/EquipmentService';

type Props = {
  onSuccess(): void;
};

export const useDeleteEquipmentModal = ({onSuccess}: Props) => {
  const [equipmentId, setEquipmentId] = useState<number>();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const onOpenDeleteModal = useCallback(
    (equipmentId: number) => {
      setEquipmentId(equipmentId);
      setIsOpenDeleteModal(true);
    },
    [setIsOpenDeleteModal, setEquipmentId]
  );

  const onCloseDeleteModal = useCallback(() => setIsOpenDeleteModal(false), [setIsOpenDeleteModal]);

  const onSuccessDelete = useCallback(() => {
    onCloseDeleteModal();
    onSuccess();
  }, [onCloseDeleteModal, onSuccess]);

  const {communication, request} = useRequestMutation(EquipmentService.deleteEquipmentType);
  useOnErrorCommunication(communication);
  useOnSuccessCommunication(communication, onSuccessDelete);
  const isLoading = communication.type === 'requesting';

  const onDelete = useCallback(() => equipmentId && request(equipmentId), [equipmentId, request]);

  const deleteModal = useMemo(
    () => (
      <Modal
        open={isOpenDeleteModal}
        onClose={onCloseDeleteModal}
        aria-label="Delete equipment type"
        aria-description="Confirm equipment type deletion"
      >
        <DeleteLayout title="Do you want to delete equipment type?" onDelete={onDelete} onCancel={onCloseDeleteModal} />
      </Modal>
    ),
    [isOpenDeleteModal, onCloseDeleteModal, onDelete]
  );

  return {
    onOpenDeleteModal,
    deleteModal,
    onDelete,
    isLoading,
  };
};
