import TextField from '@mui/material/TextField';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {memo} from 'react';
import {StaticDatePicker} from '@mui/x-date-pickers';

interface CalendarStaticDatePickerProps {
  value: Date | null;
  onChange(value: Date | null): void;
}

const CalendarStaticDatePicker = (props: CalendarStaticDatePickerProps) => {
  const {value, onChange} = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        openTo="day"
        value={value}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

export default memo(CalendarStaticDatePicker);
