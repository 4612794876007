import {FieldConfig, Field, FieldProps} from 'formik';
import TextInput, {TextInputProps} from 'components/base/Input/TextInput';

type Props = FieldConfig & Omit<TextInputProps, 'value' | 'onChange' | 'onBlur' | 'name' | 'meta'>;

export default function TextInputField(props: Props) {
  return (
    <Field {...props}>
      {({field, meta, form}: FieldProps<string>) => (
        <TextInput
          {...props}
          value={field.value}
          onChange={(v) => form.setFieldValue(field.name, v)}
          onBlur={field.onBlur}
          meta={meta}
        />
      )}
    </Field>
  );
}
