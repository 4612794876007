import {ServerContact} from 'services/types/ServerContact';

export enum JobTitle {
  MedicalMonitor = 'Medical monitor',
  CRAContact = 'CRA Contact',
  SAEContact = 'SAE Contact',
  EmergencyUnbundlingContact = 'Emergency Unbundling Contact',
  CRIOContact = 'CRIO Contact',
  SlopeContact = 'Slope Contact',
  TravelContact = 'Travel Contact',
  RecruitmentContact = 'Recruitment Contact',
  LabShipmentContact = 'LAB Shipment Contact',
  SponsorContact = 'Sponsor Contact',
  CROContact = 'CRO Contact',
}

type ServerTypeKeyValue<Value> = {
  [key in JobTitle]: Value;
};
export type ServerContacts = ServerTypeKeyValue<ServerContact>;
