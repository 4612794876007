import Tooltip from 'components/base/Tooltip/Tooltip';
import MUIInfoIcon from '@mui/icons-material/Info';

interface Props {
  info: string;
}
export function InfoIcon({info}: Props) {
  return (
    <Tooltip title={info}>
      <MUIInfoIcon />
    </Tooltip>
  );
}
