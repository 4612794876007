// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import {ButtonProps as MUIButtonProps} from '@mui/material/Button';
import {useMemo} from 'react';

const defaultButtonStyle = {
  height: 37,
  padding: '6px 12px',
  width: 'max-content',
};

export type ButtonCommonProps = {
  children: string | JSX.Element;
  fullWidth?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  name?: string;
  backgroundColor?: string;
  height?: string | number;
} & Pick<MUIButtonProps, 'variant' | 'endIcon' | 'startIcon' | 'color' | 'sx'>;

export function useButtonCommonProps(props: ButtonCommonProps & {isLoading?: boolean}) {
  const buttonStyle = useMemo(() => {
    return {
      ...defaultButtonStyle,
      height: props.height || defaultButtonStyle.height,
      backgroundColor: props.backgroundColor,
    };
  }, [props.backgroundColor, props.height]);

  const contentProps = useMemo(
    () => ({
      ...props,
      variant: props.variant ?? 'contained',
      disabled: props.disabled || props.isLoading,
      size: props.size ?? 'medium',
      style: props.fullWidth ? {...buttonStyle, maxWidth: '100%', width: '100%'} : buttonStyle,
    }),
    [props, buttonStyle]
  );

  const buttonProps = useMemo(
    () => ({
      color: contentProps.color ? contentProps.color : 'secondary',
      variant: contentProps.variant,
      disabled: contentProps.disabled,
      size: contentProps.size,
      style: contentProps.style,
      endIcon: contentProps.endIcon,
      startIcon: contentProps.startIcon,
      sx: contentProps.sx,
    }),
    [
      contentProps.color,
      contentProps.disabled,
      contentProps.size,
      contentProps.style,
      contentProps.variant,
      contentProps.startIcon,
      contentProps.endIcon,
      contentProps.sx,
    ]
  );

  return {
    contentProps,
    buttonProps,
  };
}
