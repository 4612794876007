import React, {memo, useCallback, useState} from 'react';
import {ChangeSimpleFieldForm} from 'components/base/Form/ChangeSimpleFieldForm';
import {TrialsService} from 'services/TrialsService';
import EditVendorsForm from 'components/Vendors/EditVendorsForm/EditVendorsForm';
import {useOnSuccessCommunication} from 'helpers/hooks/useOnSuccessCommunication';
import {ServerVendor} from 'services/types/ServerVendor';
import {STORE_TRIAL} from 'store/StoreTrial';
import {useRequestData} from 'services/useRequestData';
import {observer} from 'mobx-react-lite';
import validateNotEmpty from 'validators/validateNotEmpty';

interface IProps {
  onSuccess(): void;
  onClose?(): void;
}

const CreateNewTrialForm = (props: IProps) => {
  const {onSuccess, onClose} = props;

  const [isFirstStep, setIsFirstStep] = useState(true);
  const toSecondStep = useCallback(() => setIsFirstStep(false), []);
  const toFirstStep = useCallback(() => setIsFirstStep(true), []);

  const [values, setValues] = useState<{name: string; vendors: ServerVendor[]}>({name: '', vendors: []});

  const {communication: createCommunication, request: createTrial, data} = useRequestData(TrialsService.createTrial);
  const {putTrialVendors, changeTrialVendorsCommunication} = STORE_TRIAL;

  const onSubmit = useCallback(() => createTrial(values.name), [createTrial, values]);

  useOnSuccessCommunication(createCommunication, () => data?.id && putTrialVendors(data?.id, values.vendors));
  useOnSuccessCommunication(changeTrialVendorsCommunication, () => onSuccess && onSuccess());

  const onSubmitTrialName = useCallback(
    (data: {name: string}) => {
      setValues({...values, name: data.name});
      toSecondStep();
    },
    [values, toSecondStep]
  );

  const onCancelVendors = useCallback(
    (vendors: ServerVendor[]) => {
      setValues({...values, vendors});
      toFirstStep();
    },
    [values, toFirstStep]
  );

  const onSubmitVendors = useCallback(
    (vendors: ServerVendor[]) => {
      setValues({...values, vendors});
      onSubmit();
    },
    [values, onSubmit]
  );

  if (isFirstStep) {
    return (
      <ChangeSimpleFieldForm
        title="Add new trial"
        name="name"
        data={values}
        communication={createCommunication}
        onSubmit={onSubmitTrialName}
        onClose={onClose}
        submitTitle="Continue"
        validate={validateNotEmpty}
        type="create"
      />
    );
  }

  return (
    <EditVendorsForm
      type="create"
      currentVendors={values.vendors}
      onClose={onCancelVendors}
      onSubmit={onSubmitVendors}
    />
  );
};

export default memo(observer(CreateNewTrialForm));
