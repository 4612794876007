import Stack from '@mui/material/Stack';
import {ButtonCommonProps} from 'components/base/Button/shared/useButtonCommonProps';
import TypographyWrapper from 'components/base/Typography/TypographyWrapper';

type Props = Omit<ButtonCommonProps, 'variant'>;

export function ButtonContent(props: Props) {
  const {children} = props;

  return (
    <Stack direction={'row'} alignItems={'center'} style={{width: '100%'}}>
      <TypographyWrapper value={children} />
    </Stack>
  );
}
