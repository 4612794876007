import Stack from '@mui/material/Stack';
import {Formik, FormikHelpers} from 'formik';
import TextInputField from 'components/base/Input/TextInputField';
import SubmitButton from 'components/base/Button/SubmitButton';
import validateNotEmpty from 'validators/validateNotEmpty';
import {equals} from 'ramda';
import DropZoneField from 'components/base/DropZone/DropZoneField';
import validateNotEmptyFile from 'validators/validateNotEmptyFile';
import {ServerMonitoringVisitRequest} from 'services/types/ServerMonitoringVisit';
import {Box} from '@mui/material';
import isNil from 'lodash/isNil';

interface Props {
  onSend(value: ServerMonitoringVisitRequest): void;
  isLoading: boolean;
}

type FormValues = Partial<ServerMonitoringVisitRequest>;

const initialValues: FormValues = {
  name: '',
  date: '',
  length: undefined,
  monitors_name: '',
  confirmation_letter: undefined,
  follow_up_letter: undefined,
};

export const MonitoringVisitsForm = ({onSend, isLoading}: Props) => {
  const handleSend = (
    {name, date, length, monitors_name, confirmation_letter, follow_up_letter}: FormValues,
    {resetForm}: FormikHelpers<FormValues>
  ) => {
    if (name && !isNil(length) && date && monitors_name && confirmation_letter && follow_up_letter) {
      onSend({
        name,
        date,
        length,
        monitors_name,
        confirmation_letter,
        follow_up_letter,
      });
      resetForm();
    }
  };
  return (
    <Box width={400}>
      <Formik initialValues={initialValues} onSubmit={handleSend}>
        {({handleSubmit, isValid, values}) => (
          <form onSubmit={handleSubmit}>
            <Stack direction="column">
              <TextInputField name="name" placeholder="Name of Monitoring Visit" validate={validateNotEmpty} />
              <TextInputField
                name="date"
                placeholder="Date of Monitoring Visit"
                validate={validateNotEmpty}
                helperText="The format of date is unstructured. It can be a single date or a range of dates."
              />
              <TextInputField
                name="length"
                type="number"
                placeholder="Length of Time (hours)"
                validate={validateNotEmpty}
              />
              <TextInputField name="monitors_name" placeholder="Monitor's Name" validate={validateNotEmpty} />
              <Stack direction="column" pt={2} gap={2}>
                <DropZoneField name="confirmation_letter" title="Confirmation Letter" validate={validateNotEmptyFile} />
                <DropZoneField name="follow_up_letter" title="Follow Up letter" validate={validateNotEmptyFile} />
              </Stack>
              <Stack direction="row" justifyContent="end">
                <SubmitButton
                  isLoading={isLoading}
                  disabled={!isValid || equals(values, initialValues)}
                  fullWidth={false}
                >
                  Submit
                </SubmitButton>
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default MonitoringVisitsForm;
