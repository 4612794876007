import {Grid, Stack, Typography} from '@mui/material';
import PostalCodeInputField from 'components/base/Input/PostalCodeInputField';
import StateInputField from 'components/base/Input/StateInputField';
import TextInputField from 'components/base/Input/TextInputField';
import validateMinLength from 'validators/validateMinLength';

import validateNotEmpty from 'validators/validateNotEmpty';

export const SiteInformationForm = () => {
  return (
    <>
      <Typography variant="subtitle1">Site information</Typography>
      <Stack maxWidth={450} sx={{pt: 2}}>
        <TextInputField name="name" placeholder="Name" validate={validateNotEmpty} />
        <TextInputField name="street1" placeholder="Address 1" validate={validateNotEmpty} />
        <TextInputField name="street2" placeholder="Address 2 (optional)" />
        <Grid container width="100%" gap={1} direction="row">
          <Grid item xs>
            <TextInputField name="city" placeholder="City" validate={validateNotEmpty} />
          </Grid>
          <Grid item xs={3}>
            <StateInputField name="state" placeholder="State" validate={(value) => validateMinLength(value, 2)} />
          </Grid>
          <Grid item xs>
            <PostalCodeInputField name="zip_code" placeholder="Zip code" validate={validateNotEmpty} />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default SiteInformationForm;
