import React from 'react';
import ReactDOM from 'react-dom/client';

import {BrowserRouter} from 'react-router-dom';

import './index.scss';
import App from './App';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {MainMuiTheme} from 'theme/MainMuiTheme';
import {SnackbarProvider} from 'notistack';

import {msalInstance} from 'services/AzureService';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={MainMuiTheme}>
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <BrowserRouter>
            <App pca={msalInstance} />
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);
