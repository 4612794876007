import {List, ListItem, Stack, Typography} from '@mui/material';

import 'react-lazy-load-image-component/src/effects/blur.css';

import {TableOfContentsLink} from './components/TableOfContentsLink';
import StyledRouterLink from './components/StyledRouterLink';
import {useScrollToIDHash} from 'helpers/hooks/useScrollToIDHash';
import Section1 from './sections/Section1';
import Section2 from './sections/Section2';

import styles from './PIGuide.module.scss';

export const PIGuide = () => {
  useScrollToIDHash();
  return (
    <Stack gap={2}>
      <Typography variant="h4">PI Guide</Typography>
      <Typography variant="h5">Quick start guide to working with Macro Trials.</Typography>
      <Typography>
        In your role as Principal Investigator (PI) with Macro Trials, you will be required to oversee study visits in
        CRIO, our clinical trial management system (CTMS). The Macro Platform (this web app) is a one-stop-shop from
        where you can access CRIO, trial information, study team information, and tag your team in requests.
      </Typography>
      <section>
        <Typography variant="subtitle1">Table of Contents</Typography>
        <List>
          <ListItem className={styles.nestedList}>
            <StyledRouterLink hash="#guide-section-1">
              <Typography component="span" sx={{fontWeight: 600}}>
                1. Navigating the Macro Platform
              </Typography>
            </StyledRouterLink>
            <List>
              <TableOfContentsLink hash="#guide-trial-overview">a. Trial Overview - Your Studies</TableOfContentsLink>
              <TableOfContentsLink hash="#guide-trial-dashboard">b. Trial Dashboard</TableOfContentsLink>
              <TableOfContentsLink hash="#guide-iframe-crio">
                c. I-Frame - Launching CRIO & Other Portals
              </TableOfContentsLink>
              <TableOfContentsLink hash="#guide-task-management">d. Task Management - Tag Your CRC</TableOfContentsLink>
            </List>
          </ListItem>
          <ListItem className={styles.nestedList}>
            <StyledRouterLink hash="#guide-section-2">
              <Typography component="span" sx={{fontWeight: 600}}>
                2. Using CRIO for Study Activities
              </Typography>
            </StyledRouterLink>
            <List>
              <TableOfContentsLink hash="#guide-crio-login">a. Logging In & Overall Orientation</TableOfContentsLink>
              <TableOfContentsLink hash="#guide-study-visits">
                b. Conducting Study Visits - Investigator Attestation, Progress Notes, Adverse Events, Sign Off
              </TableOfContentsLink>
              <TableOfContentsLink hash="#guide-screening-visits">
                c. Screening Visits - Inclusion/Exclusion (I/E) Criteria, Medical History & Eligibility
              </TableOfContentsLink>
              <TableOfContentsLink hash="#guide-delegation-log">
                d. Delegation Log - Sign Off on Study Team
              </TableOfContentsLink>
            </List>
          </ListItem>
          <ListItem sx={{fontWeight: 600}}>
            3. Macro Onboarding Guide & Agreements{' '}
            <Typography component="span" sx={{fontStyle: 'italic', fontWeight: 600, ml: '2px'}}>
              (coming soon)
            </Typography>
          </ListItem>
          <ListItem sx={{fontWeight: 600}}>
            4. Your Site & Equipment{' '}
            <Typography component="span" sx={{fontStyle: 'italic', fontWeight: 600, ml: '2px'}}>
              (coming soon)
            </Typography>
          </ListItem>
        </List>
      </section>
      <Section1 />
      <Section2 />
    </Stack>
  );
};

export default PIGuide;
