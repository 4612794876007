import React, {memo, useCallback, useEffect} from 'react';
import Stack from '@mui/material/Stack';
import {TrialTitle} from 'components/TrialContent/trial/details/TrialTitle';
import {useRequestData} from 'services/useRequestData';
import {TrialsService} from 'services/TrialsService';
import {useRequestMutation} from 'services/useRequestMutation';
import WithCommunication from 'components/base/WithCommunication/WithCommunication';
import AddButton from 'components/base/Button/AddInfoButton';
import {usePopover} from 'components/base/Popover/usePopover';
import Popover from 'components/base/Popover/Popover';
import AddUserForm from './AddUserForm';
import {observer} from 'mobx-react-lite';
import {useOnSuccessCommunication} from 'helpers/hooks/useOnSuccessCommunication';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';
import UserTable from 'components/complex/UserTable/UserTable';

interface IProps {
  trialId: number;
}

const TrialUsers = ({trialId}: IProps) => {
  const {popoverProps, onOpen, onClose} = usePopover();

  const {
    data,
    communication: getCommunication,
    request: requestGet,
    refetch: refetchGet,
  } = useRequestData(TrialsService.getTrialUsers);

  const canEdit = data?.user_permissions?.includes('update');

  const {communication: deleteCommunication, request: requestDelete} = useRequestMutation<
    {
      trialId: number;
      userId: string;
    },
    void
  >(({trialId, userId}) => TrialsService.deleteUserFromTrial(trialId, userId));

  const onLoad = useCallback(() => requestGet(trialId), [trialId, requestGet]);
  const refetchUsers = useCallback(() => refetchGet(trialId), [trialId, refetchGet]);

  useOnErrorCommunication(getCommunication);
  useOnErrorCommunication(deleteCommunication);
  useOnSuccessCommunication(deleteCommunication, refetchUsers);

  useEffect(() => onLoad(), [onLoad]);

  const onSuccessHandler = useCallback(() => {
    onClose();
    refetchUsers();
  }, [onClose, refetchUsers]);

  const onDeleteHandler = useCallback((userId: string) => requestDelete({trialId, userId}), [trialId, requestDelete]);

  return (
    <>
      <WithCommunication communication={getCommunication} data={data?.data}>
        {(trialUsers) => (
          <>
            <Popover {...popoverProps} width={500} horizontal="right">
              <AddUserForm trialId={trialId} onClose={onClose} onSuccess={onSuccessHandler} />
            </Popover>
            <Stack direction="column" spacing={2}>
              <TrialTitle title="Users who have access to the trial" />
              <UserTable
                users={trialUsers}
                deleteUser={onDeleteHandler}
                deleteDisabled={deleteCommunication.type === 'requesting'}
                readonly={!canEdit}
              />
              {canEdit && <AddButton title="+ Add user" onClick={onOpen} />}
            </Stack>
          </>
        )}
      </WithCommunication>
    </>
  );
};

export default memo(observer(TrialUsers));
