import MuiDialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import CloseIcon from '@mui/icons-material/Close';
import Title from 'components/base/Title/Title';
import Button from '../Button/Button';

type DialogProps = {
  title?: string;
  isOpen: boolean;
  onClose?: () => void;
  children?: JSX.Element;
  onConfirm?: () => void;
  cancel?: boolean;
  withSpaceUnderTitle?: boolean;
  width?: number;
};

export default function Dialog(props: DialogProps) {
  const {title, children, isOpen, onClose, onConfirm, cancel, withSpaceUnderTitle, width} = props;

  return (
    <MuiDialog open={isOpen} sx={{'& .MuiDialog-paper': {width, minWidth: width}}}>
      {title && (
        <MuiDialogTitle>
          <Title title={title} style={{marginBottom: withSpaceUnderTitle ? 0 : -20}} />
        </MuiDialogTitle>
      )}
      {onClose && (
        <IconButton style={{position: 'absolute', right: 24, top: 20}} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent>
        {children}
        {(onConfirm || cancel) && (
          <Box gap={1} display="flex" justifyContent="flex-end" mt={3}>
            {cancel && onClose && (
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            )}
            {onConfirm && (
              <Button
                onClick={() => {
                  onConfirm();
                  onClose?.();
                }}
              >
                Confirm
              </Button>
            )}
          </Box>
        )}
      </DialogContent>
    </MuiDialog>
  );
}
