import {Stack, Typography} from '@mui/material';
import {LazyLoadImage} from 'react-lazy-load-image-component';

import styles from '../PIGuide.module.scss';

// images
import img1 from 'images/guide/section1/img-1.jpg';
import img1Placeholder from 'images/guide/section1/img-1-placeholder.jpg';
import img2 from 'images/guide/section1/img-2.jpg';
import img2Placeholder from 'images/guide/section1/img-2-placeholder.jpg';
import img3 from 'images/guide/section1/img-3.jpg';
import img3Placeholder from 'images/guide/section1/img-3-placeholder.jpg';
import img4 from 'images/guide/section1/img-4.jpg';
import img4Placeholder from 'images/guide/section1/img-4-placeholder.jpg';

export const Section1 = () => (
  <section id="guide-section-1">
    <Typography variant="h6" mb={2}>
      Section 1. Navigating the Macro Platform
    </Typography>
    <section id="guide-trial-overview">
      <Stack gap={1} mb={4}>
        <Typography variant="subtitle1">A. Trial Overview - Your Studies</Typography>
        <Typography>
          When you log into the Macro Platform, you will see the “Trial Overview” page, which will show you the trial(s)
          you are leading.
        </Typography>
        <LazyLoadImage
          src={img1}
          width={800}
          height={397}
          placeholderSrc={img1Placeholder}
          className={styles.img}
          effect="blur"
        />
      </Stack>
    </section>
    <section id="guide-trial-dashboard">
      <Stack gap={1} mb={4}>
        <Typography variant="subtitle1">B. Trial Dashboard</Typography>
        <Typography>
          When you click into a trial from the “Trial Overview” page, you land in the “Trial Dashboard,” which is a
          quick reference point for all trial details.
        </Typography>
        <Typography>
          The “I-Frame” on the left-hand side has all the vendor portals your study team manages, and includes a link to
          CRIO (our CTMS).
        </Typography>
        <Typography>
          The middle section has a task management tool where you can assign tasks to your study team by “@” tagging
          them, as well as an overview of the study.
        </Typography>
        <Typography>
          The right-hand side has key figures, including recruitment progress, trial timelines & milestones, and key
          study contacts you may need.
        </Typography>
        <LazyLoadImage
          src={img2}
          width={800}
          height={392}
          placeholderSrc={img2Placeholder}
          className={styles.img}
          effect="blur"
        />
      </Stack>
    </section>
    <section id="guide-iframe-crio">
      <Stack gap={1} mb={4}>
        <Typography variant="subtitle1">C. I-Frame - Launching CRIO & Other Portals</Typography>
        <Typography>
          The I-Frame is where you can launch CRIO and other relevant vendor portals from. By clicking the CRIO logo,
          you will launch a new tab to log directly into CRIO for other study activities. CRIO will be your most-used
          portal from the Macro Platform.
        </Typography>
        <LazyLoadImage
          src={img3}
          width={290}
          height={400}
          placeholderSrc={img3Placeholder}
          className={styles.img}
          effect="blur"
        />
      </Stack>
    </section>
    <section id="guide-task-management">
      <Stack gap={1} mb={4}>
        <Typography variant="subtitle1">D. Task Management - Tag Your CRC</Typography>
        <Typography>
          From the “Trial Dashboard” you can “@” tag your clinical research coordinator (CRC) or other members of the
          study team to remind them of specific tasks. CRCs will be reminded via email of these activities.
        </Typography>
        <LazyLoadImage
          src={img4}
          width={600}
          height={260}
          placeholderSrc={img4Placeholder}
          className={styles.img}
          effect="blur"
        />
      </Stack>
    </section>
  </section>
);

export default Section1;
