import {Stack} from '@mui/material';
import CellRendererEmpty from './CellRendererEmpty';
import TypographyWrapper from 'components/base/Typography/TypographyWrapper';
import {ServerSites} from 'services/types/ServerSites';

type CellRendererAddressProps = {
  value: Pick<ServerSites, 'street1' | 'street2' | 'zip_code' | 'city' | 'state'>;
};

export default function CellRendererAddress(props: CellRendererAddressProps) {
  if (!props.value) {
    return <CellRendererEmpty />;
  }

  const {street1, street2, zip_code, city, state} = props.value;

  return (
    <Stack>
      <TypographyWrapper breakAll value={street1} />
      {street2 && <TypographyWrapper breakAll value={street2} />}
      <TypographyWrapper breakAll value={`${city}, ${state} ${zip_code}`} />
    </Stack>
  );
}
