import React, {memo, useEffect} from 'react';
import {useRequestData} from 'services/useRequestData';
import {TrialsService} from 'services/TrialsService';
import {ServerUser} from 'services/types/ServerUser';

interface IProps {
  user: Pick<ServerUser, 'id'>;
  size?: number;
}

const AvatarImage = (props: IProps) => {
  const {user: {id}, size = 24} = props;

  const {request, data} = useRequestData(TrialsService.getAvatar, undefined, true);

  useEffect(() => request(id), [request, id]);

  return (
    <div style={{height: size}}>
      <div key={id} style={{
        width: size,
        height: '100%',
        backgroundImage: `url(${data || ''})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        borderRadius: '50%'
      }} />
    </div>
  );
};

export default memo(AvatarImage);