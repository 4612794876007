import Stack from '@mui/material/Stack';
import {VendorCard} from 'components/Vendors/VendorCard';
import {ServerVendor} from 'services/types/ServerVendor';

interface Group {
  type: ServerVendor['type'];
  vendors: ServerVendor[];
}

interface Props {
  group: Group;
  deselectAll: boolean;
  selectedId: number | undefined;
  handleVendorClick(id: number): void;
}

export function VendorCellsGroup({group, deselectAll, handleVendorClick, selectedId}: Props) {
  if (group.vendors.length === 0) return null;

  return (
    <Stack direction={'row'} spacing={2} alignItems={'center'}>
      <Stack direction={'column'} spacing={0}>
        {group.vendors.map((vendor) => (
          <VendorCard
            selected={!deselectAll && selectedId === vendor.id}
            key={vendor.id}
            vendor={vendor}
            onClick={handleVendorClick}
          />
        ))}
      </Stack>
    </Stack>
  );
}
