import {msalInstance} from 'services/AzureService';

const AdminRole = 'MacroPortal.Admin';

interface IRole {
  roles: string[];
  isAdmin: boolean;
}

export default function useAzureRole(): IRole {
  const account = msalInstance.getActiveAccount();
  if (account) {
    const result: IRole = {
      roles: [],
      isAdmin: false,
    };

    if (account.idTokenClaims?.roles) {
      result.roles = account.idTokenClaims?.roles;
      result.isAdmin = account.idTokenClaims?.roles.includes(AdminRole);
    }
    return result;
  }
  return {roles: [], isAdmin: false};
}
