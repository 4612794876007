import {Communication} from 'models/Communication';
import {usePrevious} from 'helpers/hooks/usePrevious';

export function useOnSuccessCommunication(communication: Communication, onSuccess: () => void) {
  const prevCommunication = usePrevious<Communication>(communication);

  if (
    prevCommunication?.type === 'requesting' &&
    communication.type !== 'requesting' &&
    communication.type === 'success'
  ) {
    setTimeout(() => onSuccess(), 0); // setTimeout is needed because useEffect executes after render
  }
}
