import React, {memo} from 'react';
import FormButton, {FormButtonProps} from 'components/base/Button/FormButton/FormButton';
import CloseIcon from '@mui/icons-material/Close';

const CancelIconButton = (props: FormButtonProps) => (
  <FormButton {...props}>
    <CloseIcon style={{width: 16, height: 16}} />
  </FormButton>
);

export default memo(CancelIconButton);
