import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

interface Props {
  disabled: boolean;
  onClick(): void;
}
export function DeleteIconButton({disabled, onClick}: Props) {
  //  todo add confirmation dialog
  return (
    <IconButton onClick={onClick} disabled={disabled}>
      <DeleteIcon />
    </IconButton>
  );
}
