import {ServerVendor} from 'services/types/ServerVendor';
import {ServerVendorType} from 'services/types/ServerVendorType';
import {Nullable} from 'services/types/common';

export interface IFormValues extends Omit<ServerVendor, 'logo'> {
  logo: File | string | null;
}

export interface IRequestVendor extends Omit<IFormValues, 'logo'> {
  logo: Nullable<File>
}

export const initialValues: IFormValues = {
  id: 0,
  name: '',
  logo: null,
  url: '',
  type: ServerVendorType.unknown,
};

export const convertToServer = (data: IFormValues): IRequestVendor => {
  return {
    ...data,
    logo: typeof data.logo !== 'string' ? data.logo : null
  };
};