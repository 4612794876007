import {useEffect, useMemo, useState} from 'react';
import {EMPTY_OPTION, SORT_OPTIONS} from './sortOptions';
import {ServerTrialBase, ServerTrialBaseRequest} from 'services/types/ServerTrialBase';

export const useSortBy = (reloadTrials: (...p: ServerTrialBaseRequest[]) => void) => {
  const [sortBySelectedOption, setSortBySelectedOption] = useState<{value: string | undefined; label: string}>(
    EMPTY_OPTION
  );

  const values = useMemo(() => sortBySelectedOption?.value?.split('_'), [sortBySelectedOption]);
  const orderBy = values?.[0] as keyof ServerTrialBase;
  const orderType = values?.[1] as 'asc' | 'desc';

  useEffect(() => {
    reloadTrials({orderBy, orderType});
  }, [orderBy, orderType, reloadTrials, sortBySelectedOption]);

  return {
    orderBy,
    orderType,
    sortBySelectedOption,
    setSortBySelectedOption,
    sortOptions: SORT_OPTIONS,
  };
};
