import EditIcon from '@mui/icons-material/Edit';
import {SxProps} from '@mui/material';
import IconButton from '@mui/material/IconButton';

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps;
  disabled?: boolean;
}
export function EditIconButton({onClick, sx, disabled}: Props) {
  return (
    <IconButton
      sx={{height: '100%', alignSelf: 'center', ...sx}}
      onClick={onClick}
      size="small"
      color="secondary"
      disabled={disabled}
    >
      <EditIcon fontSize="inherit" />
    </IconButton>
  );
}
