import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MUISelect, {SelectChangeEvent} from '@mui/material/Select';
import {useMemo} from 'react';
import TypographyWrapper from 'components/base/Typography/TypographyWrapper';
import FormHelperText from 'components/base/FormHelperText/FormHelperText';
import {makeStyles} from '@material-ui/styles';
import {OutlinedInput, SxProps} from '@mui/material';

export const NA_SELECT_OPTION = {label: 'N/a', value: undefined};

export default function useClearableSelectOptions<T = string>(
  options: SelectOption<T>[]
): SelectOption<T | undefined>[] {
  return useMemo(() => [NA_SELECT_OPTION, ...options], [options]);
}

export interface SelectOption<T = string> {
  label: string;
  value: T;
}

export interface SelectProps<T = string> {
  placeholder: string;
  options: SelectOption<T>[];
  value: SelectOption<T> | undefined;
  errorMessage?: string;
  onChange(value: SelectOption<T>): void;
  onClose?(event: React.SyntheticEvent<Element, Event>): void;
  disabled?: boolean;
  hasBorder?: boolean;
  sx?: SxProps;
}

const useStyles = makeStyles(() => ({
  anotherBorder: {
    border: '2px solid #DFE1E6',
  },
  noBorder: {
    border: 'none',
  },
}));

export function Select<T = string>(props: SelectProps<T>) {
  const classes = useStyles();
  const {options, errorMessage, disabled, placeholder, value, onChange, hasBorder, sx} = props;

  const children = useMemo(() => options.map((option) => getSelectItem(option)), [options]);

  const handleChange = (event: SelectChangeEvent<SelectOption<T>>) => {
    onChange(event.target.value as SelectOption<T>);
  };

  const selectValue: SelectOption<T> | undefined = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    () => (value?.value !== undefined ? value : ('' as any)),
    [value]
  );

  const inputId = `select-placeholder-${placeholder}`;
  const isEmpty = options.length === 0;
  const isNAOnly = options.length === 1 && options[0].label === NA_SELECT_OPTION.label;
  return (
    <FormControl
      sx={{
        m: 1,
        minWidth: 160,
        borderRadius: 3,
        margin: 0,
        ...sx,
      }}
      size={'small'}
      error={Boolean(errorMessage)}
    >
      <InputLabel id={inputId}>{placeholder}</InputLabel>
      <MUISelect
        labelId={inputId}
        id={`select-${placeholder}`}
        value={selectValue}
        onChange={handleChange}
        onClose={props.onClose}
        autoWidth
        label={placeholder}
        disabled={isEmpty || isNAOnly || disabled}
        sx={{
          bgcolor: hasBorder ? '#FAFBFC' : 'rgba(9, 30, 66, 0.04)',
        }}
        input={
          <OutlinedInput
            label={placeholder}
            classes={{notchedOutline: hasBorder ? classes.anotherBorder : classes.noBorder}}
          />
        }
      >
        {children}
      </MUISelect>
      <FormHelperText errorMessage={errorMessage} />
    </FormControl>
  );
}

function getSelectItem<T = string>(option: SelectOption<T>) {
  return (
    <MenuItem
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value={option as any}
      key={option.label}
      sx={{maxWidth: 350}}
    >
      <TypographyWrapper value={option.label} />
    </MenuItem>
  );
}
