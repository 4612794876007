import {useCallback, useMemo, useState} from 'react';
import {Communication} from 'models/Communication';
import {useSnackbar} from 'notistack';

export function useRequestMutation<RequestParams, T>(
  req: (...params: RequestParams[]) => Promise<T>,
  onSuccess?: (result?: T) => void
) {
  const [communication, setCommunication] = useState<Communication>({
    type: 'notAsked',
  });
  const {enqueueSnackbar} = useSnackbar();

  const request = useCallback(
    async (...p: RequestParams[]) => {
      setCommunication({type: 'requesting'});
      try {
        const result = await req(...p);
        setCommunication({type: 'success'});
        onSuccess?.(result);
        return result;
      } catch (error: unknown) {
        if (typeof error === 'string') {
          setCommunication({type: 'error', error});
          enqueueSnackbar(error, {variant: 'error'});
        }
      }
    },
    [enqueueSnackbar, onSuccess, req]
  );

  return useMemo(() => ({request, communication}), [communication, request]);
}
