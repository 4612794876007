import React, {HTMLAttributes, memo, useCallback} from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  FormControl,
  InputProps,
  Paper,
  TextField,
  TextFieldProps,
  createFilterOptions,
} from '@mui/material';
import {SelectOption} from 'components/base/Select/Select';
import FormHelperText from 'components/base/FormHelperText/FormHelperText';
import {makeStyles} from '@material-ui/styles';
import isEmpty from 'lodash/isEmpty';

export type CustomSelectOption = SelectOption<string> & {custom?: boolean};

interface IProps {
  options: SelectOption<string>[];
  inputPlaceholder?: string;
  inputLabel?: string;
  onChange?(option: CustomSelectOption | null): void;
  errorMessage?: string;
  hideErrorMessage?: boolean;
  inputProps?: InputProps;
  textFieldProps?: TextFieldProps;
}

const useStyles = makeStyles(() => ({
  anotherBorder: {
    border: '2px solid #DFE1E6',
  },
}));

const CustomPaper = (props: HTMLAttributes<HTMLElement>) => <Paper style={{fontWeight: 400}} {...props} />;

export type CreatableProps = Omit<
  AutocompleteProps<SelectOption<string>, false, false, true>,
  'renderInput' | 'onChange'
> &
  IProps;

function Creatable(props: CreatableProps) {
  const classes = useStyles();
  const filter = createFilterOptions({
    stringify: (option: CustomSelectOption) => option.label,
  });

  const {
    errorMessage,
    inputPlaceholder = 'Start typing to search...',
    onChange,
    hideErrorMessage,
    inputProps,
    textFieldProps,
    ...rest
  } = props;

  const onChangeHandler = useCallback(
    (_event: React.SyntheticEvent, option: string | CustomSelectOption | null) => {
      if (onChange) {
        if (typeof option === 'string') {
          const selectedOption = props.options.find((o) => o.label === option);
          if (selectedOption) {
            onChange(selectedOption);
            return;
          } else {
            onChange({value: option, label: option, custom: true});
            return;
          }
        }
        onChange(option);
      }
    },
    [onChange, props.options]
  );

  return (
    <FormControl
      sx={{
        m: 1,
        minWidth: 160,
        background: 'rgba(9, 30, 66, 0.04)',
        borderRadius: 3,
        margin: 0,
      }}
      size={'small'}
      fullWidth
      error={Boolean(errorMessage)}
    >
      <Autocomplete
        {...rest}
        freeSolo
        onChange={onChangeHandler}
        PaperComponent={CustomPaper}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const inputValueExists = options.some((option) => option.label === params.inputValue);

          // Suggest the creation of a new value
          if (params.inputValue !== '' && !inputValueExists) {
            filtered.push({
              value: params.inputValue,
              label: `Add "${params.inputValue}"`,
              custom: true,
            });
          }

          return filtered;
        }}
        getOptionLabel={(option: CustomSelectOption) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add option created dynamically
          if (option?.custom) {
            return option.value;
          }
          // Regular option
          return option.label;
        }}
        renderOption={(props, option) => <li {...props}>{option.label}</li>}
        autoSelect
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldProps}
            placeholder={inputPlaceholder}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              classes: {notchedOutline: classes.anotherBorder},
              style: {background: '#FAFBFC'},
              ...inputProps,
            }}
          />
        )}
        forcePopupIcon={!isEmpty(props.options)}
      />
      {!hideErrorMessage && <FormHelperText errorMessage={errorMessage} />}
    </FormControl>
  );
}

export default memo(Creatable);
