import {ServerUser} from 'services/types/ServerUser';

export const renderLabelName = (user: ServerUser) => {
  const {last_name, first_name, email, display_name} = user;
  let labelName = '';

  if (first_name || last_name) {
    labelName += `${first_name} ${last_name}`;
  } else {
    labelName += display_name;
  }

  if (labelName.length !== 0) {
    if (email) labelName += ` (${email})`;
  } else labelName += email;

  return labelName;
};
