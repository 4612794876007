import Stack from '@mui/material/Stack';
import styles from './StudyDescriptionTable.module.scss';
import {ServerConditionOrDisease} from 'services/types/ServerConditionOrDisease';
import {ServerInterventionTreatment} from 'services/types/ServerInterventionTreatment';
import ChangeTrialDetailArrayForm from 'components/TrialContent/trial/details/blocks/ChangeTrialDetailArrayForm';
import {ServerDetails} from 'services/types/ServerDetails';
import {EditIconButton} from 'components/base/Button/EditIconButton';
import {useCallback, useState} from 'react';
import {usePopover} from 'components/base/Popover/usePopover';
import Popover from 'components/base/Popover/Popover';
import AddInfoButton from 'components/base/Button/AddInfoButton';
import {STORE_TRIAL} from 'store/StoreTrial';

interface Props {
  left: ServerConditionOrDisease;
  right: ServerInterventionTreatment;
}

export function StudyDescriptionTable({left, right}: Props) {
  return (
    <table style={{border: '1px solid #E0E6F2', borderCollapse: 'collapse'}}>
      <thead>
        <tr className={styles.headerBlock}>
          <HeaderBlock title="Condition or Disease" />
          <HeaderBlock title="Intervention / treatment" />
        </tr>
      </thead>
      <tbody>
        <tr className={styles.contentBlock}>
          <ContentBlock items={left} name="condition_of_desease" />
          <ContentBlock items={right} name="intervention_treatment" />
        </tr>
      </tbody>
    </table>
  );
}

interface HeaderProps {
  title: string;
}

function HeaderBlock({title}: HeaderProps) {
  return (
    <th className={styles.headerPart}>
      <span className={styles.headerTitle}>{title}</span>
    </th>
  );
}

interface ContentProps {
  items: string[];
  name: keyof ServerDetails;
}

function ContentBlock({items, name}: ContentProps) {
  const [index, setIndex] = useState<number>();
  const {onOpen, onClose, popoverProps} = usePopover();
  const canEdit = STORE_TRIAL.canEditTrial;

  const onOpenHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, i?: number) => {
      setIndex(i);
      onOpen(event);
    },
    [setIndex, onOpen]
  );

  return (
    <>
      <Popover {...popoverProps} width={500}>
        <ChangeTrialDetailArrayForm onClose={onClose} name={name} index={index} onSuccess={onClose} />
      </Popover>

      <td className={styles.contentPart}>
        <Stack
          direction={'column'}
          sx={{
            maxHeight: 200,
            overflow: 'auto',
          }}
        >
          {items.map((item, i) => (
            <Stack direction="row" key={i}>
              <div className={styles.contentTextItem}>{item}</div>
              {canEdit && <EditIconButton onClick={(event) => onOpenHandler(event, i)} />}
            </Stack>
          ))}
        </Stack>
        {canEdit && <AddInfoButton onClick={(event) => onOpenHandler(event)} />}
      </td>
    </>
  );
}
