import {OutlinedInput} from '@mui/material';
import {useGridApiContext} from '@mui/x-data-grid/hooks/utils/useGridApiContext';
import {GridRenderEditCellParams} from '@mui/x-data-grid/models/params/gridCellParams';
import {PropertyPath, get, set, isNil} from 'lodash';
import {useTheme} from '@mui/material';

type Props = {
  valueKey?: PropertyPath;
  indexArrayChanged?: number;
} & GridRenderEditCellParams;

export const CustomEditComponent = ({id, value, field, valueKey, indexArrayChanged, hasFocus}: Props) => {
  const apiRef = useGridApiContext();
  const theme = useTheme();

  const inputValue = valueKey ? get(value, valueKey, value) : value;

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = apiRef.current.getCellValue(id, field);
    const targetValue = event.target.value;

    const newValue = valueKey ? set(value, valueKey, targetValue) : targetValue;

    const newValueWithIndex = {...newValue, index: indexArrayChanged};

    apiRef.current.setEditCellValue({id, field, value: !isNil(indexArrayChanged) ? newValueWithIndex : newValue});
  };

  return (
    <OutlinedInput
      size="small"
      multiline
      autoFocus
      value={inputValue}
      onChange={handleValueChange}
      fullWidth
      sx={{
        padding: '2px',
        fontSize: '12px',
        ...(hasFocus && {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.grey[500]} !important`,
          },
        }),
      }}
    />
  );
};
