import React, {memo, useCallback, useState} from 'react';
import {Box, Stack} from '@mui/material';
import styles from 'components/Vendors/EditVendorsForm/EditVendorsForm.module.scss';
import {Checkbox} from 'components/base/Checkbox/Checkbox';
import LogoWrapper from 'components/base/LogoWrapper/LogoWrapper';
import {EditIconButton} from 'components/base/Button/EditIconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {ServerVendor} from 'services/types/ServerVendor';
import {useFormikContext} from 'formik';
import {useDeleteVendorModal} from 'components/Vendors/EditVendorsForm/useDeleteVendorModal';
import Popover from 'components/base/Popover/Popover';
import EditVendorForm from 'components/Vendors/EditVendorForm/EditVendorForm';
import {usePopover} from 'components/base/Popover/usePopover';
import {observer} from 'mobx-react-lite';

interface IProps {
  data: ServerVendor;
  isEditingMode: boolean;
}

const VendorSection = (props: IProps) => {
  const {data, isEditingMode} = props;

  const {values, setFieldValue} = useFormikContext<{vendors: ServerVendor[]}>();
  const [currentVendorId, setCurrentVendorId] = useState<number>();

  const {onOpenDeleteModal, deleteModal} = useDeleteVendorModal({onSuccess: console.log});
  const {popoverProps, onOpen, onClose: onClosePopover} = usePopover();

  const onCloseEditVendorFormPopover = useCallback(() => {
    setCurrentVendorId(undefined);
    onClosePopover();
  }, [setCurrentVendorId, onClosePopover]);

  const onOpenEditVendorFormPopover = useCallback((event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>, vendorId: number) => {
    setCurrentVendorId(vendorId);
    onOpen(event);
  }, [setCurrentVendorId, onOpen]);

  const onChange = useCallback((vendor: ServerVendor, value: boolean) => {
    const valueArray = values.vendors || [];
    const newValue = value ? [...valueArray, vendor] : valueArray.filter((v) => v.id !== vendor.id);
    setFieldValue('vendors', newValue);

  }, [setFieldValue, values.vendors]);

  const leftSectionStyles =
    isEditingMode
      ? `${styles.leftMoved} ${styles.hidden}`
      : `${styles.leftInPlace} ${styles.visible}`;

  const rightSectionStyles =
    isEditingMode
      ? `${styles.rightMoved} ${styles.visible}`
      : `${styles.rightInPlace} ${styles.hidden}`;

  return (
    <>
      {deleteModal}

      <Popover {...popoverProps} horizontal="left">
        <EditVendorForm typeId={data.type} vendorId={currentVendorId} onClose={onCloseEditVendorFormPopover} onSuccess={onCloseEditVendorFormPopover}/>
      </Popover>

      <Stack justifyContent="space-between" direction="row">
        <Stack gap={1} alignItems="center" direction="row">
          <Box className={`${styles.animatedObject} ${leftSectionStyles}`}>
            <Checkbox
              onChange={(value) => onChange(data, value)}
              value={!!values.vendors?.find((i) => i.id === data.id)}
              label=""
            />
          </Box>
          <LogoWrapper url={data.logo} />
          <Box>{data.name}</Box>
        </Stack>
        <Stack
          height="100%"
          alignSelf="center"
          direction="row"
          alignItems="center"
          className={`${styles.animatedObject} ${rightSectionStyles}`}>
          <EditIconButton onClick={(event) => onOpenEditVendorFormPopover(event, data.id)}/>
          <DeleteOutlineIcon style={{cursor: 'pointer'}} color="secondary" onClick={() => onOpenDeleteModal(data.id)}/>
        </Stack>
      </Stack>
    </>
  );
};

export default memo(observer(VendorSection));