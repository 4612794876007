import {ServerRequestVendor, ServerVendor} from 'services/types/ServerVendor';
import {convertServerVendorToClient} from 'services/types/converters/convertServerVendorToClient';
import API from 'services/api';

const DEFAULT_PREFIX = 'vendor';

export class VendorsService {
  public static async getVendors(): Promise<ServerVendor[]> {
    const result = await API.get(`${DEFAULT_PREFIX}/`);
    return result.data.map(convertServerVendorToClient);
  }

  public static async getVendor(vendorId: number): Promise<ServerVendor> {
    const result = await API.get(`${DEFAULT_PREFIX}/${vendorId}`);
    return convertServerVendorToClient(result.data);
  }

  public static async addVendor(data: ServerRequestVendor): Promise<ServerVendor> {
    const result = await API.post(`${DEFAULT_PREFIX}/`, JSON.stringify(data));
    return convertServerVendorToClient(result.data);
  }

  public static changeVendor(data: ServerRequestVendor): Promise<void> {
    return API.put(`${DEFAULT_PREFIX}/${data.id}`, JSON.stringify(data));
  }

  public static deleteVendor(vendorId: number): Promise<void> {
    return API.delete(`${DEFAULT_PREFIX}/${vendorId}`);
  }

  public static async getVendorLogo(vendorId: number): Promise<string> {
    const result = await API.get(`${DEFAULT_PREFIX}/logo/${vendorId}`);
    return result.data;
  }

  public static updateVendorLogo(vendorId: number, logo: File | string): Promise<void> {
    const formData = new FormData();
    formData.append('logo', logo || '');

    return API.put(`${DEFAULT_PREFIX}/logo/${vendorId}`, formData, {
      headers: {'Content-Type': 'multipart/form-data'},
    });
  }


  public static deleteVendorLogo(vendorId: number): Promise<void> {
    return API.delete(`${DEFAULT_PREFIX}/logo/${vendorId}`);
  }
}
