import {useSnackbar} from 'notistack';
import WithCommunication from 'components/base/WithCommunication/WithCommunication';
import SitesContent from 'components/SitesContent/SitesContent';
import {useEffect} from 'react';
import {SitesService} from 'services/SitesService';
import {useRequestData} from 'services/useRequestData';
import {useRequestMutation} from 'services/useRequestMutation';

const SitesPage = () => {
  const {enqueueSnackbar} = useSnackbar();
  const {data, communication, request: getSites, refetch: refetchSites} = useRequestData(SitesService.getSites);
  const {request: deleteSiteRequest} = useRequestMutation(SitesService.deleteSite, () => {
    refetchSites();
    enqueueSnackbar('Site deleted successfully', {variant: 'success'});
  });

  useEffect(() => {
    getSites();
  }, [getSites]);

  return (
    <WithCommunication communication={communication} data={data}>
      {(loadedSites) => <SitesContent sites={loadedSites} onDeleteSite={(id: string) => deleteSiteRequest(id)} />}
    </WithCommunication>
  );
};

export default SitesPage;
