import {SVGIcon} from 'components/base/SVGIcon/SVGIcon';

interface IIconProps {
    onClick: () => void;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export default function Icon({onClick, Icon}: IIconProps) {

  return (
    <div onClick={onClick}>
      <SVGIcon>
        <Icon />
      </SVGIcon>
    </div>
  );
}
