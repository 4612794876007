import {useLayoutEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const useScrollToIDHash = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    setTimeout(() => {
      const hash = location.hash;
      const element = document.getElementById(hash.substring(1));

      if (element) {
        element.scrollIntoView();
      }
    }, 25);
  }, [location]);
};
