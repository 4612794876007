const useIsElectronApp = (): {
    isElectronApp: boolean;
} => {
    const userAgent = navigator.userAgent.toLowerCase();

    return {
        isElectronApp: userAgent.indexOf(' electron/') > -1,
    };
};

export default useIsElectronApp;
