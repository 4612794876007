import CellRendererEmpty from './CellRendererEmpty';
import TypographyWrapper from 'components/base/Typography/TypographyWrapper';

type CellRendererStringProps = {
  value: string | undefined | null;
};

export default function CellRendererString(props: CellRendererStringProps) {
  if (!props.value) {
    return <CellRendererEmpty />;
  }

  return <TypographyWrapper value={props.value} />;
}
