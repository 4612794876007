import {ServerExpensesType} from 'services/types/ServerExpensesType';

export const mapExpenseTypeToLabel: Record<ServerExpensesType, string> = {
  [ServerExpensesType.hotel]: 'Hotel',
  [ServerExpensesType.travel]: 'Travel',
  [ServerExpensesType.gas]: 'Gas',
  [ServerExpensesType.mileage]: 'Mileage',
  [ServerExpensesType.food]: 'Food',
  [ServerExpensesType.medical_bill]: 'Medical Bill',
  [ServerExpensesType.other]: 'Other',
  [ServerExpensesType.unknown]: '',
  [ServerExpensesType.patient_stipend]: 'Patient Stipend',
};
