import TextField from '@mui/material/TextField';
import {DatePicker as MUIDatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import FormHelperText from 'components/base/FormHelperText/FormHelperText';
import FormControl from '@mui/material/FormControl';
import {makeStyles} from '@material-ui/styles';

export interface DatePickerProps {
  value: Date | null;
  placeholder: string;
  onChange(value: Date | null): void;
  errorMessage?: string;
}

const useStyles = makeStyles(() => ({
  anotherBorder: {
    border: '2px solid #DFE1E6',
  },
}));

export default function DatePicker({value, onChange, placeholder, errorMessage}: DatePickerProps) {
  const classes = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl error={Boolean(errorMessage)}>
        <MUIDatePicker
          label={placeholder}
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size={'small'}
              InputProps={{
                ...params.InputProps,
                classes: {notchedOutline: classes.anotherBorder},
                style: {background: '#FAFBFC'},
              }}
            />
          )}
        />
        <FormHelperText errorMessage={errorMessage} />
      </FormControl>
    </LocalizationProvider>
  );
}
