import React, {memo, useCallback, useState} from 'react';
import {STORE_TRIAL} from 'store/StoreTrial';
import {equals} from 'ramda';
import {ServerDetails} from 'services/types/ServerDetails';
import {observer} from 'mobx-react-lite';
import {useOnSuccessCommunication} from 'helpers/hooks/useOnSuccessCommunication';
import TextInput from 'components/base/Input/TextInput';
import {Stack} from '@mui/material';
import Title from 'components/base/Title/Title';
import FormButtons from 'components/base/Button/FormButton/FormButtons';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';

interface IProps {
  name: keyof ServerDetails;
  index?: number;
  onSuccess?(): void;
  onClose?(): void;
}

const ChangeTrialDetailArrayForm = observer((props: IProps) => {
  const {onSuccess, onClose, index, name} = props;

  const details = STORE_TRIAL._details;
  const communication = STORE_TRIAL.changeDetailsCommunication;
  const isLoading = communication.type === 'requesting';

  useOnErrorCommunication(communication);
  useOnSuccessCommunication(communication, () => onSuccess && onSuccess());

  const array = details[name] as Array<string>;

  const initial = index !== undefined ? array[index] : '';
  const [value, setValue] = useState(initial);
  const onChangeHandler = useCallback((value: string) => setValue(value), [setValue]);

  const putTrialDetailsHandler = useCallback(() => {
    let correctArray;

    if (array.length === 0) {
      correctArray = [value];
    }

    if (Number.isInteger(index)) {
      correctArray = array.map((item, i) => (i === index ? value : item));
    } else {
      correctArray = [...array, value];
    }

    STORE_TRIAL.changeDetails({
      ...details,
      [name]: correctArray,
    });
  }, [name, index, array, value, details]);

  return (
    <Stack direction="column" justifyContent="end" alignItems="end">
      <Title title="" />
      <TextInput placeholder="Enter" onChange={onChangeHandler} value={value} />
      <FormButtons
        isLoading={isLoading}
        isDisabled={equals(value, initial) || value.length < 1}
        onClose={onClose}
        onSubmit={putTrialDetailsHandler}
      />
    </Stack>
  );
});

export default memo(ChangeTrialDetailArrayForm);
