import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FormHelperText from 'components/base/FormHelperText/FormHelperText';
import FormControl from '@mui/material/FormControl';
import styles from './DropZone.module.scss';

export interface DropZoneProps {
  onChange(files: File): void;
  errorMessage?: string;
  title?: string;
  hint?: string;
  value?: File;
  isEmpty?: boolean;
}

export function DropZone({onChange, errorMessage, title, hint, isEmpty}: DropZoneProps) {
  const [loadedFile, setLoadedFile] = useState<File | undefined>(undefined);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0];
        setLoadedFile(file);
        onChange(file);
      }
    },
    [onChange]
  );

  useEffect(() => {
    if (isEmpty) setLoadedFile(undefined);
  }, [isEmpty]);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  return (
    <FormControl fullWidth error={Boolean(errorMessage)}>
      {title && (
        <div style={{color: '#7A7E8D', fontWeight: 600, fontSize: 13, lineHeight: '26px', marginTop: -20}}>{title}</div>
      )}
      <Paper
        sx={{
          background: '#F5F6F7',
          border: '1px dashed #C1C1C1',
          borderRadius: '3px',
          width: 400,
          height: 96,
          cursor: 'pointer',
        }}
      >
        <Box
          sx={{
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            wordBreak: 'break-word',
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {getText(loadedFile, isDragActive)}
        </Box>
      </Paper>
      {hint && <div className={styles.hint}>{hint}</div>}
      <FormHelperText errorMessage={errorMessage} />
    </FormControl>
  );
}

function getText(loadedFile: File | undefined, isDragActive: boolean): React.ReactElement | string {
  if (loadedFile) {
    return `File: ${loadedFile.name}.${loadedFile.type}`;
  }
  if (isDragActive) {
    return 'Drop the files here ...';
  }
  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      Drop file here or
      <Box ml="5px" color="#769E98">
        Click to select file on computer
      </Box>
    </div>
  );
}
