import {Typography} from '@mui/material';

import SectionA from './section2/A';
import SectionD from './section2/D';
import SectionC from './section2/C';
import SectionB from './section2/B';

export const Section2 = () => {
  return (
    <section id="guide-section-2">
      <Typography variant="h6" mb={2}>
        Section 2. Using CRIO for Study Activities
      </Typography>
      <SectionA />
      <SectionB />
      <SectionC />
      <SectionD />
    </section>
  );
};

export default Section2;
