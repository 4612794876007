export enum ServerExpensesType {
  unknown,
  hotel,
  travel,
  gas,
  mileage,
  food,
  medical_bill,
  other,
  patient_stipend,
}
