import {useCallback, useMemo, useState} from 'react';
import {Communication} from 'models/Communication';
import {useSnackbar} from 'notistack';

export function useRequestData<RequestParams, ResponseData>(
  req: (...params: RequestParams[]) => Promise<ResponseData>,
  onSuccess?: () => void,
  disabledSnackbar?: boolean
) {
  const [communication, setCommunication] = useState<Communication>({
    type: 'notAsked',
  });
  const [data, setData] = useState<ResponseData | undefined>(undefined);
  const {enqueueSnackbar} = useSnackbar();

  const sendRequest = useCallback(
    (...p: RequestParams[]) => {
      req(...p)
        .then((resp) => {
          setData(resp);
          setCommunication({type: 'success'});
          onSuccess?.();
        })
        .catch((error: string) => {
          setData(undefined);
          setCommunication({type: 'error', error});
          !disabledSnackbar && enqueueSnackbar(error, {variant: 'error'});
        });
    },
    [disabledSnackbar, enqueueSnackbar, onSuccess, req]
  );

  const request = useCallback(
    (...p: RequestParams[]) => {
      setCommunication({type: 'requesting'});
      sendRequest(...p);
    },
    [sendRequest]
  );

  const refetch = useCallback(
    (...p: RequestParams[]) => {
      setCommunication({type: 'refetch'});
      sendRequest(...p);
    },
    [sendRequest]
  );

  return useMemo(
    () => ({
      data,
      request,
      communication,
      refetch,
    }),
    [communication, data, request, refetch]
  );
}
