import React, {useCallback, useState} from 'react';

export function usePopover<T>(anchor?: HTMLButtonElement | HTMLDivElement | null) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | HTMLDivElement | null>(null);
  const [data, setData] = useState<T>();

  const onOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>, data?: T) => {
      setAnchorEl(anchor || event.currentTarget);
      setData(data);
    },
    [anchor]
  );
  const onClose = useCallback(() => setAnchorEl(null), []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return {
    popoverProps: {id, open, anchorEl},
    onOpen,
    onClose,
    data,
  };
}
