import React, {memo, useCallback} from 'react';
import {FormControlLabel, Radio, RadioGroup} from '@mui/material';
import {ServerTrialStatus} from 'services/types/ServerTrialStatus';
import {SelectOption} from 'components/base/Select/Select';
import {mapServerTrialStatusToLabel} from 'services/types/converters/getServerTrialStatus';

const OPTIONS: SelectOption<ServerTrialStatus>[] = [
  {
    value: ServerTrialStatus.EARLY_STAGE_PIPELINE,
    label: mapServerTrialStatusToLabel[ServerTrialStatus.EARLY_STAGE_PIPELINE],
  },
  {
    value: ServerTrialStatus.SITE_QUALIFICATION,
    label: mapServerTrialStatusToLabel[ServerTrialStatus.SITE_QUALIFICATION],
  },
  {
    value: ServerTrialStatus.SITE_ACTIVATION,
    label: mapServerTrialStatusToLabel[ServerTrialStatus.SITE_ACTIVATION],
  },
  {
    value: ServerTrialStatus.OPEN_TO_ENROLLMENT,
    label: mapServerTrialStatusToLabel[ServerTrialStatus.OPEN_TO_ENROLLMENT],
  },
  {
    value: ServerTrialStatus.CLOSED_TO_ENROLLMENT,
    label: mapServerTrialStatusToLabel[ServerTrialStatus.CLOSED_TO_ENROLLMENT],
  },
  {
    value: ServerTrialStatus.CLOSED,
    label: mapServerTrialStatusToLabel[ServerTrialStatus.CLOSED],
  },
  {
    value: ServerTrialStatus.NOT_MOVING_FORWARD,
    label: mapServerTrialStatusToLabel[ServerTrialStatus.NOT_MOVING_FORWARD],
  },
];

interface IProps {
  currentStatus: ServerTrialStatus;
  onChangeStatus?(status: ServerTrialStatus): void;
}

const ChangeTrialStatusForm = (props: IProps) => {
  const {currentStatus, onChangeStatus} = props;

  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: string) =>
      onChangeStatus && onChangeStatus(+value as ServerTrialStatus),
    [onChangeStatus]
  );

  return (
    <RadioGroup defaultValue={currentStatus} onChange={onChangeHandler}>
      {OPTIONS.map((option) => (
        <FormControlLabel
          key={option.value}
          sx={{
            marginLeft: '-24px',
            width: '130%',
            '&:hover': {background: '#EBECF0'},
            '&:active': {background: '#EEF6F5'},
          }}
          value={option.value}
          control={<Radio />}
          label={option.label}
        />
      ))}
    </RadioGroup>
  );
};

export default memo(ChangeTrialStatusForm);
