import React, {memo} from 'react';
import Box from '@mui/material/Box';
import Title from 'components/base/Title/Title';
import Loading from 'components/base/WithCommunication/Loading';
import FormButtons from 'components/base/Button/FormButton/FormButtons';
import {observer} from 'mobx-react-lite';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#FFF',
  outline: 'none',
  boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
  borderRadius: '3px',
  px: '24px',
  pt: '24px',
};

const hintStyle = {
  fontFamily: 'SF Pro Text',
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '24px',
  color: '#FFF',
  height: '56px',
};

interface IProps {
  isLoading?: boolean;
  onDelete?(): void;
  onCancel?(): void;
  title?: string;
  hint?: string;
}

const DeleteLayout = (props: IProps) => {
  const {onDelete, onCancel, isLoading, hint = 'Operation is irreversible!', title = ''} = props;

  return (
    <Box sx={style}>
      <Title title={title} />
      <Box sx={{background: '#FD4A5C', marginBottom: '24px'}}>
        <Box display="flex" alignItems="center" justifyContent="center" style={hintStyle}>
          {hint}
        </Box>
      </Box>
      {isLoading && <Loading />}
      <FormButtons
        isDisabled={isLoading}
        submitTitle="Delete"
        submitButtonProps={{backgroundColor: '#FD4A5C'}}
        cancelButtonProps={{variant: 'text', color: 'info'}}
        onSubmit={onDelete}
        onClose={onCancel}
      />
    </Box>
  );
};

export default memo(observer(DeleteLayout));
