import {ServerDetails} from 'services/types/ServerDetails';
import {TrialDetailsAccordion} from 'components/TrialContent/trial/details/TrialDetailsAccordion';
import {TrialDetailsKeyValue} from 'components/TrialContent/trial/details/TrialDetailsKeyValue';
import Stack from '@mui/material/Stack';
import {TrialDefaultValue} from 'components/TrialContent/trial/details/TrialDefaultValue';
import {EditIconButton} from 'components/base/Button/EditIconButton';
import {useMemo} from 'react';
import AddInfoButton from 'components/base/Button/AddInfoButton';
import ChangeTrialDetailDateForm from 'components/TrialContent/trial/details/blocks/ChangeTrialDetailDateForm';
import {usePopover} from 'components/base/Popover/usePopover';
import Popover from 'components/base/Popover/Popover';
import {Box} from '@mui/material';

interface Props {
  details: ServerDetails;
  canEdit: boolean;
}
export function TrialTimeline(props: Props) {
  const {
    details: {
      sq_visit_data,
      siv_date,
      activation_date,
      irb_approval_date,
      protocol_version_date,
      icf_date,
      enrollment_end_date,
      first_screened_date,
    },
    canEdit,
  } = props;

  return (
    <TrialDetailsAccordion title="Trial timeline">
      <Stack direction="column" spacing={1}>
        <DatePair k="SQ visit date" name="sq_visit_data" v={sq_visit_data} canEdit={canEdit} />
        <DatePair k="SIV date" name="siv_date" v={siv_date} canEdit={canEdit} />
        <DatePair k="Activation date" name="activation_date" v={activation_date} canEdit={canEdit} />
        <DatePair k="IRB approval date" name="irb_approval_date" v={irb_approval_date} canEdit={canEdit} />
        <DatePair k="Protocol version dates" name="protocol_version_date" v={protocol_version_date} canEdit={canEdit} />
        <DatePair k="ICF dates" name="icf_date" v={icf_date} canEdit={canEdit} />
        <DatePair k="Last patient enrolled" name="enrollment_end_date" v={enrollment_end_date} canEdit={canEdit} />
        <DatePair k="First patient in" name="first_screened_date" v={first_screened_date} canEdit={canEdit} />
      </Stack>
    </TrialDetailsAccordion>
  );
}

function DatePair({
  k,
  v,
  name,
  canEdit,
}: {
  name: keyof ServerDetails;
  k: string;
  v: string | undefined;
  canEdit: boolean;
}) {
  const {popoverProps, onClose, onOpen} = usePopover();

  const renderEdit = useMemo(() => {
    if (v) {
      return (
        <Stack direction="row-reverse" gap={1}>
          <TrialDefaultValue type="date" v={v} />
          {canEdit && <EditIconButton />}
        </Stack>
      );
    }

    if (canEdit) return <AddInfoButton />;

    return 'N/A';
  }, [v, canEdit]);

  return (
    <>
      <Popover {...popoverProps} horizontal="left">
        <ChangeTrialDetailDateForm onClose={onClose} name={name} onSuccess={onClose} />
      </Popover>

      <TrialDetailsKeyValue k={k}>
        <Box onClick={canEdit ? onOpen : undefined}>{renderEdit}</Box>
      </TrialDetailsKeyValue>
    </>
  );
}
