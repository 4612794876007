import styles from './TrialDefaultValue.module.scss';
import {formatDate} from 'components/base/utils/formatDate';

type DateType = {
  type: 'date';
  v: string;
};

type SimpleType = {
  type: 'simple';
  v: number | string;
};

type Props = DateType | SimpleType;

export function TrialDefaultValue(props: Props) {
  switch (props.type) {
    case 'simple':
      return <span className={styles.value}>{props.v}</span>;
    case 'date':
      return <span className={styles.value}>{formatDate(new Date(props.v), false)}</span>;
    default:
      return null;
  }
}
