import {ServerTrialBase} from 'services/types/ServerTrialBase';
import useClearableSelectOptions, {SelectOption} from 'components/base/Select/Select';
import {useMemo} from 'react';
import {getUserFullName} from 'services/types/converters/getUserFullName';
import isNotNil from 'components/base/utils/isNotNil';
import filterByLabel from 'components/TrialsOverviewContent/filters/utils/filterByLabel';

export function usePIsOptions(list: ServerTrialBase[]): SelectOption<string | undefined>[] {
  return useClearableSelectOptions(
    useMemo(() => {
      const piList = list
        .map((item) => item.pi)
        .flat()
        .filter(isNotNil);

      return piList
        .map((item) => {
          const fullName = getUserFullName({...item});
          return {
            label: fullName,
            value: fullName,
          };
        })
        .filter(filterByLabel);
    }, [list])
  );
}
