import {Box, Divider, Stack, Typography} from '@mui/material';
import {LogoSmall} from 'components/complex/Logo/LogoSmall';
import Button from 'components/base/Button/Button';
import Title from 'components/base/Title/Title';
import Popover from 'components/base/Popover/Popover';
import {usePopover} from 'components/base/Popover/usePopover';
import EquipmentNewForm from 'components/complex/EquipmentNewForm/EquipmentNewForm';
import {MatrixEquipmentResponse} from 'services/types/ServerEquipment';
import {EquipmentAdminTable} from './EquipmentAdminTable';
import {useDeleteEquipmentModal} from './useDeleteEquipmentModal';

interface Props {
  equipment: MatrixEquipmentResponse;
  refetchEquipment: () => void;
}

const EquipmentContent = ({equipment, refetchEquipment}: Props) => {
  const {popoverProps, onClose, onOpen, data} = usePopover<{equipmentId: number; name: string}>();

  const {
    onOpenDeleteModal,
    deleteModal,
    isLoading: isDeleting,
  } = useDeleteEquipmentModal({onSuccess: refetchEquipment});

  return (
    <>
      {deleteModal}
      <Popover {...popoverProps} horizontal="left" sx={{mt: 0.5}}>
        <EquipmentNewForm
          onClose={onClose}
          onSuccess={refetchEquipment}
          equipmentId={data?.equipmentId}
          initialName={data?.name}
        />
      </Popover>
      <Stack direction="column" spacing={3} width="100%">
        <LogoSmall />
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Title title="Equipment" mainTitle style={{marginBottom: 0, marginRight: 8}} />
          <Button variant="outlined" onClick={onOpen}>
            <Stack direction="row" alignItems="center" spacing={0}>
              <Typography>+ Add new equipment type</Typography>
            </Stack>
          </Button>
        </Stack>
        <Divider sx={{mt: '16px !important'}} />
        <Box>
          <EquipmentAdminTable
            data={equipment}
            onDelete={onOpenDeleteModal}
            disabledDelete={isDeleting}
            onEdit={onOpen}
          />
        </Box>
      </Stack>
    </>
  );
};

export default EquipmentContent;
