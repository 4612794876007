import Typography from '@mui/material/Typography';
import {DataGridColumn} from 'components/base/Table/DataGridColumn';
import {DataGridWithTypes} from 'components/base/Table/DataGridWithTypes';
import {useMemo} from 'react';
import {SiteEquipment, TrialEquipmentMatrix, EquipmentTrialEquipmentMatrix} from 'services/types/ServerEquipment';
import {GridRenderEditCellParams} from '@mui/x-data-grid/models/params/gridCellParams';
import {CustomEditComponent} from 'components/base/Table/renderCell/CustomEditField';
import {RenderCellParams} from 'components/base/Table/renderCell/RenderCellParams';
import {GridCellEditCommitParams} from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import {Box, List, ListItem, Stack, useTheme} from '@mui/material';
import {DeleteIconButton} from 'components/base/Button/DeleteIconButton';
import TypographyWrapper from 'components/base/Typography/TypographyWrapper';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import {isEmpty, isNil} from 'lodash';
import {RouterLink} from 'components/base/RouterLink/RouterLink';

type Props = {
  trialId: number;
  equipment: TrialEquipmentMatrix;
  deleteEquipment?: (trialEquipmentId: number) => void;
  deleteDisabled?: boolean;
  addNoteToEquipmentType(trialEquipmentId: number, note: string): void;
  addNoteToRequiredEquipment(trialEquipmentId: number, note: string, siteId?: number, siteEquipmentId?: number): void;
  editable?: boolean;
};

type Row = {
  id: number;
  name: string;
} & {[key: string]: SiteEquipment | number | string};

export const EquipmentTrialTable = ({
  trialId,
  equipment,
  deleteEquipment,
  deleteDisabled,
  addNoteToEquipmentType,
  addNoteToRequiredEquipment,
  editable,
}: Props) => {
  const {trial_equipment, sites} = equipment;
  const theme = useTheme();

  const rows: Row[] = useMemo(() => {
    return trial_equipment.map((equipment, index) => ({
      ...equipment,
      ...sites.reduce((acc, site) => {
        return {
          ...acc,
          [site.id]: site.equipment[index],
        };
      }, {}),
    }));
  }, [sites, trial_equipment]);

  const columns: DataGridColumn<Row>[] = useMemo(() => {
    return [
      ...(deleteEquipment
        ? [
            {
              renderHeader: () => null,
              field: 'Delete equipment',
              width: 50,
              renderCell: ({row}: RenderCellParams<EquipmentTrialEquipmentMatrix>) => (
                <DeleteIconButton
                  onClick={() => {
                    deleteEquipment(Number(row.id));
                  }}
                  disabled={!!deleteDisabled}
                />
              ),
            },
          ]
        : []),
      {
        renderHeader: () => <Typography variant="subtitle2">Required equipment</Typography>,
        renderCell: (props: RenderCellParams<EquipmentTrialEquipmentMatrix>) => {
          const {row} = props;
          return (
            <Stack>
              <TypographyWrapper style={{fontWeight: 'bold'}} value={row.name} />
              {row.note && <TypographyWrapper style={{fontSize: '12px'}} value={row.note} />}
            </Stack>
          );
        },
        renderEditCell: (params: GridRenderEditCellParams) => (
          <Box padding="15px 10px" width="100%">
            <Stack>
              <TypographyWrapper style={{fontWeight: 'bold'}} value={params.row.name} />
              <CustomEditComponent {...params} />
            </Stack>
          </Box>
        ),

        field: 'note',
        editable,
        flex: 1,
        minWidth: 200,
        cellClassName: 'sticky-col',
      },
      ...sites.map((site) => ({
        field: `${site.id}`,
        flex: 1,
        minWidth: 200,
        editable: true,
        renderHeader: () => (
          <RouterLink
            to={`/trials/${trialId}/sites/${site.id}/edit`}
            state={{from: {pathname: `/trials/${trialId}/equipment`, title: 'Back to Equipment tracking'}}}
            sx={{'&': {color: theme.palette.text.primary}, width: '100%'}}
          >
            <TypographyWrapper withTooltip variant="subtitle2" value={site.name} />
          </RouterLink>
        ),
        renderCell: ({row}: RenderCellParams<Row>) => {
          const equipment = row[site.id] as SiteEquipment;
          const presentEquipment = equipment.site_equipment;
          const equipmentLength = presentEquipment.length;

          if (!equipmentLength)
            return (
              <Stack>
                <AssignmentLateOutlinedIcon sx={{fill: '#DA0000'}} />
                {equipment.note && <TypographyWrapper style={{fontSize: '12px'}} value={equipment.note} />}
              </Stack>
            );

          return (
            <Stack>
              <CheckIcon sx={{fill: '#2E7D32'}} />
              {!isEmpty(presentEquipment) && (
                <List disablePadding>
                  {presentEquipment.map((info) => (
                    <ListItem alignItems="center" disablePadding key={info.site_equipment_id}>
                      {equipmentLength > 1 && !isEmpty(info.note) && (
                        <CircleIcon sx={{width: 4, mr: 1}} fontSize="small" />
                      )}
                      <TypographyWrapper style={{fontSize: '12px'}} value={info.note} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Stack>
          );
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
          const {row} = params;
          const equipment = row[site.id] as SiteEquipment;
          const presentEquipment = equipment.site_equipment;
          const equipmentLength = presentEquipment.length;

          if (!equipmentLength)
            return (
              <Box
                padding="15px 10px"
                width="100%"
                height="100%"
                className="red-cell"
                display="flex"
                alignItems="center"
              >
                <Stack width="100%">
                  <AssignmentLateOutlinedIcon sx={{fill: '#DA0000'}} />
                  <CustomEditComponent {...params} valueKey="note" />
                </Stack>
              </Box>
            );

          return (
            <Box padding="15px 10px" width="100%">
              <Stack>
                <CheckIcon sx={{fill: '#2E7D32'}} />
                {presentEquipment.map((info, index) => (
                  <CustomEditComponent
                    key={info.site_equipment_id}
                    {...params}
                    valueKey={`site_equipment[${index}]['note']`}
                    indexArrayChanged={index}
                    value={params.value.site_equipment[index].note}
                  />
                ))}
              </Stack>
            </Box>
          );
        },
      })),
    ];
  }, [deleteDisabled, deleteEquipment, editable, sites, theme.palette.text.primary, trialId]);

  return (
    <Box
      sx={{
        '& .red-cell': {
          backgroundColor: 'rgba(218, 0, 0, 0.10)',
        },
      }}
    >
      <DataGridWithTypes
        rows={rows}
        columns={columns}
        getCellClassName={(params) => {
          if (!Number(params.field)) return '';
          const equipment = params?.row[params.field]?.site_equipment;
          const equipmentLength = equipment?.length;

          if (!equipmentLength) return 'red-cell';
          return '';
        }}
        onCellEditCommit={(params: GridCellEditCommitParams) => {
          const {value, field, id} = params;

          // id - Trial Equipment ID
          // field - "note" or site ID
          // value - new value

          const trialEquipmentId = Number(id);

          // Data for the unavailable equipment cell
          if (Number(field) && isEmpty(value.site_equipment)) {
            // Field is a site ID
            addNoteToRequiredEquipment(trialEquipmentId, value.note, Number(field), undefined);
          }

          // Data for the available equipment cell
          if (Number(field) && !isEmpty(value.site_equipment)) {
            //Field not changed
            if (isNil(value?.index)) return;

            const siteEquipmentId = value.site_equipment[value.index].site_equipment_id;
            const note = value.site_equipment[value.index].note;

            addNoteToRequiredEquipment(trialEquipmentId, note, undefined, Number(siteEquipmentId));
          }

          // Data for the equipment type cell
          if (field === 'note' && typeof value === 'string') {
            // Value is a string note
            addNoteToEquipmentType(trialEquipmentId, value);
          }
        }}
        isHeaderSticky
        backgroundColor="#ffffff"
      />
    </Box>
  );
};
