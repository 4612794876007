import {TrialTitle} from 'components/TrialContent/trial/details/TrialTitle';
import MonitoringVisitsForm from './MonitoringVisitsForm';
import {MonitoringVisitsTable} from './MonitoringVisitsTable';
import {Stack, Typography, useTheme} from '@mui/material';
import DownloadIcon from '@mui/icons-material/DownloadOutlined';
import {useCallback, useEffect} from 'react';
import {STORE_TRIAL_MONITORING_VISITS} from 'store/StoreMonitoringVisits';
import {observer} from 'mobx-react-lite';
import {ServerMonitoringVisitRequest} from 'services/types/ServerMonitoringVisit';
import WithCommunication from 'components/base/WithCommunication/WithCommunication';
import Button from 'components/base/Button/Button';
import downloadFromUrl from 'services/downloadFromUrl';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';

type Props = {
  trialId: number;
};

export const MonitoringVisits = observer(({trialId}: Props) => {
  const theme = useTheme();
  const {
    monitoringVisits,
    addMonitoringVisitCommunication,
    loadMonitoringVisitsCommunication,
    canCreateMonitoringVisits,
    canDeleteMonitoringVisits,
    deleteMonitoringVisitCommunication,
  } = STORE_TRIAL_MONITORING_VISITS;

  useEffect(() => {
    STORE_TRIAL_MONITORING_VISITS.loadMonitoringVisits(trialId);
    return () => {
      STORE_TRIAL_MONITORING_VISITS.onUnmountMonitoringVisits();
      STORE_TRIAL_MONITORING_VISITS.onUnmountCommunications();
    };
  }, [trialId]);

  useOnErrorCommunication(addMonitoringVisitCommunication);
  useOnErrorCommunication(deleteMonitoringVisitCommunication);

  const addNewMonitoringVisit = useCallback(
    (visit: ServerMonitoringVisitRequest) => {
      STORE_TRIAL_MONITORING_VISITS.addMonitoringVisit(trialId, visit);
    },
    [trialId]
  );

  const deleteMonitoringVisit = useCallback((visitId: number) => {
    STORE_TRIAL_MONITORING_VISITS.deleteMonitoringVisit(visitId);
  }, []);

  const onDownload = useCallback(() => downloadFromUrl(`/trial/monitoring_visits/export/${trialId}`), [trialId]);

  return (
    <WithCommunication communication={loadMonitoringVisitsCommunication} data={{monitoringVisits}}>
      {({monitoringVisits}) => (
        <Stack direction={'column'} spacing={2}>
          {canCreateMonitoringVisits && (
            <Stack width={400} mb="20px" gap={1}>
              <TrialTitle title="Add new Monitoring Visit" />
              <MonitoringVisitsForm
                onSend={addNewMonitoringVisit}
                isLoading={addMonitoringVisitCommunication.type === 'requesting'}
              />
            </Stack>
          )}
          <>
            <Stack direction="row" alignItems="center" spacing={3}>
              <TrialTitle title="Monitoring Visits" />
              {monitoringVisits.length > 0 && (
                <Button height={24} size="small" color="info" variant="text" backgroundColor="rgba(9, 30, 66, 0.04)">
                  <Stack direction="row" alignItems="center" spacing={0} onClick={onDownload}>
                    <DownloadIcon style={{height: 16, color: theme.palette.primary.main}} />
                    <Typography style={{lineHeight: 0}}>Download all monitoring visits as excel file</Typography>
                  </Stack>
                </Button>
              )}
            </Stack>
            <MonitoringVisitsTable
              visits={monitoringVisits}
              onDelete={canDeleteMonitoringVisits ? deleteMonitoringVisit : undefined}
              deleteDisabled={deleteMonitoringVisitCommunication.type === 'requesting'}
            />
          </>
        </Stack>
      )}
    </WithCommunication>
  );
});

export default MonitoringVisits;
