import React, {memo, useEffect} from 'react';
import {useRequestData} from 'services/useRequestData';
import {TrialsService} from 'services/TrialsService';
import {AvatarUnknown} from 'components/base/Avatar/AvatarUnknown';
import {ServerUser} from 'services/types/ServerUser';
import AvatarImage from 'components/base/Avatar/AvatarImage';

interface IProps {
  user: Pick<ServerUser, 'first_name' | 'last_name' | 'id' | 'display_name'>;
  size?: number;
}

const Avatar = (props: IProps) => {
  const {
    user: {first_name, id, last_name, display_name},
    size = 24,
  } = props;

  const {request, communication, data} = useRequestData(TrialsService.getAvatar, undefined, true);
  const isLoading = communication.type === 'requesting';

  useEffect(() => request(id), [request, id]);

  if ((!first_name && !last_name && !id) || isLoading) {
    return <div style={{width: size}} />;
  }

  if (!data && communication.type !== 'notAsked') {
    return <AvatarUnknown user={{first_name, last_name, display_name}} size={size} />;
  }

  return <AvatarImage user={{id}} size={size} />;
};

export default memo(Avatar);
