import {FieldConfig, Field, FieldProps} from 'formik';
import {Select, SelectProps} from 'components/base/Select/Select';
import {useCallback} from 'react';
import {equals} from 'ramda';

type Props<T> = FieldConfig & Omit<SelectProps<T>, 'value' | 'onChange'>;

export default function SelectField<T>(props: Props<T>) {
  const findValue = useCallback(
    (newValue: T | undefined) => props.options.find((option) => equals(option.value, newValue)),
    [props.options]
  );
  return (
    <Field {...props}>
      {({field, form, meta}: FieldProps<T | undefined>) => (
        <Select
          {...props}
          value={findValue(field.value)}
          onChange={(v) => form.setFieldValue(field.name, v.value)}
          onClose={props.onClose}
          errorMessage={meta.touched && !meta.value ? meta.error : ''}
        />
      )}
    </Field>
  );
}
