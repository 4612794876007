import React, {memo, useCallback, useMemo} from 'react';
import {FormikProvider, useFormik} from 'formik';
import {equals} from 'ramda';
import {observer} from 'mobx-react-lite';
import {Box} from '@mui/material';
import Stack from '@mui/material/Stack';
import TextInputField from 'components/base/Input/TextInputField';
import {SelectOption} from 'components/base/Select/Select';
import validateNotEmpty from 'validators/validateNotEmpty';
import DropZoneField from 'components/base/DropZone/DropZoneField';
import {ServerVendorType} from 'services/types/ServerVendorType';
import {mapVendorToTitle} from 'components/Vendors/EditVendorsForm/useVendorGroups';
import FormButtons from 'components/base/Button/FormButton/FormButtons';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';
import {useOnSuccessCommunication} from 'helpers/hooks/useOnSuccessCommunication';
import {STORE_VENDOR} from 'store/StoreVendor';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddInfoButton from 'components/base/Button/AddInfoButton';
import SelectField from 'components/base/Select/SelectField';
import LogoWrapper from 'components/base/LogoWrapper/LogoWrapper';
import {convertToServer, IFormValues, initialValues} from 'components/Vendors/EditVendorForm/validation';

const TYPE_OPTIONS: SelectOption<ServerVendorType>[] = [
  {
    value: ServerVendorType.clinical_trial_management,
    label: mapVendorToTitle[ServerVendorType.clinical_trial_management],
  },
  {
    value: ServerVendorType.clinical_portals,
    label: mapVendorToTitle[ServerVendorType.clinical_portals],
  },
  {
    value: ServerVendorType.patient_recruitment,
    label: mapVendorToTitle[ServerVendorType.patient_recruitment],
  },
  {
    value: ServerVendorType.travel_vendors,
    label: mapVendorToTitle[ServerVendorType.travel_vendors],
  },
];

interface IProps {
  typeId: number;
  vendorId?: number;
  onSuccess?(): void;
  onClose?(): void;
}

const EditVendorForm = (props: IProps) => {
  const {onClose, vendorId, typeId, onSuccess} = props;

  const {
    _vendors,
    changeVendor,
    changeVendorCommunication,
    addVendor,
    addVendorCommunication,
    deleteVendorLogo,
    deleteVendorLogoCommunication,
    updateVendorLogo,
    updateVendorLogoCommunication
  } = STORE_VENDOR;

  const vendor = useMemo(() => _vendors.find(i => i.id === vendorId), [_vendors, vendorId]);
  const isLoading =
    [changeVendorCommunication, addVendorCommunication, updateVendorLogoCommunication, deleteVendorLogoCommunication]
      .some(i => i.type === 'requesting');

  useOnErrorCommunication(addVendorCommunication);
  useOnErrorCommunication(changeVendorCommunication);
  useOnErrorCommunication(updateVendorLogoCommunication);
  useOnErrorCommunication(deleteVendorLogoCommunication);
  useOnSuccessCommunication(addVendorCommunication, () => onSuccess && onSuccess());
  useOnSuccessCommunication(changeVendorCommunication, () => onSuccess && onSuccess());

  const initialFormValues: IFormValues = useMemo(() =>
    (!vendorId || !vendor) ? {...initialValues, type: typeId} : vendor,
    [vendorId, typeId, vendor]);

  const onSubmit = useCallback(async (data: IFormValues) => {
    const requestVendor = convertToServer(data);

    let id = vendorId;

    if(id) {
      await changeVendor(requestVendor);
      if(initialFormValues.logo && !requestVendor.logo) {
        await deleteVendorLogo(requestVendor.id);
      }
    } else {
      const newVendor = await addVendor(requestVendor);
      id = newVendor.id;
    }

    if(requestVendor.logo) {
      await updateVendorLogo(id, requestVendor.logo);
    }
  }, [vendorId, addVendor, updateVendorLogo, changeVendor, deleteVendorLogo, initialFormValues.logo]);

  const formikProps = useFormik({initialValues: initialFormValues, onSubmit});
  const {setFieldValue, values, isValid, handleSubmit} = formikProps;

  const removeImage = useCallback(() => setFieldValue('logo', null), [setFieldValue]);

  const renderRemoveButton = useMemo(() => (
    <Stack direction="row" alignItems="center" onClick={removeImage}>
      <Box mr={1}>
        <DeleteOutlineIcon />
      </Box>
      Remove image
    </Stack>
  ), [removeImage]);

  const renderLogoSection = useCallback((logo: File | string | null) => {
    if(logo === null) {
      return <DropZoneField name="logo" title="Logo image" />;
    }

    if(typeof logo === 'object') {
      return (
        <Stack direction="row" alignItems="center">
          <LogoWrapper url={URL.createObjectURL(logo as File)} />
          <AddInfoButton title={renderRemoveButton} onClick={console.log} />
        </Stack>
      );
    }

    return (
      <Stack direction="row" alignItems="center">
        <LogoWrapper url={logo} />
        <AddInfoButton title={renderRemoveButton} onClick={console.log} />
      </Stack>
    );
  }, [renderRemoveButton]);

  return (
    <FormikProvider value={formikProps}>
      <form onSubmit={handleSubmit}>
        <Stack direction="column">
          <SelectField name="type" placeholder="Select category" disabled={!vendorId} validate={validateNotEmpty} options={TYPE_OPTIONS} />
          <TextInputField name="name" placeholder="Displayed name" validate={validateNotEmpty} />
          <TextInputField name="url" placeholder="Service link" validate={validateNotEmpty} />
          {renderLogoSection(values.logo)}
          <Stack direction="row" justifyContent="end">
            <FormButtons
              isLoading={isLoading}
              isDisabled={!isValid || equals(values, formikProps.initialValues)}
              onClose={onClose}
            />
          </Stack>
        </Stack>
      </form>
    </FormikProvider>
  );
};

export default memo(observer(EditVendorForm));