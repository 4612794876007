import {createTheme} from '@mui/material/styles';
import {muiThemeTypography} from 'theme/muiThemeFonts';

const COLOR_PRIMARY_MAIN = '#769E98';
const COLOR_SECONDARY_MAIN = '#2C5F57';
const COLOR_ERROR_MAIN = '#FF5A58';
const COLOR_WARNING_MAIN = '#FF9145';
const COLOR_SUCCESS_MAIN = '#68C292';
const COLOR_WASHED_BLUE = '#42526e';

export const MainMuiTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: COLOR_PRIMARY_MAIN,
    },
    secondary: {
      main: COLOR_SECONDARY_MAIN,
    },
    error: {
      main: COLOR_ERROR_MAIN,
    },
    warning: {
      main: COLOR_WARNING_MAIN,
    },
    success: {
      main: COLOR_SUCCESS_MAIN,
    },
    info: {
      main: COLOR_WASHED_BLUE,
    },
    text: {
      primary: COLOR_WASHED_BLUE,
    },
  },
  typography: muiThemeTypography,
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontWeight: 400,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        flexContainer: {
          gap: 32,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          minWidth: 48,
          fontWeight: 600,
        },
      },
    },
    MuiStack: {
      defaultProps: {
        spacing: 1,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: 600,
          maxWidth: '100%',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '16px 24px 0 !important',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '18px 24px !important',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          minWidth: 180,
        },
        list: {
          padding: '8px 0',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          maxWidth: 400,
          minWidth: 160,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          background: 'unset !important',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'unset',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#E0E6F2',
          borderRadius: 8,
          '&:before': {
            backgroundColor: 'unset',
          },
          margin: '0 !important',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          height: 48,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation0: {
          boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
          borderRadius: 3,
        },
        elevation1: {
          boxShadow: 'none',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        placement: 'top-start',
        arrow: true,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
          '&$disabled': {
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          '.SnackbarItem-variantSuccess': {
            backgroundColor: `${COLOR_SUCCESS_MAIN} !important`,
          },
          '.SnackbarItem-variantWarning': {
            backgroundColor: `${COLOR_WARNING_MAIN} !important`,
          },
          '.SnackbarItem-variantError': {
            backgroundColor: `${COLOR_ERROR_MAIN} !important`,
          },
        },
      },
    },
  },
});
