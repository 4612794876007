import {Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';

export type StyledRouterLinkProps = {
  hash: string;
  children: React.ReactNode;
};

export const StyledRouterLink = ({hash, children}: StyledRouterLinkProps) => {
  return (
    <Link
      to={{
        hash,
      }}
      color="secondary"
      sx={{
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
      component={RouterLink}
    >
      {children}
    </Link>
  );
};

export default StyledRouterLink;
