import React, {memo, useCallback, useEffect, useMemo} from 'react';
import Stack from '@mui/material/Stack';
import {Form, Formik} from 'formik';
import {useOnSuccessCommunication} from 'helpers/hooks/useOnSuccessCommunication';
import {equals} from 'ramda';
import {observer} from 'mobx-react-lite';
import Title from 'components/base/Title/Title';
import FormButtons from 'components/base/Button/FormButton/FormButtons';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';
import SelectField from 'components/base/Select/SelectField';
import {SelectOption} from 'components/base/Select/Select';
import {ServerUserRole} from 'services/types/ServerUserRole';
import {mapUserRoleToLabel} from 'services/types/converters/getServerUserRoleLabel';
import {TrialsService} from 'services/TrialsService';
import {ServerAssignUserToTrial} from 'services/types/ServerTrialUser';
import {useRequestMutation} from 'services/useRequestMutation';
import {useRequestData} from 'services/useRequestData';
import {AzureService} from 'services/AzureService';
import Loading from 'components/base/WithCommunication/Loading';
import ComboboxField from 'components/base/Combobox/ComboboxField';
import {renderLabelName} from 'helpers/renderLabelName';

const TYPE_OPTIONS: SelectOption<ServerUserRole>[] = [
  {
    value: ServerUserRole.PI,
    label: mapUserRoleToLabel[ServerUserRole.PI],
  },
  {
    value: ServerUserRole.CRC,
    label: mapUserRoleToLabel[ServerUserRole.CRC],
  },
  {
    value: ServerUserRole.LEAD,
    label: mapUserRoleToLabel[ServerUserRole.LEAD],
  }
];

interface IFormValues extends Omit<ServerAssignUserToTrial, 'user_id'> {
  user_id: string | null;
}

const initialValues: IFormValues = {
  user_id: null,
  role: ServerUserRole.CRC,
};

interface IProps {
  trialId: number;
  onSuccess?(): void;
  onClose?(): void;
}

const AddUserForm = (props: IProps) => {
  const {trialId, onSuccess, onClose} = props;

  const {data: allUsers, communication: getCommunication, request: requestGet} = useRequestData(AzureService.getUsers);

  useEffect(() => requestGet(), [requestGet]);

  const {communication: assignCommunication, request: assignRequest} = useRequestMutation<
    {
      trialId: number;
      data: ServerAssignUserToTrial;
    },
    void
  >((params) => TrialsService.assignUserToTrial(params.trialId, params.data));

  const assignIsLoading = assignCommunication.type === 'requesting';
  const isLoading = getCommunication.type === 'requesting';

  useOnErrorCommunication(getCommunication);
  useOnErrorCommunication(assignCommunication);
  useOnSuccessCommunication(assignCommunication, () => onSuccess && onSuccess());

  const userOptions: SelectOption[] = useMemo(
    () =>
      allUsers
        ?.filter((i) => !!i.email || !!i.first_name || !!i.last_name)
        .map((user) => ({
          label: renderLabelName(user),
          value: user.id,
        })) || [],
    [allUsers]
  );

  const onSubmit = useCallback(
    (data: IFormValues) => {
      if (data.user_id) {
        assignRequest({trialId, data: {user_id: data.user_id, role: data.role}});
      }
    },
    [trialId, assignRequest]
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({handleSubmit, isValid, values, initialValues}) => (
          <Form onSubmit={handleSubmit}>
            <Title title="" />
            <Stack direction="column" spacing={1}>
              <ComboboxField name="user_id" inputLabel="User" options={userOptions} />
              <SelectField name="role" placeholder="Select role" options={TYPE_OPTIONS} />
              <FormButtons
                isLoading={assignIsLoading}
                isDisabled={!isValid || equals(values, initialValues) || !values.user_id}
                onClose={onClose}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default memo(observer(AddUserForm));
