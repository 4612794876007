import {Form, Formik} from 'formik';
import {useCallback} from 'react';
import FormButtons from 'components/base/Button/FormButton/FormButtons';
import TextInputField from 'components/base/Input/TextInputField';
import {Box} from '@mui/material';
import {useRequestMutation} from 'services/useRequestMutation';
import {EquipmentService} from 'services/EquipmentService';
import {ServerEquipment} from 'services/types/ServerEquipment';

type EquipmentFormValues = {
  name: string;
};

type Props = {
  onClose?: () => void;
  onSuccess?: (result?: ServerEquipment) => void;
  initialName?: string;
  equipmentId?: number;
};

export const EquipmentNewForm = ({onClose, onSuccess, initialName, equipmentId}: Props) => {
  const initialValues = {
    name: initialName ?? '',
  };

  const {request: createNewEquipment} = useRequestMutation<string, ServerEquipment>(
    EquipmentService.createEquipment,
    (result) => {
      onSuccess?.(result);
      onClose?.();
    }
  );

  const {request: updateEquipment} = useRequestMutation<
    {
      equipmentId: number;
      name: string;
    },
    ServerEquipment
  >(EquipmentService.updateEquipmentType, (result) => {
    onSuccess?.(result);
    onClose?.();
  });

  const onSubmit = useCallback(
    (data: EquipmentFormValues) => {
      if (data.name) {
        if (equipmentId) {
          updateEquipment({equipmentId, name: data.name});
          return;
        }
        createNewEquipment(data.name);
      }
    },
    [createNewEquipment, equipmentId, updateEquipment]
  );

  return (
    <Box width={350} maxWidth="100%">
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            <TextInputField name="name" placeholder="Enter new equipment name" />
            <FormButtons onClose={onClose} />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EquipmentNewForm;
