import React, {memo} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';

import {Home, Logout} from 'routes/Home/Home';
import TrialsPage from 'routes/Trials/TrialsPage';
import TrialPage from 'routes/Trial/TrialPage';
import SitesPage from 'routes/Sites/SitesPage';

import {MsalProvider} from '@azure/msal-react';
import {IPublicClientApplication} from '@azure/msal-browser';
import {CustomNavigationClient} from 'utils/NavigationClient';
import EditSite from 'routes/EditSite/EditSite';
import AddSite from 'routes/AddSite/AddSite';
import {AdminRoute} from 'components/base/AdminRoute/AdminRoute';
import EquipmentPage from 'routes/Equipment/EquipmentPage';
import {LayoutWrapper} from 'components/complex/BasicLayout/LayoutWrapper';

type AppProps = {
  pca: IPublicClientApplication;
};

// todo fix extra requests/renders
function App({pca}: AppProps) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Logout />} path="/logout" />

        <Route element={<LayoutWrapper />}>
          <Route element={<TrialsPage />} path="trials" />
          <Route element={<TrialPage />} path="trials/:trialId/*" />

          <Route element={<AdminRoute />}>
            <Route element={<SitesPage />} path="sites" />
            <Route element={<EditSite />} path="sites/:siteId/edit" />
            <Route element={<AddSite />} path="sites/new" />
            <Route element={<EquipmentPage />} path="equipment" />
          </Route>
        </Route>
      </Routes>
    </MsalProvider>
  );
}

export default memo(App);
