import {DataGrid, DataGridProps} from '@mui/x-data-grid';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {DataGridColumn} from 'components/base/Table/DataGridColumn';
import {SxProps, useTheme, darken} from '@mui/material';

type DataWithID<Data> = Data & {
  id: string | number;
};

type Props<Data> = {
  columns: DataGridColumn<Data>[];
  rows: DataWithID<Data>[];
  isHeaderSticky?: boolean;
  backgroundColor?: string;
  styles?: SxProps;
} & Omit<DataGridProps, 'rows' | 'columns'>;

export function DataGridWithTypes<Data>(props: Props<Data>) {
  const [ref, setRef] = useState<HTMLDivElement | null>();
  const theme = useTheme();
  const columns = useMemo(
    () =>
      props.columns.map((value) => ({
        ...value,
        sortable: false,
        filterable: false,
      })),
    [props.columns]
  );
  const rows = useMemo(() => props.rows.map((value) => ({...value, id: `${value.id}`})), [props.rows]);

  const heights = {
    row: 50,
    header: 50,
    footer: 24,
  };
  const commonProps: DataGridProps = {
    ...props,
    rows,
    columns,
    autoHeight: true,
    disableSelectionOnClick: true,
    headerHeight: heights.header,
    rowHeight: heights.row,
    hideFooter: heights.footer === 0,
    disableColumnMenu: true,
    hideFooterPagination: true,
    hideFooterSelectedRowCount: true,
    getRowHeight: useCallback(() => 'auto', []),
  };

  useEffect(() => {
    if (!ref || !props.isHeaderSticky) {
      return undefined;
    }
    const headerRow = ref.querySelector('.MuiDataGrid-columnHeaders');

    const onScroll = (event: Event) => {
      const deltaX = (event as WheelEvent)?.deltaX;
      if (deltaX !== 0 && ref) {
        event.preventDefault();
        const virtualScroller = ref.querySelector('.MuiDataGrid-virtualScroller');
        if (virtualScroller) virtualScroller.scrollLeft += deltaX;
      }
    };

    headerRow?.addEventListener('mousewheel', onScroll);
    return () => {
      headerRow?.removeEventListener('mousewheel', onScroll);
    };
  }, [props.isHeaderSticky, ref]);

  return (
    <div style={{height: '100%'}}>
      <DataGrid
        ref={(ref) => setRef(ref)}
        {...commonProps}
        sx={{
          border: 0,

          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '15px'},
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {py: '22px'},
          '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {minHeight: heights.footer},
          '&.MuiDataGrid-root .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
            py: 0,
            boxShadow: 'none',
            '& .MuiSelect-select': {
              padding: '15px 14px',
            },
            'fieldset.MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderRadius: 0,
            },
          },
          ...(props.isHeaderSticky && {
            '& .MuiDataGrid-columnHeaders': {
              position: 'sticky',
              top: 0,
              zIndex: theme.zIndex.mobileStepper - 1,
              backgroundColor: props.backgroundColor,
            },
            '& .MuiDataGrid-virtualScroller': {
              marginTop: '0 !important',
            },
            '& .MuiDataGrid-main': {
              overflow: 'visible',
            },
          }),
          '& .MuiDataGrid-cell.sticky-col': {
            position: 'sticky',
            left: 0,
            zIndex: theme.zIndex.mobileStepper - 1,
            backgroundColor: props.backgroundColor,
          },
          ...(props.backgroundColor && {
            '&.MuiDataGrid-root .MuiDataGrid-row:hover, .css-lyislt-MuiDataGrid-root .MuiDataGrid-row.Mui-hovered': {
              '& .MuiDataGrid-cell.sticky-col': {
                backgroundColor: darken(props.backgroundColor, 0.04),
              },
            },
          }),
          ...props.styles,
        }}
      />
    </div>
  );
}
