import {Stack, Typography} from '@mui/material';
import {BackButton} from 'components/base/BackButton/BackButton';
import Button from 'components/base/Button/Button';
import Title from 'components/base/Title/Title';
import UserTable from '../UserTable/UserTable';
import AddButton from 'components/base/Button/AddInfoButton';
import {usePopover} from 'components/base/Popover/usePopover';
import {Formik, FormikHelpers} from 'formik';
import {useNavigate, useParams} from 'react-router-dom';
import Popover from 'components/base/Popover/Popover';
import {OwnersSelect} from './OwnersSelect';
import {ServerUser} from 'services/types/ServerUser';
import {SitesService} from 'services/SitesService';
import {useSnackbar} from 'notistack';
import SiteInformationForm from './SiteInformationForm';
import {ServerSiteResponse, ServerSitesRequest} from 'services/types/ServerSites';
import {ServerSiteEquipment} from 'services/types/ServerEquipment';
import EquipmentSiteForm from './EquipmentSiteForm';
import {useRequestMutation} from 'services/useRequestMutation';

type Props = {
  backRoute: {
    pathname: string;
    title: string;
  };
  site?: ServerSiteResponse;
};

type SiteFormValues = {
  name: string;
  street1: string;
  street2?: string;
  city: string;
  zip_code: string;
  state: string;
  owners: ServerUser[];
  equipment: ServerSiteEquipment[];
};

export const SiteForm = ({backRoute, site}: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const siteId = params.siteId;
  const trialId = params.trialId;

  const {popoverProps, onOpen, onClose} = usePopover();
  const {enqueueSnackbar} = useSnackbar();

  const {communication: addSiteCommunication, request: requestAddSite} = useRequestMutation(
    SitesService.createSite,
    () => {
      navigate(backRoute);
      enqueueSnackbar('Site created successfully', {variant: 'success'});
    }
  );

  const {communication: editSiteCommunication, request: requestEditSite} = useRequestMutation<
    {
      siteId: string;
      site: ServerSitesRequest;
    },
    ServerSiteResponse
  >(
    ({siteId, site}) => SitesService.editSite(siteId, site),
    () => {
      navigate(backRoute);
      enqueueSnackbar('Site updated successfully', {variant: 'success'});
    }
  );

  const isLoading = addSiteCommunication.type === 'requesting' || editSiteCommunication.type === 'requesting';

  const initialValues: SiteFormValues = {
    name: site?.name || '',
    street1: site?.street1 || '',
    street2: site?.street2 || '',
    city: site?.city || '',
    zip_code: site?.zip_code || '',
    state: site?.state || '',
    owners: site?.owners || [],
    equipment: site?.equipment || [],
  };

  const getOwnersWithoutId = (owners: ServerUser[], id: string) => owners.filter((owner) => owner.id !== id);

  const handleSubmit = async (values: SiteFormValues, {setValues}: FormikHelpers<SiteFormValues>) => {
    const {name, street1, street2, city, zip_code, state, owners, equipment} = values;
    const data = {
      name,
      street1,
      street2,
      city,
      zip_code,
      state,
      owners: owners.map((owner) => owner.id),
      equipment,
      ...(trialId && {trial_id: Number(trialId)}),
    };
    if (!site) {
      const resp = await requestAddSite(data);
      if (resp) {
        setValues(resp);
      }
    } else if (site && siteId) {
      const resp = await requestEditSite({siteId, site: data});
      if (resp) {
        setValues(resp);
      }
    }
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({values, setFieldValue, submitForm}) => {
          return (
            <>
              <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                <Stack>
                  <BackButton href={backRoute.pathname} text={backRoute.title} />
                  <Title title="New site" mainTitle />
                </Stack>
                <Stack direction="row">
                  <Button variant="outlined" onClick={() => navigate(backRoute)}>
                    Cancel
                  </Button>
                  <Button type="submit" onClick={submitForm} isLoading={isLoading}>
                    Save
                  </Button>
                </Stack>
              </Stack>
              <Stack>
                <SiteInformationForm />
                <>
                  <Popover {...popoverProps} width={500} horizontal="right">
                    <OwnersSelect
                      onClose={onClose}
                      onSubmit={(value) => {
                        setFieldValue('owners', values.owners.concat(value));
                      }}
                      trialId={trialId ? Number(trialId) : undefined}
                      currentOwners={values.owners}
                    />
                  </Popover>
                  <Typography variant="subtitle1">Site owners</Typography>
                  <UserTable
                    users={values.owners}
                    deleteUser={(id) => setFieldValue('owners', getOwnersWithoutId(values.owners, id))}
                    deleteDisabled={false}
                  />
                  <AddButton title="+ Add owner" onClick={onOpen} />
                </>
                <Stack>
                  <Typography variant="subtitle1" sx={{mt: 3}}>
                    Site equipment
                  </Typography>
                  <EquipmentSiteForm equipment={values.equipment} />
                </Stack>
              </Stack>
            </>
          );
        }}
      </Formik>
    </>
  );
};
