import {ServerTrial} from 'services/types/ServerTrial';
import TrialContentLayout from 'components/TrialContent/TrialContentLayout';
import {useParams} from 'react-router-dom';
import {ServerVendor} from 'services/types/ServerVendor';
import {memo, useMemo} from 'react';
import Typography from '@mui/material/Typography';
import {VendorScreen} from 'components/TrialContent/vendor/VendorScreen';
import {useVendorScreen} from 'components/TrialContent/vendor/useVendorScreen';

interface Props {
  trial: ServerTrial;
  vendors: ServerVendor[];
}

const Vendor = ({trial, vendors}: Props) => {
  const {vendorId} = useParams();

  const {splitMode} = useVendorScreen(vendors);

  const primaryVendor = useMemo(() => {
    return vendors.find((vendor) => `${vendor.id}` === vendorId);
  }, [vendorId, vendors]);

  return (
    <TrialContentLayout vendors={!splitMode ? vendors : undefined} trial={trial}>
      {primaryVendor ? (
        <VendorScreen vendors={vendors} primaryVendor={primaryVendor} />
      ) : (
        <Typography variant={'h6'}>Error happened on loading vendor data</Typography>
      )}
    </TrialContentLayout>
  );
};

export default memo(Vendor);
