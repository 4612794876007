import Box from '@mui/material/Box';
import styles from 'components/Vendors/Card.module.scss';
import Stack from '@mui/material/Stack';

type Props = {
  trialName: string;
  onClick(): void;
  selected: boolean;
};

export function TrialCard(props: Props) {
  return (
    <Box className={props.selected ? styles.rootSelected : styles.root} onClick={props.onClick}>
      <Stack direction={'column'} spacing={1}>
        <div className={styles.trialTitle}>{props.trialName}</div>
        <div className={styles.trialDetails}>Trial details</div>
      </Stack>
    </Box>
  );
}
