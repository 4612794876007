import {useMemo} from 'react';
import Typography from '@mui/material/Typography';
import {DeleteIconButton} from 'components/base/Button/DeleteIconButton';
import CreatableField from 'components/base/Combobox/CreatableField';
import TextInputField from 'components/base/Input/TextInputField';
import {DataGridColumn} from 'components/base/Table/DataGridColumn';
import {DataGridWithTypes} from 'components/base/Table/DataGridWithTypes';
import {Equipment, ServerEquipment, ServerSiteEquipment} from 'services/types/ServerEquipment';

export type TableSiteEquipment = {
  equipmentId: number;
  name: string;
  note: string;
  id: number;
};

type Props = {
  equipment: TableSiteEquipment[];
  onDeleteSite: (index: number) => void;
  availableEquipment: ServerEquipment[];
  onEquipmentTypeSelect: (equipment: ServerSiteEquipment | Equipment, index: number) => void;
};

export const EquipmentSiteTable = ({equipment, onDeleteSite, availableEquipment, onEquipmentTypeSelect}: Props) => {
  const availableEquipmentOptions = useMemo(
    () =>
      availableEquipment.map((equipment) => ({
        label: equipment.name,
        value: equipment.id.toString(),
      })),
    [availableEquipment]
  );

  const columns: Array<DataGridColumn<TableSiteEquipment>> = [
    {
      renderHeader: () => <Typography variant="subtitle2">Equipment type</Typography>,
      field: 'Equipment type',
      flex: 2,
      renderCell: ({row}) => {
        return (
          <CreatableField
            name={`equipment.${row.id}.name`}
            options={availableEquipmentOptions}
            textFieldProps={{
              size: 'small',
            }}
            inputProps={{
              onKeyDown: (event) => {
                // Prevents blocking the spacebar when typing
                event.stopPropagation();
              },
            }}
            value={
              availableEquipmentOptions.find((option) => option.label === row.name) || {
                label: row.name,
                value: row.name,
                custom: true,
              }
            }
            clearOnBlur
            clearOnEscape
            onChange={(value) => {
              if (value) {
                onEquipmentTypeSelect(
                  {
                    name: value.custom ? value.value : value.label,
                    note: row.note,
                    ...(row?.equipmentId && {id: row.equipmentId}),
                  },
                  row.id
                );
              }
            }}
            hideErrorMessage
          />
        );
      },
    },
    {
      renderHeader: () => <Typography variant="subtitle2">Notes</Typography>,
      field: 'Notes',
      flex: 2,
      renderCell: ({row}) => {
        return (
          <TextInputField
            name={`equipment.${row.id}.note`}
            placeholder="Note"
            onKeyDown={(event) => {
              // Prevents blocking the spacebar when typing
              event.stopPropagation();
            }}
            hideErrorMessage
          />
        );
      },
    },
    {
      renderHeader: () => null,
      field: 'Remove',
      flex: 1,
      renderCell: ({row}) => {
        return (
          <DeleteIconButton
            onClick={() => {
              onDeleteSite(row.id);
            }}
            disabled={false}
          />
        );
      },
    },
  ];

  return <DataGridWithTypes rows={equipment} columns={columns} />;
};
