import MUIFormHelperText from '@mui/material/FormHelperText';

type Props = {
  errorMessage: string | undefined;
  error?: boolean;
};

export default function FormHelperText({errorMessage, error}: Props) {
  // hack to support https://vertaai.atlassian.net/browse/FE-355 - prevent jumping when error appears
  if (!errorMessage) {
    return (
      <MUIFormHelperText error={error}>
        <span>&nbsp;</span>
      </MUIFormHelperText>
    );
  }

  return <MUIFormHelperText error={error}>{errorMessage}</MUIFormHelperText>;
}
