// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import MUICheckbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import React, {ChangeEvent, useCallback, useMemo} from 'react';
import Box from '@mui/material/Box';
import TypographyWrapper from 'components/base/Typography/TypographyWrapper';

type ClickableProps = {
  onChange(value: boolean): void;
  disabled?: boolean;
  readonly?: boolean;
};

type ICheckboxProps = ClickableProps & {
  value: boolean;
  label: string;
  name?: string;
  lineThroughOnSelected?: boolean;
  allUsers?: {id: string; display: string}[];
};

export const Checkbox = (props: ICheckboxProps) => {
  const {value, label, name, disabled, allUsers, onChange, readonly} = props;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.checked);
    },
    [onChange]
  );

  const textDecorationLine = useMemo(() => {
    if (props.lineThroughOnSelected && props.value) {
      return 'line-through';
    }

    return undefined;
  }, [props.lineThroughOnSelected, props.value]);

  const renderLabel = useCallback(
    (label: string) => {
      let labelText = label;

      if (allUsers !== undefined) {
        const userMentionRegex = /@\[([^\]]+)\]\(([^)]+)\)/;
        const userMentionMatches = labelText.match(userMentionRegex);

        if (userMentionMatches && userMentionMatches.length === 3) {
          const [userMention, userDisplay, userId] = userMentionMatches;
          const user = allUsers.find((u) => u.id === userId);
          labelText = labelText.replace(userMention, user ? user.display : userDisplay);
        }
      }

      return labelText;
    },
    [allUsers]
  );

  return (
    <Box sx={{maxWidth: 600}}>
      <FormControl fullWidth>
        <Stack direction={'row'} alignItems={'center'} style={{width: '100%'}}>
          <FormControlLabel
            disabled={disabled}
            sx={{
              cursor: readonly || disabled ? 'default' : 'pointer',
            }}
            control={
              <MUICheckbox
                disableRipple={true}
                checked={value}
                onChange={readonly ? undefined : handleChange}
                disabled={disabled}
                name={name}
                style={{pointerEvents: 'auto', cursor: readonly || disabled ? 'default' : 'pointer'}}
              />
            }
            label={
              <div
                style={{
                  width: '100%',
                  display: 'inline-grid',
                  textDecorationLine,
                  cursor: readonly || disabled ? 'default' : 'pointer',
                }}
              >
                <TypographyWrapper value={renderLabel(label)} />
              </div>
            }
          />
        </Stack>
      </FormControl>
    </Box>
  );
};
