import {Communication} from 'models/Communication';
import {ServerRequestVendor, ServerVendor} from 'services/types/ServerVendor';
import {makeAutoObservable} from 'mobx';
import {VendorsService} from 'services/VendorsService';

const INITIAL = {
  _vendors: [],
  communication: {type: 'notAsked'} as Communication,
};

class StoreVendor {
  _vendors: ServerVendor[] = INITIAL._vendors;
  getVendorCommunication: Communication = INITIAL.communication;
  getVendorsCommunication: Communication = INITIAL.communication;
  addVendorCommunication: Communication = INITIAL.communication;
  changeVendorCommunication: Communication = INITIAL.communication;
  deleteVendorCommunication: Communication = INITIAL.communication;
  updateVendorLogoCommunication: Communication = INITIAL.communication;
  deleteVendorLogoCommunication: Communication = INITIAL.communication;

  constructor() {
    makeAutoObservable(this);
  }

  getVendors = () => {
    this.getVendorsCommunication = {type: 'requesting'};
    VendorsService.getVendors()
      .then((result) => {
        this.getVendorsCommunication = {type: 'success'};
        this._vendors = result;
      })
      .catch((error: string) => {
        this.getVendorsCommunication = {type: 'error', error};
      });
  };

  getVendor = async (vendorId: number) => {
    this.getVendorCommunication = {type: 'requesting'};
    await VendorsService.getVendor(vendorId)
      .then((result) => {
        this._vendors = this._vendors.map(vendor => vendor.id !== vendorId ? vendor : result);
        this.getVendorCommunication = {type: 'success'};
      })
      .catch((error: string) => {
        this.getVendorCommunication = {type: 'error', error};
      });
  };

  addVendor = async (vendor: ServerRequestVendor) => {
    this.addVendorCommunication = {type: 'requesting'};
    try {
      const newVendor = await VendorsService.addVendor(vendor);
      this._vendors = this._vendors.concat([newVendor]);
      this.addVendorCommunication = {type: 'success'};
      return newVendor;
    } catch (error: any) {
      this.getVendorCommunication = {type: 'error', error};
      throw error;
    }
  };

  changeVendor = async (vendor: ServerRequestVendor) => {
    this.changeVendorCommunication = {type: 'requesting'};
    try {
      await VendorsService.changeVendor(vendor);
      this._vendors = this._vendors.map(item => item.id !== vendor?.id ? item : {...item, ...vendor});
      this.changeVendorCommunication = {type: 'success'};
    } catch(error: any) {
      this.changeVendorCommunication = {type: 'error', error};
      throw error;
    }
  };

  deleteVendor = (vendorId: number) => {
    this.deleteVendorCommunication = {type: 'requesting'};
    VendorsService.deleteVendor(vendorId)
      .then(() => {
        this._vendors = this._vendors.filter(vendor => vendor.id !== vendorId);
        this.deleteVendorCommunication = {type: 'success'};
      })
      .catch((error: string) => {
        this.deleteVendorCommunication = {type: 'error', error};
      });
  };

  updateVendorLogo = async (vendorId: number, logo: File) => {
    this.updateVendorLogoCommunication = {type: 'requesting'};
    await VendorsService.updateVendorLogo(vendorId, logo)
      .then(async () => {
        await this.getVendor(vendorId);
        this.updateVendorLogoCommunication = {type: 'success'};
      })
      .catch((error: string) => {
        this.updateVendorLogoCommunication = {type: 'error', error};
      });
  };

  deleteVendorLogo = async (vendorId: number) => {
    this.deleteVendorLogoCommunication = {type: 'requesting'};
    await VendorsService.deleteVendorLogo(vendorId)
      .then(async () => {
        this._vendors = this._vendors.map(vendor => vendor.id !== vendorId ? vendor : {...vendor, logo: null});
        this.deleteVendorLogoCommunication = {type: 'success'};
      })
      .catch((error: string) => {
        this.deleteVendorLogoCommunication = {type: 'error', error};
      });
  };
}

export const STORE_VENDOR = new StoreVendor();
