import WithCommunication from 'components/base/WithCommunication/WithCommunication';
import {useCallback, useEffect} from 'react';
import {ServerExpenses} from 'services/types/ServerExpenses';
import {DataGridWithTypes} from 'components/base/Table/DataGridWithTypes';
import {DataGridColumn} from 'components/base/Table/DataGridColumn';
import CellRendererString from 'components/base/Table/columns/CellRendererString';
import CellRendererDate from 'components/base/Table/columns/CellRendererDate';
import Stack from '@mui/material/Stack';
import {TrialExpensesAddForm} from 'components/TrialContent/trial/expenses/TrialExpensesAddForm';
import {mapExpenseTypeToLabel} from 'services/types/converters/getServerExpensesTypeLabel';
import {DeleteIconButton} from 'components/base/Button/DeleteIconButton';
import {ServerExpenseRequest} from 'services/types/ServerExpenseRequest';
import CellRendererDownload from 'components/base/Table/columns/CellRendererDownload';
import {TrialTitle} from 'components/TrialContent/trial/details/TrialTitle';
import Button from 'components/base/Button/Button';
import DownloadIcon from '@mui/icons-material/DownloadOutlined';
import {useTheme} from '@mui/material/styles';
import {Typography} from '@mui/material';
import downloadFromUrl from 'services/downloadFromUrl';
import {RenderCellParams} from 'components/base/Table/renderCell/RenderCellParams';
import CellRendererCheckbox from 'components/base/Table/columns/CellRendererCheckbox';
import {STORE_TRIAL_EXPENSES} from 'store/StoreExpenses';
import {Permissions} from 'services/types/Permissions';
import {observer} from 'mobx-react-lite';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';

type ServerExpenseValue = Omit<ServerExpenseRequest, 'trialId'>;

interface Props {
  trialId: number;
}

export const TrialExpenses = observer(({trialId}: Props) => {
  const expenses = STORE_TRIAL_EXPENSES._expenses;
  const userPermissions = STORE_TRIAL_EXPENSES.userPermissions;

  const communication = STORE_TRIAL_EXPENSES.loadExpensesCommunication;
  const addCommunication = STORE_TRIAL_EXPENSES.addExpenseCommunication;
  const deleteCommunication = STORE_TRIAL_EXPENSES.deleteExpenseCommunication;
  const markAsCompleteCommunication = STORE_TRIAL_EXPENSES.markAsCompleteCommunication;

  useOnErrorCommunication(addCommunication);
  useOnErrorCommunication(deleteCommunication);
  useOnErrorCommunication(markAsCompleteCommunication);

  const addExpenses = useCallback(
    (expenses: ServerExpenseValue) => {
      STORE_TRIAL_EXPENSES.addExpense(trialId, expenses);
    },
    [trialId]
  );

  const deleteExpenses = useCallback((expenseId: number) => {
    STORE_TRIAL_EXPENSES.deleteExpense(expenseId);
  }, []);

  const markExpenseAsComplete = useCallback((expenseId: number, complete: boolean) => {
    STORE_TRIAL_EXPENSES.markAsComplete(expenseId, complete);
  }, []);

  useEffect(() => {
    STORE_TRIAL_EXPENSES.loadExpenses(trialId);
    return () => {
      STORE_TRIAL_EXPENSES.onUnmountCommunications();
      STORE_TRIAL_EXPENSES.onUnmountExpenses();
    };
  }, [trialId]);

  return (
    <WithCommunication communication={communication} data={{expenses, userPermissions}}>
      {({expenses, userPermissions}) => (
        <Expenses
          expenses={expenses}
          userPermissions={userPermissions}
          addExpenses={addExpenses}
          addDisabled={addCommunication.type === 'requesting'}
          deleteExpenses={deleteExpenses}
          deleteDisabled={deleteCommunication.type === 'requesting'}
          trialId={trialId}
          markExpenseAsComplete={markExpenseAsComplete}
        />
      )}
    </WithCommunication>
  );
});

type ExpensesProps = {
  expenses: ServerExpenses[];
  userPermissions: Permissions;
  addExpenses(value: ServerExpenseValue): void;
  addDisabled: boolean;
  deleteExpenses(expenseId: number): void;
  deleteDisabled: boolean;
  markExpenseAsComplete(expenseId: number, complete: boolean): void;
  trialId: number;
};

const Expenses = observer((props: ExpensesProps) => {
  const {
    addExpenses,
    addDisabled,
    deleteExpenses,
    deleteDisabled,
    expenses,
    userPermissions,
    trialId,
    markExpenseAsComplete,
  } = props;

  const canAddExpenses = userPermissions.includes('create');

  const theme = useTheme();

  const handleSend = useCallback((value: ServerExpenseValue) => addExpenses(value), [addExpenses]);

  const onDownload = useCallback(() => downloadFromUrl(`/trial/expenses/export/${trialId}`), [trialId]);

  return (
    <Stack direction={'column'} spacing={2}>
      {canAddExpenses && (
        <Stack width={400} mb="20px" gap={1}>
          <TrialTitle title="Expense form" />
          <TrialExpensesAddForm onSend={handleSend} isLoading={addDisabled} />
        </Stack>
      )}
      <Stack direction="row" alignItems="center" spacing={3}>
        <TrialTitle title="Expense documents" />
        {expenses.length > 0 && (
          <Button height={24} size="small" color="info" variant="text" backgroundColor="rgba(9, 30, 66, 0.04)">
            <Stack direction="row" alignItems="center" spacing={0} onClick={onDownload}>
              <DownloadIcon style={{height: 16, color: theme.palette.primary.main}} />
              <Typography style={{lineHeight: 0}}>Download all expenses as excel file</Typography>
            </Stack>
          </Button>
        )}
      </Stack>
      <Table
        expenses={expenses}
        deleteExpenses={deleteExpenses}
        deleteDisabled={deleteDisabled}
        markExpenseAsComplete={markExpenseAsComplete}
      />
    </Stack>
  );
});

const Table = observer(
  ({
    expenses,
    deleteExpenses,
    deleteDisabled,
    markExpenseAsComplete,
  }: Pick<ExpensesProps, 'expenses' | 'deleteExpenses' | 'deleteDisabled' | 'markExpenseAsComplete'>) => {
    const canDeleteExpenses = STORE_TRIAL_EXPENSES.userPermissions.includes('delete');
    const canEditExpenses = STORE_TRIAL_EXPENSES.userPermissions.includes('update');

    const columns: Array<DataGridColumn<ServerExpenses>> = [
      {
        renderHeader: () => <TrialTitle title="Subject" size={14} />,
        field: 'Subject',
        flex: 2,
        renderCell: (params) => <CellRendererString value={params.row.subject} />,
      },
      {
        renderHeader: () => <TrialTitle title="Type" size={14} />,
        field: 'Type',
        flex: 1,
        renderCell: (params) => <CellRendererString value={mapExpenseTypeToLabel[params.row.type]} />,
      },
      {
        renderHeader: () => <TrialTitle title="Date" size={14} />,
        field: 'Date',
        flex: 1,
        renderCell: (params) => <CellRendererDate value={params.row.date} />,
      },
      {
        renderHeader: () => <TrialTitle title="Complete" size={14} />,
        field: 'Complete',
        flex: 1,
        renderCell: (params) => (
          <CellRendererCheckbox
            readonly={!canEditExpenses}
            value={params.row.complete}
            onChange={(value) => markExpenseAsComplete(Number(params.row.id), value)}
          />
        ),
      },
      ...(canEditExpenses
        ? [
            {
              renderHeader: () => <TrialTitle title="Download receipt" size={14} />,
              field: 'Download receipt',
              flex: 1,
              renderCell: (params: RenderCellParams<ServerExpenses>) => (
                <CellRendererDownload url={params.row.receipt_url} />
              ),
            },
          ]
        : []),
      ...(canDeleteExpenses
        ? [
            {
              renderHeader: () => <TrialTitle title="Delete expense" size={14} />,
              field: 'Delete expense',
              flex: 1,
              renderCell: (params: RenderCellParams<ServerExpenses>) => (
                <DeleteIconButton
                  onClick={() => {
                    deleteExpenses(Number(params.row.id));
                  }}
                  disabled={deleteDisabled}
                />
              ),
            },
          ]
        : []),
    ];

    return <DataGridWithTypes rows={expenses} columns={columns} />;
  }
);
