import React, {memo} from 'react';
import {Field, FieldConfig, FieldProps} from 'formik';
import Combobox, {ComboboxProps} from 'components/base/Combobox/Combobox';
import {SelectOption} from 'components/base/Select/Select';

type AllProps<T> = FieldConfig & ComboboxProps<T>;
function ComboboxField<T>(props: AllProps<T>) {
  return (
    <Field {...props}>
      {({meta, form}: FieldProps<SelectOption<T>>) => (
        <Combobox
          {...props}
          onChange={(item) => form.setFieldValue(props.name, item?.value)}
          errorMessage={meta.error}
        />
      )}
    </Field>
  );
}

export default memo(ComboboxField);
