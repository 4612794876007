import {ReactComponent as MagnifierSVG} from 'components/base/Search/icons/magnifier.svg';
import {ChangeEvent} from 'react';
import TextField from '@mui/material/TextField';
import {makeStyles} from '@material-ui/styles';

type Props = {
  value: string;
  placeholder: string;
  onChange(value: string): void;
};

const useStyles = makeStyles(() => ({
  anotherBorder: {
    border: '1px solid #DFE1E6',
  },
}));

export const Search = (props: Props) => {
  const classes = useStyles();

  const handleChange = (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    props.onChange(value.currentTarget.value);
  };
  return (
    <TextField
      variant="outlined"
      label={props.placeholder}
      id={props.placeholder}
      size={'small'}
      InputProps={{
        endAdornment: <MagnifierSVG />,
        classes: {notchedOutline: classes.anotherBorder},
        style: {background: '#FFF'},
      }}
      value={props.value}
      onChange={handleChange}
    />
  );
};
