import TrialContentLayout from 'components/TrialContent/TrialContentLayout';
import {ServerVendor} from 'services/types/ServerVendor';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TrialDetails from 'components/TrialContent/trial/details/TrialDetails';
import {TrialSharedDrive} from 'components/TrialContent/trial/drive/TrialSharedDrive';
import {TrialExpenses} from 'components/TrialContent/trial/expenses/TrialExpenses';
import React, {memo, useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Stack from '@mui/material/Stack';
import {TrialStatus} from 'components/TrialsList/TrialStatus/TrialStatus';
import styles from 'components/TrialContent/trial/Trial.module.scss';
import useAzureRole from 'helpers/hooks/useAzureRole';
import Button from 'components/base/Button/Button';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';
import {ServerTrial} from 'services/types/ServerTrial';
import {STORE_TRIAL} from 'store/StoreTrial';
import {EditIconButton} from 'components/base/Button/EditIconButton';
import {ChangeSimpleFieldForm} from 'components/base/Form/ChangeSimpleFieldForm';
import {observer} from 'mobx-react-lite';
import {usePopover} from 'components/base/Popover/usePopover';
import Popover from 'components/base/Popover/Popover';
import TrialUsers from 'components/TrialContent/trial/users/TrialUsers';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useDeleteTrialModal} from 'components/TrialContent/trial/useDeleteTrialModal';
import {ServerTrialStatus} from 'services/types/ServerTrialStatus';
import ChangeTrialStatusForm from 'components/TrialsList/TrialStatus/ChangeTrialStatusForm';
import {useOnSuccessCommunication} from 'helpers/hooks/useOnSuccessCommunication';
import validateNotEmpty from 'validators/validateNotEmpty';
import {TrialSites} from './sites/TrialSites';
import {TrialEquipment} from './equipment/TrialEquipment';
import PIGuide from './guide/PIGuide';
import {MenuItem, MenuList} from '@mui/material';
import TypographyWrapper from 'components/base/Typography/TypographyWrapper';
import MonitoringVisits from './visits/MonitoringVisits';

interface Props {
  trial: ServerTrial;
  vendors: ServerVendor[];
  tab: 'details' | 'shared-drive' | 'expenses' | 'users' | 'sites' | 'equipment' | 'guide' | 'monitoring-visits';
}

// for unknown reasons override in MainMuiTheme doesn't work
const DISABLE_TEXT_TRANSFORM = {textTransform: 'none'};

const Trial = ({tab, trial, vendors}: Props) => {
  const [activeTab, setActiveTab] = useState<Props['tab']>(tab);
  const navigate = useNavigate();

  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  const {isAdmin} = useAzureRole();
  const isPi = trial.user_trial_role === 'trial.pi' && !isAdmin;

  const request = STORE_TRIAL.changeTrial;
  const communication = STORE_TRIAL.changeTrialCommunication;
  const isLoading = communication.type === 'requesting';
  const canEdit = STORE_TRIAL.canEditTrial;

  useOnErrorCommunication(communication);

  const handleChange = useCallback(
    (_: unknown, newValue: Props['tab']) => {
      setActiveTab(newValue);
      navigate(`../${newValue}`);
    },
    [setActiveTab, navigate]
  );

  const changeTrialHandler = useCallback(() => {
    request({...trial, is_published: true});
  }, [trial, request]);

  const {onClose, onOpen, popoverProps} = usePopover();
  const {onClose: onClose2, onOpen: onOpen2, popoverProps: popoverProps2} = usePopover();
  const {onClose: onClose3, onOpen: onOpen3, popoverProps: popoverProps3} = usePopover();

  useOnSuccessCommunication(communication, () => {
    onClose2();
    onClose3();
  });

  const changeTrialName = useCallback(
    ({name}: {name: string}) => {
      trial &&
        request({
          ...trial,
          name,
        });
    },
    [trial, request]
  );

  const onChangeTrialStatus = useCallback(
    (status: ServerTrialStatus) => trial && request({...trial, status}),
    [trial, request]
  );

  const onChangeArchivedStatus = useCallback(
    (isArchived: boolean) => trial && STORE_TRIAL.changeArchiveStatus(trial.id, isArchived),
    [trial]
  );

  const navigateToTrials = useCallback(() => navigate('/trials'), [navigate]);

  const {onOpenDeleteModal, deleteModal} = useDeleteTrialModal({trialId: trial.id, onSuccess: navigateToTrials});

  return (
    <>
      {deleteModal}

      <Popover {...popoverProps} horizontal="left" width={720}>
        <ChangeSimpleFieldForm
          title=" "
          onClose={onClose}
          onSubmit={changeTrialName}
          onSuccess={onClose}
          data={trial}
          name="name"
          validate={validateNotEmpty}
          communication={communication}
        />
      </Popover>

      <Popover
        {...popoverProps2}
        isLoading={isLoading}
        width={300}
        onClose={onClose2}
        horizontal="left"
        sx={{
          paper: {
            '& .MuiDialogContent-root': {
              padding: '5px important',
            },
          },
        }}
      >
        <MenuList>
          <MenuItem
            className={styles.item}
            onClick={() => {
              onChangeArchivedStatus(!trial.is_archived);
              onClose2();
            }}
          >
            <Stack gap={1} direction="row" alignItems="center">
              {trial.is_archived ? (
                <>
                  <UnarchiveOutlinedIcon />
                  <TypographyWrapper value="Unarchive trial" />
                </>
              ) : (
                <>
                  <ArchiveOutlinedIcon />
                  <TypographyWrapper value="Archive trial" />
                </>
              )}
            </Stack>
          </MenuItem>
          {isAdmin && (
            <MenuItem onClick={onOpenDeleteModal} className={styles.item}>
              <Stack gap={1} direction="row" alignItems="center">
                <DeleteOutlineIcon />
                <TypographyWrapper value="Delete trial" />
              </Stack>
            </MenuItem>
          )}
        </MenuList>
      </Popover>

      <Popover {...popoverProps3} isLoading={isLoading} onClose={onClose3} horizontal="left">
        <ChangeTrialStatusForm
          currentStatus={trial.status || ServerTrialStatus.UNKNOWN}
          onChangeStatus={onChangeTrialStatus}
        />
      </Popover>

      <TrialContentLayout vendors={vendors} trial={trial}>
        <Box sx={{pt: 5, pl: 6, pr: 6, maxWidth: '1440px'}}>
          <Stack direction="column">
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" alignItems="center" spacing={2}>
                <div className={styles.header}>{trial.name}</div>
                <Stack direction={isLg ? 'row' : 'column-reverse'} spacing={3.5}>
                  {canEdit && <EditIconButton onClick={onOpen} />}
                  <TrialStatus onClick={onOpen3} status={trial.status} isDisplayMode={!canEdit} />
                  {trial.is_archived && (
                    <Box
                      borderColor={theme.palette.text.disabled}
                      borderRadius={43}
                      padding="4px 10px"
                      border={1}
                      fontSize={12}
                      color={theme.palette.text.disabled}
                      width="fit-content"
                    >
                      Archived
                    </Box>
                  )}
                </Stack>
              </Stack>
              <Stack direction={isLg ? 'row' : 'column'} spacing={2} alignItems="center" justifyContent="center">
                {isAdmin && !trial?.is_published && <Button onClick={changeTrialHandler}>Publish trial</Button>}
                {canEdit && (
                  <MoreVertIcon
                    style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                    onClick={(event: never) => onOpen2(event)}
                  />
                )}
              </Stack>
            </Stack>
            <Box>
              <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs
                  sx={{maxWidth: '100%'}}
                  value={activeTab}
                  onChange={handleChange}
                  aria-label="Trial tabs"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab sx={DISABLE_TEXT_TRANSFORM} label="Trial details" value="details" />
                  <Tab sx={DISABLE_TEXT_TRANSFORM} label="Shared drive" value="shared-drive" />
                  <Tab sx={DISABLE_TEXT_TRANSFORM} label="PI Guide" value="guide" />
                  {!isPi && <Tab sx={DISABLE_TEXT_TRANSFORM} label="Expenses" value="expenses" />}
                  {!isPi && <Tab sx={DISABLE_TEXT_TRANSFORM} label="Monitoring visits" value="monitoring-visits" />}
                  <Tab sx={DISABLE_TEXT_TRANSFORM} label="Users" value="users" />
                  <Tab sx={DISABLE_TEXT_TRANSFORM} label="Sites" value="sites" />
                  <Tab sx={DISABLE_TEXT_TRANSFORM} label="Equipment" value="equipment" />
                </Tabs>
              </Box>
              <TabPanel activeTab={activeTab} tab="details">
                <TrialDetails trialId={trial.id} />
              </TabPanel>
              <TabPanel activeTab={activeTab} tab="shared-drive">
                <TrialSharedDrive trialId={trial.id} />
              </TabPanel>
              <TabPanel activeTab={activeTab} tab="guide">
                <PIGuide />
              </TabPanel>
              {!isPi && (
                <>
                  <TabPanel activeTab={activeTab} tab="expenses">
                    <TrialExpenses trialId={trial.id} />
                  </TabPanel>
                  <TabPanel activeTab={activeTab} tab="monitoring-visits">
                    <MonitoringVisits trialId={trial.id} />
                  </TabPanel>
                </>
              )}
              <TabPanel activeTab={activeTab} tab="users">
                <TrialUsers trialId={trial.id} />
              </TabPanel>
              <TabPanel activeTab={activeTab} tab="sites">
                <TrialSites trialId={trial.id} />
              </TabPanel>
              <TabPanel activeTab={activeTab} tab="equipment">
                <TrialEquipment trialId={trial.id} />
              </TabPanel>
            </Box>
          </Stack>
        </Box>
      </TrialContentLayout>
    </>
  );
};

interface TabPanelProps {
  tab: Props['tab'];
  activeTab: Props['tab'];
  children: JSX.Element;
}

function TabPanel(props: TabPanelProps) {
  const {children, activeTab, tab, ...other} = props;

  return (
    <div {...other} role="tabpanel" hidden={activeTab !== tab} id={tab} aria-labelledby={tab}>
      {activeTab === tab && <Box sx={{pt: 3, pb: 3}}>{children}</Box>}
    </div>
  );
}

export default memo(observer(Trial));
