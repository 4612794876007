import Box from '@mui/material/Box';
import {ServerVendor} from 'services/types/ServerVendor';
import Stack from '@mui/material/Stack';
import {LogoSmall} from 'components/complex/Logo/LogoSmall';
import {TrialCard} from 'components/Vendors/TrialCard';
import {memo, useCallback, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import VendorsCellsList from 'components/Vendors/VendorsCellsList';
import Button from 'components/base/Button/Button';
import {useDialog} from 'components/base/Dialog/useDialog';
import Dialog from 'components/base/Dialog/Dialog';
import EditVendorsForm from 'components/Vendors/EditVendorsForm/EditVendorsForm';
import {useOnSuccessCommunication} from 'helpers/hooks/useOnSuccessCommunication';
import {observer} from 'mobx-react-lite';
import {STORE_TRIAL} from 'store/StoreTrial';
import useIsElectronApp from 'helpers/hooks/useIsElectronApp';

type Props = {
  trial: {
    id: number;
    name: string;
  };
  vendors: ServerVendor[];
};

const Vendors = (props: Props) => {
  const {isElectronApp} = useIsElectronApp();
  const {vendors, trial} = props;
  const {putTrialVendors, changeTrialVendorsCommunication} = STORE_TRIAL;
  const canEdit = STORE_TRIAL.canEditTrial;

  const navigate = useNavigate();
  const {vendorId} = useParams();
  const vendorNumberID = vendorId === undefined || Number.isNaN(Number(vendorId)) ? undefined : Number(vendorId);
  const [trialSelected, setTrialSelected] = useState(vendorNumberID === undefined);
  const [selectedId, setSelectedId] = useState<number | undefined>(vendorNumberID);
  const handleTrialClick = () => {
    setTrialSelected(true);
    setSelectedId(undefined);
    navigate('../details');
  };

  const handleVendorClick = useCallback(
    (id: number) => {
      if (!isElectronApp) {
        const url = vendors?.find((vendor) => vendor.id === id)?.url;
        if (url) window.open(url, '_blank');
      }
      setTrialSelected(false);
      setSelectedId(id);
      navigate(`../vendor/${id}`);
    },
    [
      navigate, 
      vendors,
      isElectronApp,
    ]
  );

  const {onClose, isOpen, onOpen} = useDialog();

  const onSubmitHandler = useCallback(
    (vendors: ServerVendor[]) => putTrialVendors(trial.id, vendors),
    [putTrialVendors, trial.id]
  );

  useOnSuccessCommunication(changeTrialVendorsCommunication, onClose);

  return (
    <Box sx={{width: 220, background: '#F6F8FF'}}>
      <Dialog isOpen={isOpen}>
        <EditVendorsForm onClose={onClose} currentVendors={vendors} onSubmit={onSubmitHandler} />
      </Dialog>

      <Stack direction={'column'} spacing={3} justifyContent={'flex-end'} alignItems={'flex-end'}>
        <Box sx={{width: 180}}>
          <LogoSmall />
        </Box>
        <Stack direction={'column'} spacing={1} justifyContent={'flex-end'} alignItems={'flex-end'}>
          <TrialCard trialName={trial.name} onClick={handleTrialClick} selected={trialSelected} />
          <VendorsCellsList
            vendors={vendors}
            handleVendorClick={handleVendorClick}
            selectedId={selectedId}
            deselectAll={trialSelected}
          />
          {canEdit && (
            <Box alignSelf="center">
              <Button variant="outlined" onClick={onOpen}>
                Edit vendors
              </Button>
            </Box>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default memo(observer(Vendors));
