import MUIAvatar from '@mui/material/Avatar';
import {useMemo} from 'react';
import {ServerUser} from 'services/types/ServerUser';

interface Props {
  user: Pick<ServerUser, 'first_name' | 'last_name' | 'display_name'>;
  size: number;
}

const getInitials = ({user: {first_name, last_name, display_name}}: Omit<Props, 'size'>): string => {
  const [displayedFirstName, displayedLastName] = display_name ? display_name.split(' ') : [];
  const displayNameInitials =
    displayedFirstName && displayedLastName ? [displayedFirstName, displayedLastName] : [display_name];

  const initialsFrom = first_name && last_name ? [first_name, last_name] : displayNameInitials;

  const initials = initialsFrom
    .map((s) => (s ? s[0] : ''))
    .slice(0, 2)
    .join('');
  return initials !== '' ? initials : '?';
};

function nameToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export const AvatarUnknown = ({user, size}: Props) => {
  const {last_name, first_name, display_name} = user;
  const name = first_name && last_name ? `${first_name} ${last_name}` : display_name || '';
  const initials = useMemo(() => getInitials({user}), [user]);
  return (
    <div style={{height: size}}>
      <MUIAvatar
        alt={name}
        // todo change font size correctly
        sx={{
          width: size,
          height: size,
          backgroundColor: nameToColor(name),
          fontSize: size === 28 ? 16 : 14,
        }}
      >
        {initials}
      </MUIAvatar>
    </div>
  );
};
