import {useCallback, useMemo, useState} from 'react';
import {ServerTrialBase} from 'services/types/ServerTrialBase';
import {Search} from 'components/base/Search/Search';

export default function useNameFilter() {
  const [nameFilterValue, setNameFilterValue] = useState('');

  const nameFilter = useCallback(
    (item: ServerTrialBase) => item.name.toLowerCase().includes(nameFilterValue.toLowerCase()),
    [nameFilterValue]
  );

  const nameComponent = useMemo(
    () => <Search placeholder={'Search trials'} value={nameFilterValue} onChange={setNameFilterValue} />,
    [nameFilterValue]
  );

  return {nameFilter, nameComponent};
}
