import {ReactComponent as SharedDriveLinkSVG} from 'components/TrialContent/trial/drive/icons/shared_drive_link.svg';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import styles from './SharedDrive.module.scss';
import {ServerSharedLink, ServerSharedLinks} from 'services/types/ServerSharedLinks';
import {formatDate} from 'components/base/utils/formatDate';
import LinkIcon from '@mui/icons-material/Link';
import IconButton from '@mui/material/IconButton';
import AddInfoButton from 'components/base/Button/AddInfoButton';
import {FC, memo, SVGProps, useCallback, useMemo} from 'react';
import {ChangeSimpleFieldForm} from 'components/base/Form/ChangeSimpleFieldForm';
import {STORE_TRIAL_LINKS} from 'store/StoreTrialLinks';
import {observer} from 'mobx-react-lite';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';
import {STORE_TRIAL} from 'store/StoreTrial';
import {usePopover} from 'components/base/Popover/usePopover';
import Popover from 'components/base/Popover/Popover';
import {ReactComponent as DeleteLinkIcon} from 'components/TrialContent/trial/drive/icons/delete_link.svg';

interface Props {
  name: keyof ServerSharedLinks;
  title: string;
  data?: ServerSharedLink;
  customIcon?: FC<SVGProps<SVGSVGElement>>;
}

const TrialSharedLink = ({title, data, name, customIcon}: Props) => {
  const canEdit = STORE_TRIAL.canEditTrial;
  const request = STORE_TRIAL_LINKS.changeTrialLinks;
  const communication = STORE_TRIAL_LINKS.changeLinksCommunication;

  useOnErrorCommunication(communication);

  const {onClose, onOpen, popoverProps} = usePopover();

  const renderIcon = useMemo(() => {
    const ComponentSvg = customIcon || SharedDriveLinkSVG;
    return data?.url ? <ComponentSvg fill="#769E98" /> : <ComponentSvg />;
  }, [data?.url, customIcon]);

  const renderButton = useMemo(() => {
    if (data?.url)
      return (
        <IconButton onClick={onOpen} sx={{color: '#769E98'}}>
          <LinkIcon />
        </IconButton>
      );

    if (canEdit) return <AddInfoButton onClick={onOpen} />;
    return null;
  }, [data?.url, onOpen, canEdit]);

  const initial = useMemo(() => ({url: '', date: new Date()}), []);
  const initialValues: ServerSharedLinks = useMemo(
    () => ({
      [name]: {url: data?.url || initial.url, date: data?.date || initial.date},
    }),
    [data, initial, name]
  );

  const onSubmit = useCallback(
    (values: ServerSharedLinks) => {
      request(STORE_TRIAL._trial?.id || 0, {...STORE_TRIAL_LINKS._links, ...values});
    },
    [request]
  );

  const onDelete = useCallback(() => {
    onSubmit({...initialValues, [name]: initial});
  }, [onSubmit, initial, initialValues, name]);

  return (
    <Grid container>
      {canEdit && (
        <Popover {...popoverProps} horizontal="left" width="500px">
          <ChangeSimpleFieldForm
            title=" "
            onClose={onClose}
            name={`${name}.url`}
            data={initialValues}
            communication={communication}
            onSuccess={onClose}
            onSubmit={onSubmit}
            customButton={
              data?.url ? (
                <Stack onClick={onDelete} className={styles.removeLinkButton} direction="row" alignItems="center">
                  <DeleteLinkIcon />
                  <div>Remove link</div>
                </Stack>
              ) : undefined
            }
          />
        </Popover>
      )}
      <Grid item xs>
        <Stack direction="row" alignItems="center">
          <div className={styles.linkIcon}>{renderIcon}</div>
          <Stack>
            <span className={styles.linkTitle}>{title}</span>
            <Link underline="hover" target="_blank" href={data?.url} className={styles.linkUrl}>
              {data?.url}
            </Link>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={false} alignSelf="center">
        <Box width={200} className={styles.linkDate}>
          {data?.date ? formatDate(new Date(data.date), false) : '-'}
        </Box>
      </Grid>
      {canEdit && (
        <Grid alignSelf="center">
          <Box display="flex" justifyContent="center" width={200} className={styles.linkDate}>
            {renderButton}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(observer(TrialSharedLink));
