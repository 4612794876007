import {FieldConfig, Field, FieldProps} from 'formik';
import {DropZone, DropZoneProps} from 'components/base/DropZone/DropZone';

type Props = FieldConfig & Omit<DropZoneProps, 'onChange'>;

export default function DropZoneField(props: Props) {
  return (
    <Field {...props}>
      {({field, meta, form}: FieldProps<string>) => (
        <DropZone
          {...props}
          onChange={(v) => form.setFieldValue(field.name, v)}
          isEmpty={!field.value}
          errorMessage={meta.touched ? meta.error : undefined}
        />
      )}
    </Field>
  );
}
