import React, {memo, useMemo} from 'react';
import {Box, Stack} from '@mui/material';
import Title from 'components/base/Title/Title';
import AddInfoButton from 'components/base/Button/AddInfoButton';
import {EditIconButton} from 'components/base/Button/EditIconButton';

interface IProps {
  isEditingMode: boolean;
  enableEditingMode(): void;
}

const HeaderSection = (props: IProps) => {
  const {isEditingMode, enableEditingMode} = props;

  const renderEditButton = useMemo(
    () => (
      <Stack direction="row">
        <Box mr={1}>
          <EditIconButton onClick={(event) => event.preventDefault()} />
        </Box>
        Edit vendor list
      </Stack>
    ),
    []
  );

  return (
    <Stack direction="row" justifyContent="space-between">
      <Title title="Select trial-specific vendors" />
      {!isEditingMode && <AddInfoButton title={renderEditButton} onClick={enableEditingMode} />}
    </Stack>
  );
};

export default memo(HeaderSection);
