import {Form, Formik} from 'formik';
import {useCallback} from 'react';
import FormButtons from 'components/base/Button/FormButton/FormButtons';
import {Box} from '@mui/material';
import {ServerEquipment} from 'services/types/ServerEquipment';
import CreatableField from 'components/base/Combobox/CreatableField';
import {CustomSelectOption} from 'components/base/Combobox/Creatable';
import validateNotEmpty from 'validators/validateNotEmpty';

type EquipmentFormValues = {
  equipment: CustomSelectOption;
};

type Props = {
  onClose?: () => void;
  availableEquipment: Omit<ServerEquipment, 'in_use'>[];
  onAssignEquipment: (equipmentId: number, equipmentName?: string) => void;
  onAddNewEquipment: (equipmentName: string) => void;
  onSuccessAddNewEquipment?: (equipment: ServerEquipment) => void;
  isLoading?: boolean;
};

const initialValues = {
  equipment: {label: '', value: ''},
};

export const EquipmentAddRequiredForm = ({
  onClose,
  availableEquipment,
  onAssignEquipment,
  onAddNewEquipment,
  isLoading,
}: Props) => {
  const onSubmit = useCallback(
    (data: EquipmentFormValues) => {
      if (data.equipment.custom) {
        onAddNewEquipment(data.equipment.value);
      } else {
        onAssignEquipment(Number(data.equipment.value), data.equipment.label);
      }
      if (onClose) {
        onClose();
      }
    },
    [onAddNewEquipment, onAssignEquipment, onClose]
  );

  return (
    <Box width={450} maxWidth="100%">
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({handleSubmit, setFieldTouched, values}) => {
          return (
            <Form onSubmit={handleSubmit}>
              <CreatableField
                name="equipment"
                options={availableEquipment.map((equipment) => ({
                  label: equipment.name,
                  value: equipment.id.toString(),
                }))}
                validate={(equipment) => validateNotEmpty(equipment?.value)}
                onBlur={() => setFieldTouched('equipment', true)}
                loading={isLoading}
              />
              <FormButtons onClose={onClose} isDisabled={!values?.equipment?.value || isLoading} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default EquipmentAddRequiredForm;
