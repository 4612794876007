import {useCallback, useEffect} from 'react';
import WithCommunication from 'components/base/WithCommunication/WithCommunication';
import {observer} from 'mobx-react-lite';
import {STORE_TRIAL_EQUIPMENT} from 'store/StoreTrialEquipment';
import Popover from 'components/base/Popover/Popover';
import {usePopover} from 'components/base/Popover/usePopover';
import AddInfoButton from 'components/base/Button/AddInfoButton';
import EquipmentAddRequiredForm from 'components/complex/EquipmentAddRequiredForm/EquipmentAddRequiredForm';
import {useRequestMutation} from 'services/useRequestMutation';
import {EquipmentService} from 'services/EquipmentService';
import {ServerEquipment} from 'services/types/ServerEquipment';
import {EquipmentTrialTable} from 'components/complex/EquipmentTrialTable/EquipmentTrialTable';
import TypographyWrapper from 'components/base/Typography/TypographyWrapper';
import {Box, useTheme} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {useDialog} from 'components/base/Dialog/useDialog';
import Dialog from 'components/base/Dialog/Dialog';
import isNil from 'lodash/isNil';

interface Props {
  trialId: number;
}

export const TrialEquipment = observer(({trialId}: Props) => {
  const theme = useTheme();
  const {request: createNewEquipment} = useRequestMutation<string, ServerEquipment>(
    EquipmentService.createEquipment,
    (result) => {
      if (result) onCreateNewEquipment(result);
      if (onClose) onClose();
    }
  );

  const {popoverProps, onClose, onOpen} = usePopover();
  const {
    isOpen: isOpenDeleteConfirmation,
    onOpen: onOpenDeleteConfirmation,
    onClose: onCloseDeleteConfirmation,
    data: deleteDialogData,
  } = useDialog<number>();

  const equipment = STORE_TRIAL_EQUIPMENT._equipment;
  const availableEquipment = STORE_TRIAL_EQUIPMENT.availableEquipment;

  const communication = STORE_TRIAL_EQUIPMENT.loadEquipmentCommunication;
  const loadAvailableEquipmentCommunication = STORE_TRIAL_EQUIPMENT.loadAvailableCommunication;
  const removeEquipmentCommunication = STORE_TRIAL_EQUIPMENT.removeEquipmentCommunication;

  const {canEditEquipment, canCreateEquipment, canDeleteEquipment, canListEquipment} = STORE_TRIAL_EQUIPMENT;

  const getEquipment = useCallback(() => {
    STORE_TRIAL_EQUIPMENT.loadEquipment(trialId);
  }, [trialId]);

  const getAvailableEquipment = useCallback(() => {
    STORE_TRIAL_EQUIPMENT.loadAvailableEquipment(trialId);
  }, [trialId]);

  const assignEquipmentToTrial = useCallback(
    (equipmentId: number) => {
      STORE_TRIAL_EQUIPMENT.assignEquipment(trialId, equipmentId);
    },
    [trialId]
  );

  const onCreateNewEquipment = useCallback(
    (equipment: ServerEquipment) => {
      assignEquipmentToTrial(equipment.id);
      STORE_TRIAL_EQUIPMENT.appendNewEquipment(equipment);
    },
    [assignEquipmentToTrial]
  );

  const onRemoveEquipment = useCallback(
    (equipmentId: number) => {
      STORE_TRIAL_EQUIPMENT.removeEquipment(equipmentId, trialId);
    },
    [trialId]
  );

  const onAddNoteToEquipmentType = useCallback((trialEquipmentId: number, note: string) => {
    STORE_TRIAL_EQUIPMENT.addNoteToEquipmentType(trialEquipmentId, note);
  }, []);

  const onAddNoteToRequiredEquipment = useCallback(
    (trialEquipmentId: number, note: string, siteId: number, siteEquipmentId?: number) => {
      STORE_TRIAL_EQUIPMENT.addNoteToRequiredEquipment(trialId, trialEquipmentId, note, siteId, siteEquipmentId);
    },
    [trialId]
  );

  useEffect(() => {
    if (canCreateEquipment) {
      getAvailableEquipment();
    }
    getEquipment();
    return () => {
      STORE_TRIAL_EQUIPMENT.onUnmountEquipment();
      STORE_TRIAL_EQUIPMENT.onUnmountCommunications();
    };
  }, [getEquipment, getAvailableEquipment, canCreateEquipment]);

  return (
    <>
      {canDeleteEquipment && (
        <Dialog
          cancel
          onConfirm={() => !isNil(deleteDialogData) && onRemoveEquipment(deleteDialogData)}
          title="Do you want to delete required equipment?"
          isOpen={isOpenDeleteConfirmation}
          onClose={onCloseDeleteConfirmation}
          withSpaceUnderTitle
          width={550}
        >
          <TypographyWrapper value="Are you sure you want to delete a required piece of equipment from this trial? This operation is irreversible." />
        </Dialog>
      )}
      {canCreateEquipment && (
        <Popover {...popoverProps} horizontal="right">
          <EquipmentAddRequiredForm
            onAddNewEquipment={createNewEquipment}
            availableEquipment={availableEquipment}
            onAssignEquipment={assignEquipmentToTrial}
            onSuccessAddNewEquipment={onCreateNewEquipment}
            onClose={onClose}
            isLoading={loadAvailableEquipmentCommunication.type === 'requesting'}
          />
        </Popover>
      )}
      <TypographyWrapper value="Equipment tracking" variant="subtitle1" style={{marginBottom: 10}} />
      <Box display="flex" alignItems="center" color={theme.palette.grey[600]} gap={1} sx={{mb: '10px'}}>
        <InfoOutlinedIcon />
        <TypographyWrapper value="Double-click a cell to add/edit note" style={{fontSize: 16, fontWeight: 500}} />
      </Box>
      {canListEquipment && (
        <WithCommunication communication={communication} data={equipment}>
          {(loadedEquipment) => (
            <>
              <EquipmentTrialTable
                trialId={trialId}
                editable={canEditEquipment}
                equipment={loadedEquipment}
                deleteEquipment={canDeleteEquipment ? onOpenDeleteConfirmation : undefined}
                deleteDisabled={removeEquipmentCommunication.type === 'requesting'}
                addNoteToRequiredEquipment={onAddNoteToRequiredEquipment}
                addNoteToEquipmentType={onAddNoteToEquipmentType}
              />
              {canCreateEquipment && <AddInfoButton title="+ Add required equipment" onClick={onOpen} />}
            </>
          )}
        </WithCommunication>
      )}
    </>
  );
});
