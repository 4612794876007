import {ServerVendor} from 'services/types/ServerVendor';
import Box from '@mui/material/Box';
import React, {useCallback, useMemo} from 'react';
import {SxProps} from '@mui/system';
import LogoWrapper from 'components/base/LogoWrapper/LogoWrapper';

type Props = {
  vendor: ServerVendor;
  onClick(id: number): void;
  selected: boolean;
  position?: 'top' | 'middle' | 'bottom';
};

export function VendorCard({vendor, selected, onClick, position}: Props) {
  const handleClick = useCallback(() => {
    onClick(vendor.id);
  }, [onClick, vendor.id]);

  const dependentStyles = useDependentStyles({selected, position});

  return (
    <Box sx={{cursor: 'pointer', ...dependentStyles}} onClick={handleClick}>
      <LogoWrapper url={vendor.logo} />
    </Box>
  );
}

function useDependentStyles({selected, position}: Pick<Props, 'selected' | 'position'>): SxProps {
  const borderRadius = useMemo(() => {
    const radius = '6px';
    const noRadius = '0';
    if (position === undefined) {
      return radius;
    }

    const top = position === 'top' ? radius : noRadius;
    const bottom = position === 'bottom' ? radius : noRadius;

    return `${top} ${top} ${bottom} ${bottom}`;
  }, [position]);
  return useMemo(
    () => ({
      borderRadius,
      borderColor: selected ? '#2c5f57' : '#E0E6F2',
      boxShadow: selected ? '0 4px 12px rgba(0, 0, 0, 0.08)' : undefined,
    }),
    [borderRadius, selected]
  );
}
