import React, {memo, useCallback, useMemo} from 'react';
import TextInputField from 'components/base/Input/TextInputField';
import validateNotEmpty from 'validators/validateNotEmpty';
import Stack from '@mui/material/Stack';
import {Form, Formik} from 'formik';
import validateEmail from 'validators/validateEmail';
import {ServerContact} from 'services/types/ServerContact';
import {STORE_TRIAL} from 'store/StoreTrial';
import {useOnSuccessCommunication} from 'helpers/hooks/useOnSuccessCommunication';
import {equals} from 'ramda';
import {observer} from 'mobx-react-lite';
import {JobTitle, ServerContacts} from 'services/types/ServerContacts';
import Title from 'components/base/Title/Title';
import FormButtons from 'components/base/Button/FormButton/FormButtons';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';

type IFormValues = ServerContact;
const initialValues: ServerContact = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
};

interface IProps {
  name: JobTitle;
  onSuccess?(): void;
  onClose?(): void;
}

const EditKeyStudyContactForm = observer((props: IProps) => {
  const {name, onSuccess, onClose} = props;

  const details = STORE_TRIAL._details;
  const communication = STORE_TRIAL.changeDetailsCommunication;
  const isLoading = communication.type === 'requesting';

  useOnErrorCommunication(communication);
  useOnSuccessCommunication(communication, () => onSuccess && onSuccess());

  const contacts = useMemo(() => details.contacts, [details.contacts]);

  if (!contacts) return null;

  const initialContact: IFormValues = useMemo(() => {
    if (!name) return initialValues;
    return {...contacts[name], name};
  }, [contacts, name]);

  const onSubmit = useCallback(
    (values: IFormValues) => {
      const newContact = {[name]: values};
      const newContacts: ServerContacts = {...contacts, ...newContact};

      STORE_TRIAL.changeDetails({
        ...details,
        contacts: newContacts,
      });
    },
    [name, contacts, details]
  );

  return (
    <div>
      <Formik initialValues={initialContact} onSubmit={onSubmit}>
        {({handleSubmit, isValid, values, initialValues}) => (
          <Form onSubmit={handleSubmit}>
            <Title title="" />
            <Stack direction="column" spacing={1}>
              <TextInputField name="first_name" placeholder="First name" validate={validateNotEmpty} />
              <TextInputField name="last_name" placeholder="Last name" />
              <TextInputField name="email" placeholder="Email" validate={validateEmail} />
              <TextInputField name="phone" placeholder="Phone" validate={validateNotEmpty} />
              <FormButtons
                isLoading={isLoading}
                isDisabled={!isValid || equals(values, initialValues)}
                onClose={onClose}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
});

export default memo(EditKeyStudyContactForm);
