import API from 'services/api';
import {MatrixEquipmentResponse, ServerEquipment} from './types/ServerEquipment';

const DEFAULT_PREFIX = 'equipment';

export class EquipmentService {
  public static async getEquipment(): Promise<ServerEquipment[]> {
    const result = await API.get(`${DEFAULT_PREFIX}/`);
    return result.data;
  }

  public static async createEquipment(name: string): Promise<ServerEquipment> {
    const result = await API.post(`${DEFAULT_PREFIX}/`, {name});
    return result.data;
  }

  public static async getEquipmentMatrix(): Promise<MatrixEquipmentResponse> {
    const result = await API.get(`${DEFAULT_PREFIX}/sites-matrix`);
    return result.data;
  }

  public static async deleteEquipmentType(equipmentId: number): Promise<void> {
    return await API.delete(`${DEFAULT_PREFIX}/${equipmentId}`);
  }

  public static async updateEquipmentType({
    equipmentId,
    name,
  }: {
    equipmentId: number;
    name: string;
  }): Promise<ServerEquipment> {
    const result = await API.put(`${DEFAULT_PREFIX}/${equipmentId}`, {name});
    return result.data;
  }
}
