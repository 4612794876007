import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {TrialTitle} from 'components/TrialContent/trial/details/TrialTitle';
import {memo, useCallback, useEffect, useMemo, useState, useRef} from 'react';
import FormGroup from '@mui/material/FormGroup';
import TextInput from 'components/base/Input/TextInput';
import {Checkbox} from 'components/base/Checkbox/Checkbox';
import {observer} from 'mobx-react-lite';
import {STORE_TRIAL} from 'store/StoreTrial';
import {DeleteIconButton} from 'components/base/Button/DeleteIconButton';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';
import Loading from 'components/base/WithCommunication/Loading';
import {useRequestData} from 'services/useRequestData';
import {AzureService} from 'services/AzureService';
import {ServerUser} from 'services/types/ServerUser';

const DailyChecklist = () => {
  const [inputValue, setInputValue] = useState('');
  const list = STORE_TRIAL._checklistItems;
  const communication = STORE_TRIAL.changeCheckListCommunication;
  const isLoading = communication.type === 'requesting';
  const trialId = STORE_TRIAL._trial?.id;
  const listRef = useRef<HTMLElement>();

  useOnErrorCommunication(communication);

  const {data: data, request: requestGet} = useRequestData(AzureService.getUsers);
  useEffect(() => requestGet(), [requestGet]);

  const renderLabelName = useCallback((user: ServerUser) => {
    const {last_name, first_name, email, display_name} = user;
    let labelName = '';

    if (first_name || last_name) {
      labelName += `${first_name} ${last_name}`;
    } else if (display_name) {
      labelName += display_name;
    }

    if (labelName.length !== 0) {
      if (email) labelName += ` (${email})`;
    } else labelName += email;

    return labelName;
  }, []);

  const allUsers: {id: string; display: string}[] = useMemo(
    () =>
      data
        ?.filter((i) => !!i.email)
        .map((user) => ({
          id: user.id,
          display: renderLabelName(user),
        })) || [],
    [data, renderLabelName]
  );

  const handleCheck = useCallback(
    (taskId: number, checked: boolean) => {
      if (trialId) {
        STORE_TRIAL.checkChecklistItem(trialId, taskId, checked);
      }
    },
    [trialId]
  );

  const handleDeleteItem = useCallback(
    (taskId: number) => {
      if (trialId) {
        STORE_TRIAL.deleteChecklistItem(trialId, taskId);
      }
    },
    [trialId]
  );

  const handleEnter = useCallback(() => {
    if (trialId) {
      STORE_TRIAL.addChecklistItem(trialId, inputValue, () => {
        setTimeout(() => {
          if (listRef?.current) {
            listRef.current.scrollTop = listRef?.current.scrollHeight;
          }
        }, 10);
      });
    }
    setInputValue('');
  }, [inputValue, trialId]);

  return (
    <Box>
      <Stack maxWidth={600} direction={'column'} spacing={1}>
        <TrialTitle title={'Daily checklist'} />
        {list.length === 0 && isLoading && <Loading />}
        {list.length > 0 && (
          <Stack
            ref={listRef}
            direction={'column'}
            spacing={1}
            sx={{
              maxHeight: 200,
              width: '100%',
              overflow: 'auto',
              maxWidth: 600,
            }}
          >
            <FormGroup>
              {list.map((item) => (
                <Stack key={item.id} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <Checkbox
                    value={item.checked}
                    lineThroughOnSelected={true}
                    onChange={(v) => {
                      handleCheck(item.id, v);
                    }}
                    label={`${item.text}`}
                    allUsers={allUsers}
                  />
                  <DeleteIconButton
                    onClick={() => {
                      handleDeleteItem(item.id);
                    }}
                    disabled={false}
                  />
                </Stack>
              ))}
            </FormGroup>
          </Stack>
        )}
        <TextInput
          type={'input'}
          value={inputValue}
          disabled={isLoading}
          onChange={setInputValue}
          onEnter={handleEnter}
          placeholder={'Add task'}
          isMentionAllowed={true}
          allUsers={allUsers}
        />
      </Stack>
    </Box>
  );
};

export default memo(observer(DailyChecklist));
