import Stack from '@mui/material/Stack';
import Vendors from 'components/Vendors/Vendors';
import {ServerTrial} from 'services/types/ServerTrial';
import Box from '@mui/material/Box';
import {ServerVendor} from 'services/types/ServerVendor';
import {memo} from 'react';
import {observer} from 'mobx-react-lite';

interface Props {
  vendors: ServerVendor[] | undefined;
  trial: ServerTrial;
  children: JSX.Element;
}

const SIDEBAR_WIDTH = '280px';
const NAVIGATION_BAR_WIDTH = '48px';

const TrialContentLayout = (props: Props) => {
  return (
    <Stack direction={'row'} sx={{width: '100%', height: '100vh', overflow: 'auto'}} spacing={0}>
      {props.vendors !== undefined ? (
        <Box
          sx={{
            minWidth: 280,
            width: 280,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 1,
            height: '100vh',
            overflowY: 'auto',
            overflowX: 'unset',
            borderRight: '1px solid #E0E6F2',
          }}
        >
          <Vendors vendors={props.vendors} trial={props.trial} />
        </Box>
      ) : null}
      <Box
        sx={{
          // Setting width for overflowing sticky table header
          width: `calc(100vw - ${SIDEBAR_WIDTH} - ${NAVIGATION_BAR_WIDTH})`,
          height: '100vh',
          overflow: 'auto',
          backgroundColor: '#FFF',
        }}
      >
        {props.children}
      </Box>
    </Stack>
  );
};

export default memo(observer(TrialContentLayout));
