import React, {memo, useCallback, useEffect, useState} from 'react';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';
import {FormikProvider, useFormik} from 'formik';
import {equals} from 'ramda';
import {ServerVendor} from 'services/types/ServerVendor';
import {useVendorGroups} from 'components/Vendors/EditVendorsForm/useVendorGroups';
import Loading from 'components/base/WithCommunication/Loading';
import {STORE_VENDOR} from 'store/StoreVendor';
import {observer} from 'mobx-react-lite';
import VendorGroupSection from 'components/Vendors/EditVendorsForm/parts/VendorGroupSection/VendorGroupSection';
import HeaderSection from 'components/Vendors/EditVendorsForm/parts/HeaderSection';
import FooterSection from 'components/Vendors/EditVendorsForm/parts/FooterSection';

interface IProps {
  type?: 'create' | 'edit';
  currentVendors: ServerVendor[];
  onSubmit(vendors: ServerVendor[]): void;
  onClose?(vendors: ServerVendor[]): void;
}

interface IFormValues {
  vendors: ServerVendor[];
}

const EditVendorsForm = (props: IProps) => {
  const {type = 'edit', currentVendors, onSubmit, onClose} = props;
  const {getVendors: request, _vendors: data, getVendorsCommunication: communication} = STORE_VENDOR;
  const isLoading = communication.type === 'requesting';

  const isEditMode = type === 'edit';
  const submitTitle = isEditMode ? 'Save' : 'Add trial';

  useEffect(() => request(), [request]);
  useOnErrorCommunication(communication);

  const initialValues: IFormValues = {vendors: currentVendors};
  const onSubmitHandler = useCallback((values: IFormValues) => onSubmit(values.vendors), [onSubmit]);
  const {optionalVendorGroups} = useVendorGroups(data);

  const formikProps = useFormik({initialValues, onSubmit: onSubmitHandler});
  const {values, isValid, handleSubmit} = formikProps;

  const [isVendorEditingMode, setIsVendorEditingMode] = useState<boolean>(false);
  const enableVendorEditingMode = useCallback(() => setIsVendorEditingMode(true), [setIsVendorEditingMode]);
  const disableVendorEditingMode = useCallback(() => setIsVendorEditingMode(false), [setIsVendorEditingMode]);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;

  return (
    <FormikProvider value={formikProps}>
      <form onSubmit={handleSubmit}>
        <HeaderSection isEditingMode={isVendorEditingMode} enableEditingMode={enableVendorEditingMode} />

        {optionalVendorGroups.map((group) => (
          <VendorGroupSection key={group.type} data={group} isEditingMode={isVendorEditingMode} />
        ))}

        <FooterSection
          isEditingMode={isVendorEditingMode}
          disableEditingMode={disableVendorEditingMode}
          submitTitle={submitTitle}
          isLoading={isLoading}
          isDisabled={!isValid || (isEditMode ? equals(values, initialValues) : false)}
          onClose={() => onClose && onClose(values.vendors)}
        />
      </form>
    </FormikProvider>
  );
};

export default memo(observer(EditVendorsForm));
