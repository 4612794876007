import {memo} from 'react';
import {Mention, MentionsInput, OnChangeHandlerFunc} from 'react-mentions';
import styles from './MentionTextsInputField.module.scss';
import * as React from 'react';

type Props = {
  value: string;
  onChange: OnChangeHandlerFunc | undefined;
  onKeyDown:
    | ((event: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>) => void)
    | undefined;
  placeholder?: string;
  data: {id: string; display: string}[];
};

const MentionTextInputField = (props: Props) => {
  const {value, onChange, onKeyDown, placeholder, data} = props;

  return (
    <MentionsInput
      singleLine={true}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      classNames={styles}
    >
      <Mention trigger="@" data={data} />
    </MentionsInput>
  );
};

export default memo(MentionTextInputField);
