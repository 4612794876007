import React, {memo, useCallback, useMemo, useState} from 'react';
import CalendarStaticDatePicker from 'components/base/DatePicker/CalendarStaticDatePicker';
import {STORE_TRIAL} from 'store/StoreTrial';
import {useOnSuccessCommunication} from 'helpers/hooks/useOnSuccessCommunication';
import {ServerDetails} from 'services/types/ServerDetails';
import {equals} from 'ramda';
import {observer} from 'mobx-react-lite';
import FormButtons from 'components/base/Button/FormButton/FormButtons';
import {useOnErrorCommunication} from 'helpers/hooks/useOnErrorCommunication';

interface IProps {
  name: keyof ServerDetails;
  onSuccess?(): void;
  onClose?(): void;
}

const ChangeTrialDetailDateForm = observer((props: IProps) => {
  const {onSuccess, onClose, name} = props;

  const details = STORE_TRIAL._details;
  const communication = STORE_TRIAL.changeDetailsCommunication;
  const isLoading = communication.type === 'requesting';

  const initialValues = useMemo(() => details[name] as string, [details, name]);

  const [selectedDate, setSelectedDate] = useState<string | null>(details[name] as string);

  useOnErrorCommunication(communication);
  useOnSuccessCommunication(communication, () => onSuccess && onSuccess());

  const onSubmit = useCallback(() => {
    STORE_TRIAL.changeDetails({
      ...details,
      [name]: selectedDate,
    });
  }, [details, selectedDate, name]);

  return (
    <>
      <CalendarStaticDatePicker
        value={selectedDate ? new Date(selectedDate) : null}
        onChange={(value) => setSelectedDate(value?.toISOString() || null)}
      />
      <FormButtons
        isLoading={isLoading}
        isDisabled={equals(selectedDate, initialValues)}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </>
  );
});

export default memo(ChangeTrialDetailDateForm);
