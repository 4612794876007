import {DataGridColumn} from 'components/base/Table/DataGridColumn';
import {ServerMonitoringVisit} from 'services/types/ServerMonitoringVisit';
import {TrialTitle} from '../details/TrialTitle';
import CellRendererString from 'components/base/Table/columns/CellRendererString';
import {DataGridWithTypes} from 'components/base/Table/DataGridWithTypes';
import {RenderCellParams} from 'components/base/Table/renderCell/RenderCellParams';
import CellRendererDownload from 'components/base/Table/columns/CellRendererDownload';
import {DeleteIconButton} from 'components/base/Button/DeleteIconButton';

type Props = {
  visits: ServerMonitoringVisit[];
  deleteDisabled?: boolean;
  onDelete?: (id: number) => void;
};

export const MonitoringVisitsTable = ({visits, onDelete, deleteDisabled}: Props) => {
  const columns: Array<DataGridColumn<ServerMonitoringVisit>> = [
    {
      renderHeader: () => <TrialTitle title="Name" size={14} />,
      field: 'Name',
      flex: 2,
      renderCell: (params) => <CellRendererString value={params.row.name} />,
    },
    {
      renderHeader: () => <TrialTitle title="Date" size={14} />,
      field: 'Date',
      flex: 1,
      renderCell: (params) => <CellRendererString value={params.row.date} />,
    },
    {
      renderHeader: () => <TrialTitle title="Length (hours)" size={14} />,
      field: 'Length',
      flex: 1,
      renderCell: (params) => <CellRendererString value={String(params.row.length)} />,
    },
    {
      renderHeader: () => <TrialTitle title="Monitor's Name" size={14} />,
      field: 'Complete',
      flex: 1,
      renderCell: (params) => <CellRendererString value={params.row.monitors_name} />,
    },
    {
      renderHeader: () => <TrialTitle title="Confirmation Letter" size={14} />,
      field: 'Download Confirmation Letter',
      flex: 1,
      renderCell: (params: RenderCellParams<ServerMonitoringVisit>) => (
        <CellRendererDownload url={params.row.confirmation_letter_url} />
      ),
    },
    {
      renderHeader: () => <TrialTitle title="Follow Up Letter" size={14} />,
      field: 'Download Follow Up Letter',
      flex: 1,
      renderCell: (params: RenderCellParams<ServerMonitoringVisit>) => (
        <CellRendererDownload url={params.row.follow_up_letter_url} />
      ),
    },
    ...(onDelete
      ? [
          {
            renderHeader: () => <TrialTitle title="Delete" size={14} />,
            field: 'Delete',
            flex: 1,
            renderCell: (params: RenderCellParams<ServerMonitoringVisit>) => (
              <DeleteIconButton
                onClick={() => {
                  onDelete(Number(params.row.id));
                }}
                disabled={!!deleteDisabled}
              />
            ),
          },
        ]
      : []),
  ];

  return <DataGridWithTypes rows={visits} columns={columns} />;
};
