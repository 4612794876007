export enum ServerTrialStatus {
  UNKNOWN = 0,
  EARLY_STAGE_PIPELINE = 1,
  SITE_QUALIFICATION = 2,
  SITE_ACTIVATION = 3,
  OPEN_TO_ENROLLMENT = 4,
  CLOSED_TO_ENROLLMENT = 5,
  CLOSED = 6,
  NOT_MOVING_FORWARD = 7,
}
