import {forwardRef} from 'react';
import {IMaskInput} from 'react-imask';
import {FieldConfig, Field, FieldProps} from 'formik';
import TextInput, {TextInputProps} from 'components/base/Input/TextInput';

type TextFieldProps = FieldConfig & Omit<TextInputProps, 'value' | 'onChange' | 'onBlur' | 'name' | 'meta'>;

interface CustomProps {
  onChange: (event: {target: {name: string; value: string}}) => void;
  name: string;
}

export const PostalCodeMask = forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(props, ref) {
  const {onChange, ...other} = props;

  return (
    <IMaskInput
      {...other}
      mask={/^[0-9-]*$/}
      inputRef={ref}
      onAccept={(value: any) => {
        onChange({target: {name: props.name, value}});
      }}
      overwrite
    />
  );
});

export default function PostalCodeInputField(props: TextFieldProps) {
  return (
    <Field {...props}>
      {({field, meta, form}: FieldProps<string>) => (
        <TextInput
          {...props}
          value={field.value}
          onChange={(v) => form.setFieldValue(field.name, v)}
          onBlur={field.onBlur}
          meta={meta}
          inputProps={{
            inputComponent: PostalCodeMask as any,
          }}
        />
      )}
    </Field>
  );
}
