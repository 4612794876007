import {memo, useMemo} from 'react';
import {ServerTrial} from 'services/types/ServerTrial';
import {Navigate, useRoutes} from 'react-router-dom';

import Trial from 'components/TrialContent/trial/Trial';
import Vendor from 'components/TrialContent/vendor/Vendor';
import {ServerVendor} from 'services/types/ServerVendor';
import useAzureRole from 'helpers/hooks/useAzureRole';
import AddSiteInTrial from './trial/sites/AddSiteInTrial';
import EditSiteInTrial from './trial/sites/EditSiteInTrial';

type Props = {
  trial: ServerTrial;
  vendors: ServerVendor[];
};

const TrialPageContent = ({trial, vendors}: Props) => {
  const {isAdmin} = useAzureRole();
  const isCrc = ['trial.crc', 'trial.lead'].includes(String(trial.user_trial_role));

  const commonRoutes = useMemo(
    () => [
      {
        path: '/',
        element: <Navigate to="../details" replace />,
      },
      {
        path: '/details',
        element: <Trial vendors={vendors} tab="details" trial={trial} />,
      },
      {
        path: '/shared-drive',
        element: <Trial vendors={vendors} tab="shared-drive" trial={trial} />,
      },
      {
        path: '/sites',
        element: <Trial vendors={vendors} tab="sites" trial={trial} />,
      },
      {
        path: '/vendor/:vendorId',
        element: <Vendor vendors={vendors} trial={trial} />,
      },
      {
        path: '/equipment',
        element: <Trial vendors={vendors} tab="equipment" trial={trial} />,
      },
      {
        path: '/guide',
        element: <Trial vendors={vendors} tab="guide" trial={trial} />,
      },
      {
        path: '/users',
        element: <Trial vendors={vendors} tab="users" trial={trial} />,
      },
    ],
    [trial, vendors]
  );

  const crcRoutes = useMemo(
    () => [
      ...commonRoutes,
      {
        path: '/expenses',
        element: <Trial vendors={vendors} tab="expenses" trial={trial} />,
      },
      {
        path: '/sites/new',
        element: <AddSiteInTrial vendors={vendors} trial={trial} />,
      },
      {
        path: '/sites/:siteId/edit',
        element: <EditSiteInTrial vendors={vendors} trial={trial} />,
      },
      {
        path: '/monitoring-visits',
        element: <Trial vendors={vendors} tab="monitoring-visits" trial={trial} />,
      },
    ],
    [commonRoutes, trial, vendors]
  );

  const getRoutes = () => {
    if (isCrc || isAdmin) return crcRoutes;
    return commonRoutes;
  };

  return useRoutes(getRoutes());
};

export default memo(TrialPageContent);
