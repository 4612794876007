import EquipmentContent from 'components/EquipmentContent/EquipmentContent';
import WithCommunication from 'components/base/WithCommunication/WithCommunication';
import {useEffect} from 'react';
import {EquipmentService} from 'services/EquipmentService';
import {useRequestData} from 'services/useRequestData';

const EquipmentPage = () => {
  const {
    data,
    communication,
    request: getEquipmentMatrix,
    refetch: refetchEquipment,
  } = useRequestData(EquipmentService.getEquipmentMatrix);

  useEffect(() => {
    getEquipmentMatrix();
  }, [getEquipmentMatrix]);

  return (
    <WithCommunication data={data} communication={communication}>
      {(loadedEquipment) => <EquipmentContent equipment={loadedEquipment} refetchEquipment={refetchEquipment} />}
    </WithCommunication>
  );
};

export default EquipmentPage;
