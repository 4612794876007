import {Stack, Typography} from '@mui/material';
import {ServerUser} from 'services/types/ServerUser';
import AvatarImage from 'components/base/Avatar/Avatar';
import {DeleteIconButton} from 'components/base/Button/DeleteIconButton';
import {DataGridColumn} from 'components/base/Table/DataGridColumn';
import {DataGridWithTypes} from 'components/base/Table/DataGridWithTypes';
import CellRendererString from 'components/base/Table/columns/CellRendererString';
import {ServerTrialUser} from 'services/types/ServerTrialUser';
import {mapUserRoleToLabel} from 'services/types/converters/getServerUserRoleLabel';
import {RenderCellParams} from 'components/base/Table/renderCell/RenderCellParams';
import {ServerUserRole} from 'services/types/ServerUserRole';

type UsersProps = {
  users: ServerTrialUser[] | ServerUser[];
  deleteUser(userId: string): void;
  deleteDisabled: boolean;
  readonly?: boolean;
};

export default function UserTable(props: UsersProps) {
  const {deleteUser, users, deleteDisabled, readonly} = props;
  const isTrialUsers = users.some((i) => 'role' in i);

  const mappedUsers: ServerTrialUser[] = isTrialUsers
    ? (users as ServerTrialUser[])
    : (users.map((i) => ({user: i, role: ServerUserRole.unknown})) as ServerTrialUser[]);

  const columns: Array<DataGridColumn<ServerTrialUser>> = [
    {
      renderHeader: () => <Typography variant="subtitle2">Name</Typography>,
      field: 'Name',
      flex: 2,
      renderCell: ({row: {user}}) => {
        return (
          <Stack width="100%" direction="row" spacing={1} alignItems="center">
            <AvatarImage user={user} />
            <CellRendererString
              value={user.first_name || user.last_name ? `${user.first_name} ${user.last_name}` : user.display_name}
            />
          </Stack>
        );
      },
    },
    {
      renderHeader: () => <Typography variant="subtitle2">Email</Typography>,
      field: 'Email',
      flex: 2,
      renderCell: ({row}) => <CellRendererString value={row.user.email} />,
    },
    ...(isTrialUsers
      ? [
          {
            renderHeader: () => <Typography variant="subtitle2">Role</Typography>,
            field: 'Role',
            flex: 1,
            renderCell: ({row}: RenderCellParams<ServerTrialUser>) => (
              <CellRendererString value={mapUserRoleToLabel[row.role]} />
            ),
          },
        ]
      : []),
    ...(readonly
      ? []
      : [
          {
            renderHeader: () => isTrialUsers && <Typography variant="subtitle2">Delete user</Typography>,
            field: 'Delete user',
            flex: 1,
            renderCell: ({row}: RenderCellParams<ServerTrialUser>) => (
              <DeleteIconButton
                onClick={() => {
                  deleteUser(row.user.id);
                }}
                disabled={deleteDisabled}
              />
            ),
          },
        ]),
  ];

  return <DataGridWithTypes rows={mappedUsers.map((i) => ({...i, id: i.user.id}))} columns={columns} />;
}
