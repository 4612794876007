import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import {TrialTitle} from 'components/TrialContent/trial/details/TrialTitle';

interface Props {
  title: string;
  children: JSX.Element | null;
}

export function TrialDetailsAccordion(props: Props) {
  return (
    <Box margin="8px 0">
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <TrialTitle title={props.title} />
        </AccordionSummary>
        <AccordionDetails>
          <Divider sx={{height: 1}} />
          <Box sx={{pt: 2, pb: 2}}>{props.children}</Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
